import {Injectable} from '@angular/core';
import {DataDetails} from '../../../pages/geospatial-viewer/model/api';
import {MapColor, MapColoursService} from '@core/utils/engin/geospatial-viewer/renderers/map-colours.service';
import {ValueType} from 'src/app/pages/geospatial-viewer/model/metric';
import {SimpleUnit} from '@core/interfaces/system/system-common';
import {FormatsService} from '@core/utils';
import {GeospatialService} from '@core/utils/engin/geospatial-viewer/helper/geospatial-service';
import {ThemeService} from '@core/utils/theme.service';

@Injectable()
export class SpatialCardAssetsViewModel {
    constructor(private geospatialService: GeospatialService) {}

    public getColor(dataDetails: DataDetails, theme: string): {legends: any[]; valueType: string} {
        const listResult = [];
        let valueType;
        switch (dataDetails.valueType) {
            case ValueType.CATEGORY:
                valueType = ValueType.CATEGORY;
                const colourParser = this.geospatialService.getDisplayCategoryParser(ValueType.CATEGORY, null);
                dataDetails.categoryList.map((legend, i) => {
                    const categoryName = colourParser(legend);
                    const mapColor: MapColor = MapColoursService.getMapCategoryColour(categoryName, theme);
                    const result = {
                        state: legend,
                        color: mapColor.color,
                    };
                    listResult.push(result);
                });
                break;
            case ValueType.NUMERIC:
                valueType = ValueType.NUMERIC;
                const legends = this.getLegends(dataDetails);
                legends.map((colorNum, i) => {
                    const categoryName = `NUMERIC_${this.colorClassMap[legends.length - 2][i]}`;
                    const mapColor: MapColor = MapColoursService.getMapCategoryColour(categoryName, theme);
                    const result = {
                        state: legends[i].label,
                        color: mapColor.color,
                    };
                    listResult.push(result);
                });
                break;
        }
        return {
            legends: listResult,
            valueType,
        };
    }

    public getLegends(resp) {
        const min: number = resp.min;
        const max: number = resp.max;
        const dataUnit: SimpleUnit = resp.unit;
        const costUnit: SimpleUnit = resp.costUnit; // legend displays costs, not unit of the data itself
        const categories: any[] = [];
        for (let i = 0; i < 5; i++) {
            const dataStep = (max - min) / (5 - 0);
            const rangeMin = dataStep * i;
            const rangeMax = dataStep * (i + 1);

            categories.push({
                label: `${FormatsService.prepareValueFromUnit(
                    rangeMin,
                    dataUnit,
                )} - ${FormatsService.prepareValueFromUnit(rangeMax, dataUnit)}`,
                min: rangeMin,
                max: rangeMax,
            });
        }
        return categories.map((category) => {
            return {
                ...category,
                unit: costUnit,
            };
        });
    }

    // Support for generic icon classes
    public colorClassMap = [
        ['1', '5'],
        ['1', '3', '5'],
        ['1', '2', '4', '5'],
        ['1', '2', '3', '4', '5'],
    ];

    public getBasemapName(theme: string): string {
        return ThemeService.isDark(theme) ? 'streets-night-vector' : 'gray-vector';
    }

    public isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }
}
