import {Pipe, PipeTransform} from '@angular/core';
import {User} from '@core/interfaces/common/users';
import {DateTime} from 'luxon';
import {FormatsService} from '@core/utils';
import {filter, takeUntil} from 'rxjs/operators';
import {UsersStore} from '@store/common/users.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Pipe({
    name: 'localizeTime',
    pure: false,
})
export class TimeLocalizePipe extends Unsubscribable implements PipeTransform {
    private localTimezone: string = 'Etc/UTC'; // default utc
    constructor(private usersStore: UsersStore) {
        super();

        this.usersStore.currentUser$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((d) => !!d),
            )
            .subscribe((user: User) => {
                this.localTimezone = user.timezone;
            });
    }

    /**
     * Convert universal timestamp (UTC) to local time; ignore any timezone in utcDateRaw in favour of user's local time
     * saved in ENGIN.
     * @param inDateRaw input string timestamp (read as UTC) or date (read timezone)
     * @param outFormat optional else DEFAULT_DATE_FORMAT = 'yyyy-MM-DD hh:mm A'
     */
    transform(inDateRaw: any, outFormat: any = FormatsService.DEFAULT_DATE_FORMAT_LUXON): any {
        let utcDate;
        if (typeof inDateRaw === 'string') {
            utcDate = this.isUTCFormat(inDateRaw)
                ? DateTime.fromISO(inDateRaw, {zone: 'utc'})
                : DateTime.fromFormat(inDateRaw, outFormat, {zone: 'utc'});
        } else if (typeof inDateRaw === 'number') {
            utcDate = DateTime.fromMillis(inDateRaw * 1000, {zone: 'utc'});
        } else utcDate = DateTime.fromJSDate(inDateRaw, {zone: 'utc'});

        return utcDate.setZone(this.localTimezone).toFormat(outFormat);
    }

    isUTCFormat(dateString) {
        // Regular expression pattern to match UTC-formatted date strings
        const utcPattern = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z)$/;

        return utcPattern.test(dateString);
    }
}
