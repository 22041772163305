import {Observable} from 'rxjs';
import {APIResponse, Filter, SimpleMultiSeries, SimpleUnitType} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {KpiType} from '@core/interfaces/engin/program-management/segment';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {OutcomeMaintainType, PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';

/*
 * Response
 */
export interface Program {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    name: string;
    description: string;
    segmentId: number;
    ownerId: string;
    ownerName: string;
    kpiList: KpiType[];
    scope: Filter[];
}

export interface ProgramDto extends Program {
    budgetMethod: ProgramBudgetType;
    investmentPriority: InvestmentPriorityType;
    segmentName: string;
    projectCount: number;
}

export interface ProgramFullDto extends ProgramDto {
    budgets: ProgramBudget[];
}

export interface ProgramParam {
    id: number;
    programId: number;
    sensitivityId: number;
    budgetMethod: ProgramBudgetType;
    investmentPriority: InvestmentPriorityType;
}

export interface ProgramBudget {
    id: number;
    programId: number;
    sensitivityId: number;
    startYear: number;
    endYear: number;
    budgetUnit: SimpleUnitType;
    budgetValue: number;
    scope: Filter[];
}

export interface ProgramBudgetOutcome {
    id: number;
    startYear: number;
    endYear: number;
    budgetUnit: SimpleUnitType;
    scope: string[];
    budgetValue: number;
}

/*
 * Request
 */
export interface ProgramCreateDto {
    name: string;
    description: string;
    segmentId: number;
    ownerId: string;
    ownerName: string;
    kpiList: KpiType[];
    assetClassList: string[];
    investmentPriority: InvestmentPriorityType;
    budgetMethod: ProgramBudgetType;
    budgetRows: ProgramBudgetCreateDto[];
    sensitivityId: number;
    workflowItemId: number;
}

export interface ProgramBudgetCreateDto {
    sensitivityId: number;
    startYear: number;
    endYear: number;
    budgetUnit: SimpleUnitType;
    budgetValue: number;
    assetClassList: string[];
}

export interface ProgramUpdateDto {
    name: string;
    description: string;
    segmentId: number;
    ownerId: string;
    ownerName: string;
    kpiList: KpiType[];
}

export interface ProgramUpdateBudgetDto {
    programId: number;
    sensitivityId: number;
    budgetMethod: ProgramBudgetType;
    investmentPriority: InvestmentPriorityType;
    budgetsRows: ProgramBudgetUpdateDto[];
}

export interface ProgramBudgetUpdateDto {
    id: number;
    startYear: number;
    endYear: number;
    budgetUnit: SimpleUnitType;
    budgetValue: number;
    assetClassList: string[];
}

export interface ProgramBudgetRequest extends PmDataRequest {
    requestDtos: ProgramBudget[];
}

export interface ProgramBudgetByOutcomeRequest extends PmDataRequest {
    assetClassList: string[];
    investmentPriority: InvestmentPriorityType;
    outcomeMaintainType: OutcomeMaintainType;
    belowPercent: number;
    startYear: number;
}

/*
 * Enums
 */
export enum ProgramBudgetType {
    UNIFORM = 'UNIFORM',
    UNIQUE = 'UNIQUE',
}

export const ProgramBudgetTypeLabel = {
    [ProgramBudgetType.UNIFORM]: 'Uniform',
    [ProgramBudgetType.UNIQUE]: 'Unique',
};

export enum InvestmentPriorityType {
    ECONOMIC = 'ECONOMIC',
    HEALTH = 'HEALTH',
    CALENDAR_AGE = 'CALENDAR_AGE',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
}

export const InvestmentPriorityTypeLabel = {
    [InvestmentPriorityType.ECONOMIC]: 'Economic',
    [InvestmentPriorityType.HEALTH]: 'Health',
    [InvestmentPriorityType.CALENDAR_AGE]: 'Calendar Age',
    [InvestmentPriorityType.EFFECTIVE_AGE]: 'Effective Age',
};

export abstract class ProgramService {
    /*
     * APIs for Programs model
     */
    abstract createProgram(program: ProgramCreateDto): Observable<APIResponse<Program>>;

    abstract editProgramDetails(programId: number, program: ProgramUpdateDto): Observable<APIResponse<Program>>;

    abstract deleteProgram(programId: number): Observable<APIResponse<boolean>>;

    abstract editProgramBudget(
        workflowItemId: number,
        updateDto: ProgramUpdateBudgetDto,
    ): Observable<APIResponse<Program>>;

    abstract getProgramList(params?: Filter[], pageSize?: number): Observable<Program[]>;

    /*
     * APIs for ProgramDto
     */
    abstract getProgramDto(programId: number, request: PmDataRequest): Observable<APIResponse<ProgramDto>>;

    abstract getProgramDtoList(request: PmDataRequest, params: Filter[], pageSize?: number): Observable<ProgramDto[]>;

    abstract getProgramDtoListCsv(request: PmDataRequest, params?: Filter[]): Observable<APIResponse<DownloadURL>>;

    abstract getProgramDtoListTable(request: PmDataRequest): Observable<DataSource>;

    /*
     * APIs for ProgramFullDto
     */
    abstract getProgramDtoFull(programId: number, req: PmDataRequest): Observable<APIResponse<ProgramFullDto>>;

    /*
     * APIs related to analysis of results and budgets
     */
    abstract findProgramKpis(programId: number, req: PmDataRequest): Observable<APIResponse<KpiData[]>>;

    abstract getBudgetChart(req: ProgramBudgetRequest): Observable<APIResponse<SimpleMultiSeries<number>>>;

    abstract getProgramBudgetByOutcomes(
        req: ProgramBudgetByOutcomeRequest,
    ): Observable<APIResponse<ProgramBudgetOutcome[]>>;

    /*
     * Other support APIs
     */
    abstract getInvestmentPriorityOptions(): Observable<APIResponse<InvestmentPriorityType[]>>;

    abstract getKpiOptions(): Observable<APIResponse<KpiType[]>>;

    abstract validateProgramName(programName: string): Observable<boolean>;

    /*
     * Helpers - call other APIs
     */
    abstract findAllBySegment(segmentId: number, request: PmDataRequest): Observable<Program[]>;
}
