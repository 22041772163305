import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastrService: NbToastrService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    const jwt = JSON.parse(localStorage.getItem('auth_app_token'));
                    this.router.navigate(['auth/login'], {state: {sessionExpire: true}});
                    if (jwt) {
                        localStorage.removeItem('auth_app_token');
                        setTimeout(() => {
                            this.toastrService.warning(
                                'Session has expired and you have been automatically logged out. ' +
                                    'Please log back in.',
                                'Session is expired',
                                {duration: 0},
                            );
                        }, 1500);
                    }
                }
                // TODO: handle 403 error ?
                return throwError(error);
            }),
        );
    }
}
