import Field from '@arcgis/core/layers/support/Field';

export class ProjectFieldsService {
    public getCustomMapFields(): Field[] {
        return this._fields.map((e) => new Field(e));
    }

    private _fields: any[] = [
        // Unique identifiers
        {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
        },
        {
            name: 'id',
            alias: 'id',
            type: 'long',
        },
        {
            name: 'tooltipType',
            alias: 'tooltipType',
            type: 'string',
        },
        // Data
        {
            name: 'projectName',
            alias: 'projectName',
            type: 'string',
        },
        {
            name: 'type',
            alias: 'type',
            type: 'string',
        },
        {
            name: 'status',
            alias: 'status',
            type: 'string',
        },
        {
            name: 'owner',
            alias: 'owner',
            type: 'string',
        },
    ];
}
