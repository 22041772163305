// TODO: this will deprecate the existing, which is at the end of the file
export class CustomMapLayer {
    code: string;
    label: string;
    description: string;
    disabled: boolean = false;
    layerSource: CustomLayerSourceType;
}

export enum CustomLayerSourceType {
    ARCGIS_ONLINE,
    DATABASE,
}

// Existing / to be deprecated by the above
// Configuration
export class AdditionalMapLayer {
    code: LayerType;
    disabled: boolean = false;
    children?: AdditionalMapLayer[];
    // Web controls
    selected: boolean = false;
}

// Other DTOs
export class MapLayerDetails {
    code: string;
    name: string;
    symbol: string;
    color: string;
}

// Enumerations
export enum LayerType {
    ROAD_MORATORIUM = 'ROAD_MORATORIUM',
    DISTRICT = 'DISTRICT',
    DISTRICT_OVERLAY = 'DISTRICT_OVERLAY',
    DISTRICT_SUMMARIZE = 'DISTRICT_SUMMARIZE',
    DEMOGRAPHIC = 'DEMOGRAPHIC',
    DEMOGRAPHIC_CUSTOMER = 'DEMOGRAPHIC_CUSTOMER',
}

// Labels
export const LayerTypeLabel = {
    [LayerType.ROAD_MORATORIUM]: 'Road Moratorium',
    [LayerType.DISTRICT]: 'City Districts',
    [LayerType.DISTRICT_OVERLAY]: 'Overlay Data',
    [LayerType.DISTRICT_SUMMARIZE]: 'Summarize Data',
    [LayerType.DEMOGRAPHIC]: 'Demographics',
    [LayerType.DEMOGRAPHIC_CUSTOMER]: 'Customer Demographics',
};
// ActiveLayer Type
export enum ActiveLayerType {
    'LineString' = 'asset_line_layer',
    'Point' = 'asset_point_layer',
}
