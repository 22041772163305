import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {MapValueFilter, Measure, Metric} from '../model/metric';
import {QUICK_FILTER_LIST, QuickFilterCategory} from '../model/filter';
import {GeospatialFilterStore} from './geospatial-filter.store';
import {AdditionalMapLayer} from '../model/layers';
import {GeospatialViewerConfiguration} from '../model/viewer-config';
import {VisualizationColorCode, VisualizationType} from '../model/visualization';
import {GeospatialViewerControlsService} from './geospatial-viewer-controls.service';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {UsersStore} from '@store/common/users.store';
import {filter, share, shareReplay, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {User} from '@core/interfaces/common/users';
import {StudiesStore} from '@store/common/studies.store';

@Injectable()
export class GeospatialViewerControlsStore extends Unsubscribable {
    private currentStudyId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    readonly currentStudyId$: Observable<number> = this.currentStudyId.asObservable();
    constructor(
        protected fb: FormBuilder,
        private geospatialFilterStore: GeospatialFilterStore,
        private controlsService: GeospatialViewerControlsService,
        private usersStore: UsersStore,
        private usageAnalyticsService: UsageAnalyticsService,
        private studiesStore: StudiesStore,
    ) {
        super();

        this.studiesStore.activeStudyIdRisk$.pipe(takeUntil(this.unsubscribe$)).subscribe((studyId: number) => {
            this.currentStudyId.next(studyId);
        });

        this.controlsService.getConfig().subscribe((conf: GeospatialViewerConfiguration) => {
            this.setConfig(conf);

            if (this.initialConfig.value === null) {
                this.initialConfig.next(conf);
                this.refreshControls();
            }
        });
        this.usersStore.currentUser$
            .pipe(
                filter((u: User) => u && !!u),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((user) => {
                this.currentUserId.next(user.id);
            });
    }

    private initialConfig: BehaviorSubject<GeospatialViewerConfiguration> =
        new BehaviorSubject<GeospatialViewerConfiguration>(null);
    readonly initialConfig$: Observable<GeospatialViewerConfiguration> = this.initialConfig.asObservable();
    private config: BehaviorSubject<GeospatialViewerConfiguration> = new BehaviorSubject<GeospatialViewerConfiguration>(
        null,
    );
    readonly config$: Observable<GeospatialViewerConfiguration> = this.config
        .asObservable()
        .pipe(share(), shareReplay(1));

    getConfig(): GeospatialViewerConfiguration {
        return this.config.value;
    }

    setConfig(newConfig: GeospatialViewerConfiguration) {
        this.config.next(newConfig);
    }

    /*
     * UI elements
     */
    // Control Panel > Measures; map controls > measure/metric
    public currentUserId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public selectedMeasure: BehaviorSubject<Measure> = new BehaviorSubject<Measure>(null);
    readonly selectedMeasure$: Observable<Measure> = this.selectedMeasure.asObservable();
    public selectedMetric: BehaviorSubject<Metric> = new BehaviorSubject<Metric>(null);
    readonly selectedMetric$: Observable<Metric> = this.selectedMetric.asObservable();
    // Control Panel > Measures > specific filters; map control > specific filters
    private mapValueFilter: BehaviorSubject<MapValueFilter> = new BehaviorSubject<MapValueFilter>(null);
    readonly mapValueFilter$: Observable<MapValueFilter> = this.mapValueFilter.asObservable();
    // Control panel > View; map controls > visualization type
    private selectedVisualizationType = new BehaviorSubject(null);
    public selectedVisualizationType$: Observable<VisualizationType> = this.selectedVisualizationType.asObservable();
    // Control panel > Filter > quick filters; map control > quick filters
    private quickFilters: BehaviorSubject<QuickFilterCategory[]> = new BehaviorSubject<QuickFilterCategory[]>(null);
    readonly quickFilters$: Observable<QuickFilterCategory[]> = this.quickFilters.asObservable();

    // Control panel > Project > visualization
    private projectLayerActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    readonly projectLayerActive$: Observable<boolean> = this.projectLayerActive.asObservable();
    public projectColorCodeType: BehaviorSubject<VisualizationColorCode> = new BehaviorSubject<VisualizationColorCode>(
        VisualizationColorCode.OWNERSHIP,
    );
    // Control panel > View > additional map layers
    private additionalMapLayers: BehaviorSubject<AdditionalMapLayer[]> = new BehaviorSubject<AdditionalMapLayer[]>(
        null,
    );
    readonly additionalMapLayers$: Observable<AdditionalMapLayer[]> = this.additionalMapLayers.asObservable();

    public projectInEditMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readyToCreateProject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // default value should be false
    // public projectListIsEmpty = new BehaviorSubject<boolean>(false);

    readonly activeMetric: BehaviorSubject<Metric> = new BehaviorSubject<Metric>(null);

    public valueFilterChangeFlag = new BehaviorSubject<Boolean>(false);
    public selectedRange = this.fb.group({
        min: this.fb.control[''],
        max: this.fb.control[''],
    });

    // Controls for configuration
    public updateMapViewWindow(updateObj: any): boolean {
        const config: GeospatialViewerConfiguration = this.config.getValue();
        const newConfig = {
            ...config,
            centerCoordinate: {
                ...config.centerCoordinate,
                ...updateObj,
            },
        };

        if (!this.isEquivalent(config.centerCoordinate, newConfig.centerCoordinate)) {
            this.setConfig(newConfig);
            return true;
        }
        return false;
    }

    /*
     * External functions for managing controls
     */
    public refreshControls() {
        if (this.initialConfig.value) {
            const config = this.initialConfig.value;

            const activeMeasure: Measure = this.getDefaultMeasure(config.measures);
            const activeMetric: Metric = this.getDefaultMetric(activeMeasure);
            const activeVisualizationType: VisualizationType = VisualizationType.INDIVIDUAL;

            this.selectedMeasure.next(activeMeasure);
            this.selectedMetric.next(activeMetric);
            this.selectedVisualizationType.next(activeVisualizationType);
            // Reset filters
            this.resetQuickFilters();
            this.geospatialFilterStore.resetGeospatialFilters();

            this.resetMapLayers();
        }
    }

    public resetQuickFilters() {
        this.quickFilters.next(QUICK_FILTER_LIST);
    }

    private resetMapLayers() {
        if (this.initialConfig.value) {
            const initConfig = this.initialConfig.value.layers;
            initConfig.forEach((layer: AdditionalMapLayer) => {
                if (layer.selected === undefined) {
                    layer.selected = false;
                }
            });
            this.additionalMapLayers.next(initConfig);
        }
    }

    // Update selected measure, and reset selected metric to nothing
    public updateMeasure(newMeasure: Measure) {
        if (newMeasure && this.selectedMeasure.value.code !== newMeasure.code) {
            this.selectedMetric.next(null); // force null
            this.selectedMeasure.next(newMeasure);
        }
    }

    public updateMetric(newMetric: Metric) {
        if (newMetric && (this.selectedMetric.value == null || this.selectedMetric.value.code !== newMetric.code)) {
            this.selectedMetric.next(newMetric);
            const metricName = newMetric.code.includes('_') ? newMetric.code.split('_').join(' ') : newMetric.code;
            this.usageAnalyticsService.logView('Geospatial', {$metric_drop_down_value: metricName});
            this.resetMapValueFilter(); // reset mapValueFilter when changing metrics
        }
    }

    public getMeasure(): Measure {
        return this.selectedMeasure.value;
    }

    public getMetric(): Metric {
        return this.selectedMetric.value;
    }

    getCurrentStudyId(): number {
        return this.currentStudyId.getValue();
    }

    // Update visualization type
    public updateVisualizationType(newVisualizationType: VisualizationType) {
        if (newVisualizationType) {
            this.selectedVisualizationType.next(newVisualizationType);
        }
    }

    // Update map value ("specific") filter
    public updateMapValueFilter(newFilter: MapValueFilter) {
        if (newFilter) {
            this.mapValueFilter.next(newFilter);
        }
    }

    // Update for project layer
    public updateProjectLayerActive(val: boolean) {
        if (this.projectLayerActive.value !== val) {
            this.projectLayerActive.next(val);
        }
    }

    private resetMapValueFilter() {
        this.mapValueFilter.next(null);
    }

    /*
     * Public getters
     */
    public getActiveMetric(): Metric {
        return this.selectedMetric.value;
    }

    // Helpers for default measure/metric
    private getDefaultMeasure(measures: Measure[] = []): Measure {
        // Find default measure
        const findFirstDefault: Measure[] = measures.filter((m) => m.selectDefault);
        if (findFirstDefault.length > 0) {
            return findFirstDefault[0];
        }
        // Else find first non-disabled measure
        return measures.filter((m) => !m.disabled)[0];
    }
    private getDefaultMetric(measure: Measure): Metric {
        // Find default measure
        const findFirstDefault: Metric[] = measure.metrics.filter((m) => m.selectDefault);
        if (findFirstDefault.length > 0) {
            return findFirstDefault[0];
        }
        // Else find first non-disabled measure
        return measure.metrics.filter((m) => !m.disabled)[0];
    }

    // Helper
    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }
}
