import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
import Home from '@arcgis/core/widgets/Home';
import {NbThemeService} from '@nebular/theme';
import Map from '@arcgis/core/Map';
import {SpatialCardAssetsViewModel} from '@theme/components/spatial-card/spatial-card.vm';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {GeospatialResponse} from '../../../../pages/geospatial-viewer/model/api';
import {Measure, Metric} from 'src/app/pages/geospatial-viewer/model/metric';
import {debounceTime, takeUntil, map, filter} from 'rxjs/operators';
import {GeospatialViewerConfiguration} from '../../../../pages/geospatial-viewer/model/viewer-config';
import {VisualizationType} from '../../../../pages/geospatial-viewer/model/visualization';
import {
    AssetLayers,
    BlendedLayer,
    BubbleLayer,
    GenerateLayerService,
    HeatmapLayers,
} from '@core/utils/engin/geospatial-viewer/esri-layers/generate-layer.service';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Layer from '@arcgis/core/layers/Layer';
import {MeasureMetricService} from '@theme/components/spatial-card/service/measure-metric/measure-metric.service';
import Extent from '@arcgis/core/geometry/Extent';
import {CoordinateConverterService} from '@core/utils/engin/geospatial-viewer/helper/coordinate-converter.service';
import {SpatialCardHeaderResponse, SpatialCardResponse} from '../model/spatialCardResponse';
import Basemap from '@arcgis/core/Basemap';
import {CoordinateLatLong} from '@core/interfaces/common/asset';
import {FormControl} from '@angular/forms';
import {SpatialCardType} from '../model/spatialCardType';
import {Project} from '../../../../pages/geospatial-viewer/model/project';
import {GeospatialViewerStore} from '../../../../pages/geospatial-viewer/api/geospatial-viewer.store';
import {GeospatialViewerControlsStore} from '../../../../pages/geospatial-viewer/api/geospatial-viewer-controls.store';
import {GeospatialViewerService} from '../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {User} from '@core/interfaces/common/users';
import {Filter} from '@core/interfaces/system/system-common';
import {UsersStore} from '@store/common/users.store';

@Component({
    selector: 'ngx-spatial-card',
    templateUrl: './spatial-card.component.html',
    styleUrls: ['./spatial-card.component.scss', '../spatial-card.component.scss'],
    providers: [SpatialCardAssetsViewModel, MeasureMetricService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpatialCardComponent extends Unsubscribable implements OnInit {
    // Quick note for Spatial card types:
    //  DEFAULT: Map component with metric and measure selections and legend bar. (standard version)
    //  APP_REGISTRY: Only map component with additional toggle event in map. (customized for app registry page)"
    @Input() panToLocation$: Observable<CoordinateLatLong>;

    public toggleFormControl = new FormControl({value: true});
    @Input() type: SpatialCardType = SpatialCardType.DEFAULT;
    @ViewChild('mapViewNode', {static: true}) private mapViewEl: ElementRef;
    @Input() title: string = 'Geospatial overview';
    @Input() subtitle: string;
    @Input() source;
    @Input() sourceData: Observable<GeospatialResponse> = new BehaviorSubject(null);
    @Input() config: Observable<GeospatialViewerConfiguration> = new BehaviorSubject(null);
    @Input() headerData: Observable<SpatialCardHeaderResponse[]> = new BehaviorSubject(null);
    @Input() param: {[key: string]: any} = {};
    @Output() updatedConfigData = new EventEmitter();
    @Output() updateMetric = new EventEmitter();
    @Input() featureSetting: {popupTemplate: boolean; labelingInfo: any} = null;

    private legendColor = new BehaviorSubject(null);
    readonly legendColor$: Observable<any> = this.legendColor.asObservable();
    private configData: BehaviorSubject<GeospatialViewerConfiguration> = new BehaviorSubject(null);
    readonly configData$: Observable<GeospatialViewerConfiguration> = this.configData.asObservable();
    public mapInitializedFlag = new BehaviorSubject(false);
    private mapRenderFlag = new BehaviorSubject(false);
    private mapView: MapView;
    private map: Map;
    public valueType: string;
    private selectedMetric: Metric;
    public spatialCardType = SpatialCardType;

    constructor(
        private nbThemeService: NbThemeService,
        public vm: SpatialCardAssetsViewModel,
        private generateLayerService: GenerateLayerService,
        private measureMetricService: MeasureMetricService,
        public geospatialViewerStore: GeospatialViewerStore,
        private controlsStore: GeospatialViewerControlsStore,
        private geospatialViewerService: GeospatialViewerService,
        private userStore: UsersStore,
    ) {
        super();
    }

    private currentUser: User;

    ngOnInit() {
        this.userStore.currentUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((currentUser: User) => {
            this.currentUser = currentUser;
        });
        this.nbThemeService
            .onThemeChange()
            .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
            .subscribe((themeChange) => {
                this.onChangeForTheme(themeChange);
            });
        combineLatest([this.source, this.nbThemeService.onThemeChange()])
            .pipe(
                takeUntil(this.unsubscribe$),
                map(([resp, themeChange]: [SpatialCardResponse, any]) => {
                    this.initProcess(resp, themeChange);
                    this.configData.next(resp.configData);

                    if (!this.mapInitializedFlag.value) {
                        this.initializeMap(this.configData.getValue());
                    } else {
                        this.updateMapAssetData(resp.sourceData, themeChange);
                    }
                }),
            )
            .subscribe();
    }

    private initProcess(resp: SpatialCardResponse, themeChange: any) {
        // Add any prep work before creating the map in here. (need to add the enum in SpatialCardType)
        switch (this.type) {
            case SpatialCardType.ASSET_PROJECT:
            case SpatialCardType.DEFAULT:
                this.initProcessByType(resp, themeChange);
                break;
        }
    }
    private initProcessByType(resp: SpatialCardResponse, themeChange: any) {
        if (!resp || !resp.sourceData.dataDetails) return null;
        const legendData = this.vm.getColor(resp.sourceData.dataDetails, themeChange.name);
        this.legendColor.next(legendData.legends);
        this.valueType = legendData.valueType;
        const activeMeasure: Measure = this.measureMetricService.getDefaultMeasure(resp.configData.measures);
        this.selectedMetric = this.measureMetricService.getDefaultMetric(activeMeasure);
    }

    private addingProject() {
        if (SpatialCardType.ASSET_PROJECT) {
            combineLatest<Observable<boolean>, Observable<number>, Observable<boolean>>([
                this.controlsStore.projectLayerActive$,
                this.geospatialViewerStore.reloadProjectData$,
                this.mapRenderFlag.asObservable(),
            ])
                .pipe(
                    takeUntil(this.unsubscribe$),
                    filter(([_0, _1, mapRenderFlag]: [boolean, number, boolean]) => mapRenderFlag),
                    filter(([_0, _1, mapRenderFlag]: [boolean, number, boolean]) => Object.keys(this.param).length > 0),
                    map(([projectLayerActive, _0, _1]: [boolean, number, boolean]) => {
                        if (projectLayerActive) {
                            this.geospatialViewerService.listProjectsByField(this.param).subscribe((projects) => {
                                if (projects.length !== 0) {
                                    this.updateProjectLayer(projects);
                                }
                            });
                        }
                    }),
                )
                .subscribe();
        }
    }

    private featureSettings(event: {eventName: string; value: string | boolean}) {
        // Add code for styling for feature in here.
        switch (this.type) {
            case SpatialCardType.DEFAULT:
                return null;
            case SpatialCardType.APP_REGISTRY:
                if (event?.eventName === 'assetIdShow') {
                    return {
                        ...this.featureSetting,
                        labelingInfo: event?.value ? this.featureSetting.labelingInfo : {},
                    };
                } else {
                    return null;
                }
        }
    }

    private initializeMap(config: GeospatialViewerConfiguration) {
        try {
            this.addingProject();
            // Create basemap
            this.map = new WebMap({
                basemap: this.vm.getBasemapName(this.nbThemeService.currentTheme),
                layers: [],
            });

            // Create view, add mapview and basic settings
            this.mapView = new MapView({
                container: this.mapViewEl.nativeElement,
                map: this.map,
                zoom: config.centerCoordinate.zoomLevel,
                center: [config.centerCoordinate.longitude, config.centerCoordinate.latitude],
                constraints: {
                    snapToZoom: false,
                    maxZoom: 18, // lock max zoom
                    minZoom: 4, // lock min zoom
                },
            });

            this.mapView.when().then(() => {
                this.onMapViewportChange(this.mapView.extent, this.mapView.zoom);
                this.mapRenderFlag.next(true);
            });

            this.mapView.on('drag', (drag) => {
                if (drag.action === 'end') {
                    this.onMapViewportChange(this.mapView.extent, this.mapView.zoom);
                }
            });

            // Toggle zoomed in / zoomed out layers based on zoom level
            this.mapView.watch('zoom', (zoomLevel) => {
                // Zoom level is set to -1 momentarily after basemap change
                if (this.mapView.extent && zoomLevel > 0) {
                    this.onMapViewportChange(this.mapView.extent, zoomLevel);
                }
            });

            // Add home button
            const homeBtn = new Home({view: this.mapView});
            this.mapView.ui.add(homeBtn, 'bottom-left');
            this.mapView.ui.move('zoom', 'bottom-left');
            this.mapInitializedFlag.next(true);

            //added
            this.panToLocation$
                .pipe(filter((d: CoordinateLatLong) => !!d?.longitude || !!d?.latitude))
                .subscribe((init: CoordinateLatLong) => {
                    this.mapView.when().then(() => {
                        this.mapView
                            .goTo({
                                center: [init.longitude, init.latitude],
                                zoom: 17.99,
                            })
                            .then(() => {
                                this.onMapViewportChange(this.mapView.extent, this.mapView.zoom);
                            });
                    });
                });
        } catch (error) {}
    }

    public updateMapViewWindow(updateObj: any) {
        const config: GeospatialViewerConfiguration = this.configData.getValue();
        const newConfig = {
            ...config,
            centerCoordinate: {
                ...config.centerCoordinate,
                ...updateObj,
            },
        };
        if (!this.isEquivalent(this.configData.value.centerCoordinate, newConfig.centerCoordinate)) {
            this.updatedConfigData.emit(newConfig);
        }
    }

    private onMapViewportChange(extent: Extent, zoomLevel: number) {
        // 'extent' extends 'geometry' and can use the same conversion
        const latLongExtent: Extent = CoordinateConverterService.convertEsriGeometry(extent, 4326) as Extent;
        const updateObj = {
            latitude: latLongExtent.center.latitude,
            longitude: latLongExtent.center.longitude,
            xmin: latLongExtent.xmin,
            xmax: latLongExtent.xmax,
            ymin: latLongExtent.ymin,
            ymax: latLongExtent.ymax,
            zoomLevel: zoomLevel,
        };
        this.updateMapViewWindow(updateObj);
    }

    private updateMapAssetData(mapDataSource: GeospatialResponse, theme: string) {
        this.removeAssetLayers();

        const mapData = mapDataSource.data;
        const mapDataDetails = mapDataSource.dataDetails;
        let visualizationType = mapDataSource.visualizationType;
        const activeMetric = this.controlsStore.getActiveMetric();
        // Heatmap switches to individual view when zoomed beyond certain level
        if (visualizationType == VisualizationType.HEATMAP && this.mapView.zoom >= 12) {
            visualizationType = VisualizationType.INDIVIDUAL;
        }
        // Render different map layers based on visualization type
        switch (visualizationType) {
            case VisualizationType.INDIVIDUAL:
                // todo: get cut-off from API response or other condition?
                // zoom level needs to be 14 as a default value
                if (this.mapView.zoom >= 14) {
                    const assetLayers: AssetLayers = this.generateLayerService.createAssetFeatureLayers(
                        mapData,
                        mapDataDetails,
                        activeMetric,
                        theme,
                        this.featureSettings({eventName: 'assetIdShow', value: this.toggleFormControl.value}),
                    );
                    this.map.addMany(assetLayers.layers);
                } else {
                    const bubbleLayer: BubbleLayer = this.generateLayerService.createAssetBubbleLayer(
                        mapData,
                        mapDataDetails,
                        activeMetric,
                        theme,
                    );
                    this.map.add(bubbleLayer.layer);
                }
                break;
            case VisualizationType.BLENDED_PRID:
            case VisualizationType.BLENDED_IRID:
            case VisualizationType.BLENDED_SECTION:
                const blendedLayer: BlendedLayer = this.generateLayerService.createAssetBlendedLayers(
                    mapData,
                    mapDataDetails,
                    activeMetric,
                    theme,
                );
                this.map.add(blendedLayer.layer);
                break;
            case VisualizationType.HEATMAP:
                this.removeAssetLayers();
                const heatmapLayers: HeatmapLayers = this.generateLayerService.createHeatmapFeatureLayers(
                    mapData,
                    mapDataDetails,
                    activeMetric,
                    theme,
                    this.mapView.zoom,
                );
                this.map.addMany(heatmapLayers.layers);
                break;
            case VisualizationType.POLYGON:
                const polygonLayer: FeatureLayer = this.generateLayerService.createAssetPolygonLayer(
                    mapData,
                    mapDataDetails,
                    activeMetric,
                    theme,
                );
                this.map.add(polygonLayer);
                break;
            default:
                throw new Error('Invalid visualization type selected: ' + visualizationType);
        }
    }

    private removeAssetLayers() {
        const targetLayers: Layer[] = [];
        this.map.allLayers.forEach((l: Layer) => {
            if (l.id.includes('asset')) {
                targetLayers.push(l);
            }
        });
        this.map.removeMany(targetLayers);
    }

    onUpdateMetric(e) {
        this.controlsStore.selectedMeasure.next(e.measure);
        this.controlsStore.selectedMetric.next(e.metric);
        this.updateMetric.emit(e);
    }

    private async onChangeForTheme(themeChange) {
        if (themeChange) {
            const currentTheme: string = themeChange.name;
            const previousTheme: string = themeChange.previous;

            if (currentTheme && previousTheme) {
                const currentBasemap = this.vm.getBasemapName(currentTheme);
                const previousBasemap = this.vm.getBasemapName(previousTheme);
                if (currentBasemap != previousBasemap) {
                    this.map.basemap = Basemap.fromId(currentBasemap);
                }
            }
        }
    }

    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }
    private updateProjectLayer(projects: Project[]) {
        // Create new layer and add to map
        const newLayer = this.generateLayerService.createProjectLayer(projects, this.currentUser.id);
        this.map.add(newLayer);
        this.goToProjectLocation(projects);
    }

    private goToProjectLocation(project) {
        const projectGeoData = project[0]?.projectGeography ? project[0]?.projectGeography : null;
        if (projectGeoData) {
            const coordinateData = JSON.parse(projectGeoData).coordinates;
            this.mapView.when().then(() => {
                this.mapView.goTo({
                    center: [coordinateData[0][0][0], coordinateData[0][0][1]],
                    zoom: 12,
                });
            });
        }
    }
}
