import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, FilterOperatorType} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {AlternativeApi} from '@core/backend/engin/api/program-management/alternative.api';
import {
    Alternative,
    AlternativeAssetDto,
    AlternativeCostResponse,
    AlternativeCreateDto,
    AlternativeDto,
    AlternativeFullDto,
    AlternativeMetricResponse,
    AlternativeMetricType,
    AlternativeService,
    AlternativeUpdateDto,
    AssetInterventionType,
    ResetAlternativeRequest,
    UpdateInterventionRequest,
} from '@core/interfaces/engin/program-management/alternative';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';

@Injectable()
export class AlternativeServiceImpl extends AlternativeService {
    constructor(private api: AlternativeApi) {
        super();
    }

    /*
     * APIs for Alternatives model
     */
    public createAlternative(alternativeCreate: AlternativeCreateDto): Observable<APIResponse<Alternative>> {
        return this.api.createAlternative(alternativeCreate);
    }

    public editAlternativeDetails(
        alternativeId: number,
        alternativeUpdate: AlternativeUpdateDto,
    ): Observable<APIResponse<Alternative>> {
        return this.api.editAlternativeDetails(alternativeId, alternativeUpdate);
    }

    public deleteAlternative(alternativeId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteAlternative(alternativeId);
    }

    public getAlternativeList(params?: Filter[], pageSize?: number): Observable<Alternative[]> {
        return this.api.getAlternativeList(params, pageSize).pipe(map((resp) => resp.items));
    }

    public updateAlternativeInterventions(
        alternativeId: number,
        updateDto: UpdateInterventionRequest,
    ): Observable<APIResponse<AlternativeFullDto>> {
        return this.api.updateAlternativeInterventions(alternativeId, updateDto);
    }

    public resetAlternative(
        alternativeId: number,
        updateDto: ResetAlternativeRequest,
    ): Observable<APIResponse<AlternativeFullDto>> {
        return this.api.resetAlternative(alternativeId, updateDto);
    }

    public setSelectedAlternative(alternativeId: number): Observable<APIResponse<boolean>> {
        return this.api.setSelectedAlternative(alternativeId);
    }

    /*
     * APIs for AlternativeDto
     */
    public getAlternativeDto(alternativeId: number, request: PmDataRequest): Observable<APIResponse<AlternativeDto>> {
        return this.api.getAlternativeDto(alternativeId, request);
    }

    public getAlternativeDtoList(
        request: PmDataRequest,
        params?: Filter[],
        pageSize?: number,
    ): Observable<AlternativeDto[]> {
        return this.api.getAlternativeDtoList(request, params, pageSize).pipe(map((resp) => resp.items));
    }

    public getAlternativeDtoListCsv(request: PmDataRequest, params?: Filter[]): Observable<APIResponse<DownloadURL>> {
        return this.api.getAlternativeDtoListCsv(request, params);
    }

    /*
     * APIs for AlternativeFullDto
     */
    public getAlternativeDtoFull(
        alternativeId: number,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeFullDto>> {
        return this.api.getAlternativeDtoFull(alternativeId, request);
    }

    public getAlternativeFullDtoListTable(request: PmDataRequest, params?: Filter[]): Observable<DataSource> {
        return this.api.getAlternativeFullDtoListTable(request, params);
    }

    /*
     * APIs for AlternativeAssetDto
     */
    public getAlternativeAssetsList(
        alternativeId: number,
        request: PmDataRequest,
        filters?: Filter[],
        pageSize?: number,
    ): Observable<AlternativeAssetDto[]> {
        return this.api
            .getAlternativeAssetsList(alternativeId, request, filters, pageSize)
            .pipe(map((resp) => resp.items));
    }

    public getAlternativeAssetsListCsv(
        alternativeId: number,
        request: PmDataRequest,
        params?: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getAlternativeAssetsListCsv(alternativeId, request, params);
    }

    public getAlternativeAssetsListTable(alternativeId: number, request: PmDataRequest): Observable<DataSource> {
        return this.api.getAlternativeAssetsListTable(alternativeId, request);
    }

    /*
     * APIs related to analysis of costs and benefits
     */
    public getCostResults(
        alternativeId: number,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeCostResponse>> {
        return this.api.getCostResults(alternativeId, request);
    }

    public getBenefitResultsByType(
        alternativeId: number,
        benefitType: AlternativeMetricType,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeMetricResponse>> {
        return this.api.getBenefitResultsByType(alternativeId, benefitType, request);
    }

    /*
     * Other support APIs
     */
    public getInterventionOptions(): Observable<APIResponse<AssetInterventionType[]>> {
        return this.api.getInterventionOptions();
    }

    public validateAlternativeName(projectId: number, alternativeName: string): Observable<boolean> {
        return this.api
            .validateAlternativeName(projectId, alternativeName)
            .pipe(map((res: APIResponse<boolean>) => res.response));
    }

    /*
     * Helpers - call other APIs
     */
    public getAlternativeDtoByProjectTable(projectId: number, request: PmDataRequest): Observable<DataSource> {
        const filter: Filter = {
            fieldKey: 'projectId',
            operator: FilterOperatorType.EQUAL,
            value: projectId,
        };
        return this.getAlternativeFullDtoListTable(request, [filter]);
    }
}
