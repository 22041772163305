<nb-card>
    <nb-card-header *ngIf="enableHeader()">
        <div [ngClass]="!subTitle ? 'singleTitle': ''">
            <div class="primary-header">{{title}}</div>
            <div *ngIf="subTitle" class="sub-header">{{subTitle}}</div>
        </div>

        <div class="display-flex">
            <input
                *ngIf="disableGlobalSearch()"
                nbInput
                #search
                type="text"
                placeholder="Search..."
                (blur)="onSearch(search.value)"
            />

            <form class="header-simple-pager" [formGroup]="pagingFormGroup" *ngIf="enablePagePerRecord()">
                <label class="simple-pager-label">Records on page</label>
                <input
                    nbInput
                    formControlName="perPage"
                    type="number"
                    [min]="1"
                    [max]="99"
                    pattern="[0-99]*"
                    placeholder="Rows"
                    class="simple-pager-input"
                />
            </form>

            <div class="vertical-separator" *ngIf="enablePagePerRecord()"></div>
            <div class="header-side-container">
                <div *ngIf="selectColumnBtn?.enable" class="col-1 header-select-columns-button">
                    <ngx-button-multiselect
                        *ngIf="(selectColumnBtn.columns$ |async)?.length"
                        [formControl]="_selectedColumnsControl"
                        [iconDetails]="{icon: 'layout-outline'}"
                        [options$]="selectColumnBtn.columns$"
                        defaultGroupTitle="Columns"
                    ></ngx-button-multiselect>
                </div>

                <div *ngIf="enablePagePerRecord()" class="col-1 header-refresh-button">
                    <button (click)="refresh()" nbButton>
                        <nb-icon [pack]="'eva'" class="icon" icon="refresh"></nb-icon>
                    </button>
                </div>

                <div *ngIf="customButton?.enable">
                    <button nbButton (click)="customButton?.callback()">
                        <nb-icon [icon]="customButton?.icon" [pack]="customButton?.pack || 'eva'"></nb-icon>
                        {{customButton.label}}
                    </button>
                </div>

                <div *ngIf="enableTableSetting()" class="col-1 header-setting-button">
                    <ngx-table-settings (downloadFile)="this.downloadFile.emit(true)"></ngx-table-settings>
                </div>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <ngx-smart-table
            [settings]="settings"
            [source]="data"
            (custom)="onCustomEventClick($event)"
            (onFilter)="onFilter($event)"
            (userRowSelect)="onUserRowSelect($event)"
        ></ngx-smart-table>
    </nb-card-body>
</nb-card>
