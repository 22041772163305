<ng-container *ngIf="user$ | async as user">
    <h1 id="title" class="title">Update Password</h1>
    <p class="sub-title"></p>

    <div class="message-box mb-3">
        <ngx-user-notification
            class="user-notification"
            [clickEvent]="false"
            [height]="'auto'"
            [messageList]="showingMessage"
        ></ngx-user-notification>
    </div>

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>Password reset failed!</b></p>
        <ul class="alert-message-list">
            <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
        </ul>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
        <p class="alert-title"><b>Success!</b></p>
        <ul class="alert-message-list">
            <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
        </ul>
    </nb-alert>

    <ngx-change-password
        [form]="resetPasswordForm"
        [validation$]="passwordValidation$"
        [passwordHistory]="true"
        (onInputChanges)="onInputChanges($event)"
    ></ngx-change-password>

    <button
        nbButton
        fullWidth
        status="primary"
        size="large"
        [disabled]="!(formReady | async) || submitted"
        (click)="submit()"
    >
        Save
    </button>

    <section class="sign-in-or-up" aria-label="Sign in">
        <p><a class="text-link" routerLink="../login">Back to Login</a></p>
    </section>
</ng-container>
