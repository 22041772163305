import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {
    FormCellType,
    FormField,
    FormViewModeType,
    NumericFieldResultDto,
    NumericType,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormFieldBaseComponent} from '../base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';

@Component({
    selector: 'ngx-form-field-numeric',
    templateUrl: './numeric.component.html',
    styleUrls: ['./numeric.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericComponent extends FormFieldBaseComponent<NumericFieldResultDto> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    public FormMode = FormMode;
    public minValue = -99999;
    public maxValue = 99999;

    NumericType = NumericType;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.field?.range?.max != undefined && (this.maxValue = this.field?.range?.max);
        this.field?.range?.min != undefined && (this.minValue = this.field?.range?.min);

        this.genericInit();

        if (this.fieldForm) {
            const currentValidators = this.fieldForm.validator ? [this.fieldForm.validator] : [];
            this.fieldForm.setValidators([
                ...currentValidators,
                Validators.max(this.maxValue),
                Validators.min(this.minValue),
            ]);
            this.fieldForm.updateValueAndValidity({emitEvent: false});
        }
    }

    public getValueOrDefault(defaultValue: string): string | number {
        return this.currentValue ? this.currentValue.value : defaultValue;
    }

    /*
     * Implement abstract methods
     */
    validate(value: NumericFieldResultDto): boolean {
        if (this.required && value.value == null) {
            return false;
        }

        // If number is invalid i.e. NaN
        if (isNaN(value.value)) {
            return false;
        }

        // Integer field must be a integer value
        if (this.field?.range?.valueType === NumericType.INTEGER && !Number.isInteger(value.value)) {
            return false;
        }

        // Respect min and max values
        if (value.value < this.minValue || value.value > this.maxValue) {
            return false;
        }
        if (value.value >= this.minValue && value.value <= this.maxValue) {
            return true;
        }

        return !this.required;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: any): NumericFieldResultDto {
        return {
            ...this.currentValue,
            value: item,
        };
    }

    getFormValue(): any {
        return this.result ? this.result.value : null;
    }

    onValidationCheck(): void {
        if (!this.fieldForm?.value && this.required) {
            this.fieldForm?.markAsTouched();
        }
        this.cd.detectChanges();
    }
}
