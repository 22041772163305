import {Injectable} from '@angular/core';
import {Measure, Metric, MetricType} from '../../../../../pages/geospatial-viewer/model/metric';

@Injectable()
export class MeasureMetricService {
    // Helpers for default measure/metric

    public getDefaultMeasure(measures: Measure[]): Measure {
        // Find default measure
        const findFirstDefault: Measure[] = measures.filter((m) => m.selectDefault);
        if (findFirstDefault.length > 0) {
            return findFirstDefault[0];
        }
        // Else find first non-disabled measure
        return measures.filter((m) => !m.disabled)[0];
    }

    public getDefaultMetric(measure: Measure): Metric {
        // Find default measure
        const findFirstDefault: Metric[] = measure.metrics.filter((m) => m.selectDefault);
        if (findFirstDefault.length > 0) {
            return findFirstDefault[0];
        }
        // Else find first non-disabled measure
        return measure.metrics.filter((m) => !m.disabled)[0];
    }

    public getMeasureByMetricType(type: MetricType, measures: Measure[]): Measure {
        return measures.find((m) => m.metrics.map((me) => me.code).includes(type));
    }

    public getMetricFromType(type: MetricType, measures: Measure[]) {
        const measure: Measure = this.getMeasureByMetricType(type, measures);
        return measure.metrics.find((me) => me.code == type);
    }
}
