import {Observable} from 'rxjs';
import {
    APIResponse,
    DataTableParam,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    SimpleSeries,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {GeoJSONGeometry} from '../../../../pages/geospatial-viewer/model/api';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

/*
 * Response
 */
export class Project {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    name: string;
    description: string;
    programId: number;
    ownerId: string;
    ownerName: string;
    status: ProjectStatusType;
    projectCreateType: ProjectCreateType;
    sourceWorkflowItemId: number;
    filters: Filter[];
    shapeGeoJson: string;
    investmentDriver: string;
    // Parsed geometry
    geoJsonGeometry?: GeoJSONGeometry;

    static getRings(p: Project): any[] {
        return p.geoJsonGeometry ? p.geoJsonGeometry.coordinates : JSON.parse(p.shapeGeoJson).coordinates;
    }
}

export class ProjectDto extends Project {
    programName: string;
    segmentId: number;
    segmentName: string;
    workflowItemId: number;
    assetCount: number;
    assetLength: number;
    lengthUnit: string;
    countWorstHealth: number;
    countPastTul: number;
    assetTotalCost: number;
    avgCalAge: number;
    avgEffAge: number;
    avgCalAgeAdjusted: number;
    avgEffAgeAdjusted: number;
    avgHealthIndex: number;
    avgCurrentFp: number;
    avgCurrentFpAdjusted: number;
    avgOitYti: number;
    sumImpactCustomer: number;
    sumImpactEnvironmental: number;
    sumImpactFinancial: number;
    sumImpactCollateral: number;
    sumImpactTotal: number;
    totalRiskCurrent: number;
    totalRisk10y: number;
    bcRatioRisk: number;
    bcRatioDeferral: number;
}

// PmAssetDto fetches assets from a single workflowItemId, with details
export interface PmAssetDto {
    assetId: string;
    workflowItemId: number;
    assetClass: string;
    assetSubclassCode: string;
    circuit: string;
    station: string;
    calendarAge: number;
    effectiveAge: number;
    healthIndex: number;
    healthIndexCategory: string;
    currentFp: number;
    interventionCost: number;
    impactCustomer: number;
    impactEnvironmental: number;
    impactFinancial: number;
    impactCollateral: number;
    impactTotal: number;
    oitYti: number;
    oitYear: number;
    bcRatioDeferral: number;
    bcRatioRisk: number;
    customerCount: string;
}

// ProjectAssetDto fetch results from a single workflowItemId, but compares to the assets to the source project
// (which has an internal source workflowItemId)
export interface ProjectAssetDto extends PmAssetDto {
    projectId: number;
    projectName: string;
    assetInStudy: string;
}

export interface EconomicsOverTimeResponse {
    data: SimpleSeries<string, number>;
    info: {
        tco_forced: string;
        tco_optimal: string;
        tco_force_delta: string;
    };
}

export interface ProjectCostAnalysis {
    data: SimpleSeries<string, number>;
    info: {
        capex_total: string;
        opex_total: string;
        opex_annual_average: string;
        opex_5year_total: string;
        opex_lifetime_total: string;
    };
    list: CostComponent[];
}

export interface CostComponent {
    costComponent: string;
    material: number;
    labour: number;
    vehicleTools: number;
    other: number;
}

/*
 * Request
 */
export class ProjectCreate {
    name: string;
    description: string;
    programId: number;
    ownerId: string;
    ownerName: string;
    status: ProjectStatusType;
    projectCreateType: ProjectCreateType;
    sourceWorkflowItemId: number;
    filters: Filter[];
    shapeGeoJson?: string;
    investmentDriver: string;

    static fromProject(p: Project | ProjectDto, workflowItemId: number): ProjectCreate {
        return {
            name: p.name,
            description: p.description,
            programId: p.programId,
            ownerId: p.ownerId,
            ownerName: p.ownerName,
            status: p.status,
            projectCreateType: p.projectCreateType,
            sourceWorkflowItemId: workflowItemId,
            filters: p.filters,
            shapeGeoJson: p.shapeGeoJson,
            investmentDriver: p.investmentDriver,
        } as ProjectCreate;
    }
}

export class ProjectUpdate {
    id?: number;
    name: string;
    description: string;
    programId: number;
    ownerId: string;
    ownerName: string;
    status: ProjectStatusType;
    filters: Filter[];
    shapeGeoJson?: string;
    investmentDriver: string;

    static fromProject(p: Project | ProjectDto): ProjectUpdate {
        return {
            id: p.id,
            name: p.name,
            description: p.description,
            programId: p.programId,
            ownerId: p.ownerId,
            ownerName: p.ownerName,
            status: p.status,
            filters: p.filters,
            shapeGeoJson: p.shapeGeoJson,
            investmentDriver: p.investmentDriver,
        } as ProjectUpdate;
    }
}

/*
 * Enums
 */
export enum ProjectCreateType {
    SPATIAL = 'SPATIAL',
    PROGRAM_MANAGEMENT = 'PROGRAM_MANAGEMENT',
    DATA_LOAD = 'DATA_LOAD',
}

export const ProjectCreateTypeLabel = {
    [ProjectCreateType.SPATIAL]: 'SPATIAL',
    [ProjectCreateType.PROGRAM_MANAGEMENT]: 'PROGRAM_MANAGEMENT',
    [ProjectCreateType.DATA_LOAD]: 'DATA_LOAD',
};

export enum ProjectStatusType {
    SCOPE = 'SCOPE',
    PROJECT = 'PROJECT',
    BUILT = 'BUILT',
    GENERATED = 'GENERATED',
}

export const ProjectStatusTypeLabel = {
    [ProjectStatusType.SCOPE]: 'Scope',
    [ProjectStatusType.PROJECT]: 'Project',
    [ProjectStatusType.BUILT]: 'Built',
    [ProjectStatusType.GENERATED]: 'Generated',
};

export enum Modes {
    CREATE = 'create',
    EDIT = 'edit',
}

export abstract class ProjectService {
    /*
     * APIs for Projects model
     */

    abstract createProject(req: ProjectCreate): Observable<APIResponse<Project>>;

    abstract updateProject(projectId: number, req: ProjectUpdate): Observable<APIResponse<Project>>;

    abstract deleteProject(projectId: number): Observable<APIResponse<boolean>>;

    abstract getProject(projectId: number): Observable<APIResponse<Project>>;

    abstract getProjectList(params?: Filter[], pageSize?: number): Observable<Project[]>;

    /*
     * APIs for ProjectDto
     */
    abstract getProjectDto(workflowItemId: number, projectId: number): Observable<APIResponse<ProjectDto>>;

    abstract getProjectDtoList(workflowItemId: number, params: Filter[], pageSize?: number): Observable<ProjectDto[]>;

    abstract getProjectDtoListCsv(workflowItemId: number): Observable<APIResponse<DownloadURL>>;

    abstract getProjectDtoListTable(workflowItemId: number): Observable<DataSource>;

    /*
     * APIs for ProjectAssetDto
     */
    abstract getProjectAssetDtoList(
        workflowItemId: number,
        filters?: Filter[],
        pageSize?: number,
    ): Observable<ProjectAssetDto[]>;

    abstract getProjectAssetsDtoListCsv(
        workflowItemId: number,
        filters?: Filter[],
    ): Observable<APIResponse<DownloadURL>>;

    /*
     * APIs for project scope
     */
    abstract getAssetScopeFilters(): Observable<APIResponse<TableFilterConfig[]>>;

    abstract getAssetFilterFieldOptions(
        workflowItemId: number,
        filterOption: FilterFieldOptionRequest,
    ): Observable<APIResponse<FilterFieldOptionResponse>>;

    abstract getAssetsByFiltersListTable(workflowItemId: number, filters: Filter[]): Observable<DataSource>;

    abstract getAssetsByFiltersListCsv(
        workflowItemId: number,
        filters: DataTableParam,
    ): Observable<APIResponse<DownloadURL>>;

    /*
     * APIs for project-modifying actions
     */
    abstract removeProjectOutlierByProjectId(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<boolean>>;

    abstract generateProjectBoundaryByProjectId(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<boolean>>;

    /*
     * Other support APIs
     */
    abstract projectNameExistsValidator(projectName: string): Observable<APIResponse<boolean>>;

    /*
     * Helpers - call other APIs
     */
    abstract getProjectsByProgram(programId: number): Observable<Project[]>;

    abstract getProjectDtoListCsvByProgram(
        workflowItemId: number,
        programId: number,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getProjectDtoListCsvBySegment(
        workflowItemId: number,
        segmentId: number,
    ): Observable<APIResponse<DownloadURL>>;

    abstract projectAssetsDtoListTableByProject(workflowItemId: number, projectId: number): Observable<DataSource>;

    abstract projectAssetsDtoListCsvByProject(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<DownloadURL>>;

    // TODO: this is being removed and refactored into alternatives
    abstract getEconomicsOverTime(id: number, year: number): Observable<APIResponse<EconomicsOverTimeResponse>>;
    abstract findProjectCostAnalysis(id: number): Observable<APIResponse<ProjectCostAnalysis>>;
    abstract downloadCostAnalysisListDtoCsv(projectId: number): Observable<APIResponse<DownloadCSVUrl>>;
}
