import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    CheckboxFieldResultDto,
    FormCellType,
    FormField,
    FormViewModeType,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';

@Component({
    selector: 'ngx-form-field-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends FormFieldBaseComponent<CheckboxFieldResultDto> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    FormMode = FormMode;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.genericInit();

        if (this.fieldForm && this.required) {
            // Add "requiredTrue" validator, since "required" will be satisfied by either boolean value
            const currentValidators = this.fieldForm.validator ? [this.fieldForm.validator] : [];
            this.fieldForm.setValidators([...currentValidators, Validators.requiredTrue]);
            this.fieldForm.updateValueAndValidity({emitEvent: false});
        }
    }

    public checkedChange(value): void {
        /* nothing */
    }

    /*
     * Implement abstract methods
     */
    validate(value: CheckboxFieldResultDto): boolean {
        if (this.required && (value.value == null || !value.value)) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: any): CheckboxFieldResultDto {
        return {
            ...this.currentValue,
            value: item,
        };
    }

    getFormValue(): any {
        return this.result ? this.result.value : '';
    }

    onValidationCheck(): void {
        if (this.required && (this.fieldForm?.value == null || !this.fieldForm?.value)) {
            this.fieldForm?.markAsTouched();
        }
        this.cd.detectChanges();
    }
}
