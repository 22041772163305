import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ECHARTS_TYPE} from '@theme/components/chart-settings/chart-data-download-service';
import {ChartsService} from '@core/utils';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AnalyzerResponse} from '@core/interfaces/engin/analyzer';
import {Chart} from '@core/utils/charts.service';
import {filter, map, share} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {GraphLabel} from '@core/interfaces/common/pages';
import {GraphLabelService} from '../../graph-label.service';
import {ECharts} from 'echarts';

@Component({
    selector: 'ngx-eol-metric-summary-bar',
    templateUrl: './eol-metric-summary-bar.component.html',
    styleUrls: ['./eol-metric-summary-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EolMetricSummaryBarComponent implements OnInit, OnChanges {
    // Component inputs
    @Input('summaryChartData') _summaryChartData: AnalyzerResponse; // raw chart data
    @Input('unitOptions') _unitOptions: string[]; // unit selector options
    @Input('labelOptions') _labelOptions: GraphLabel[]; // label selector options
    @Input('graphId') graphId: string;
    @Input('graphsFormGroup') graphsFormGroup: FormGroup; // active group selection control
    @Input() interactive: boolean = true;
    @Input() ignoreFormControl: boolean = false;

    // Static component data
    EC_TYPE_LOCAL = ECHARTS_TYPE.CUSTOM_BAR_STACKED;
    private summaryChartData: BehaviorSubject<AnalyzerResponse> = new BehaviorSubject<AnalyzerResponse>(null);
    readonly summaryChartData$: Observable<AnalyzerResponse> = this.summaryChartData.asObservable().pipe(
        filter((d) => !!d),
        share(),
    );
    public unitOptions: string[];
    public labelOptions: BehaviorSubject<GraphLabel[]> = new BehaviorSubject<GraphLabel[]>(null);
    public labelOptions$: Observable<any> = this.labelOptions.asObservable();

    public chartInstance: ECharts;

    constructor(private chartsService: ChartsService) {}

    ngOnInit(): void {
        this.summaryChartData.next(this._summaryChartData);
        this.unitOptions = this._unitOptions;
        if (this._labelOptions != null) {
            this.labelOptions.next(this._labelOptions);
        }
    }

    // Propagate external updates of main data source throughout this component
    ngOnChanges(changes: any) {
        if (changes._summaryChartData != null && changes._summaryChartData.currentValue != null) {
            this.summaryChartData.next(changes._summaryChartData.currentValue);
        }
    }

    readonly summaryChart$ = combineLatest<Observable<AnalyzerResponse>, Observable<Chart>, Observable<GraphLabel[]>>([
        this.summaryChartData$,
        this.chartsService.barChartPercentStacked$,
        this.labelOptions$,
    ]).pipe(
        map(([summaryChartData, barChartTemplate, graphLabels]: [AnalyzerResponse, Chart, GraphLabel[]]) => {
            const selectedLabel = GraphLabelService.extractGraphLabel(graphLabels, summaryChartData.graphId);
            const summaryData = this.chartsService.prepareStackedPercentageChartData(
                summaryChartData,
                barChartTemplate.colorsMap,
            );
            return this.prepareSummaryChartOptions(summaryData, barChartTemplate, selectedLabel);
        }),
    );

    private prepareSummaryChartOptions(metadata: any, barChart: Chart, selectedLabel: string) {
        if (!metadata.values) return {};
        const legend = metadata.values.map((item) => item.name);
        let sum = 0;

        try {
            sum = metadata.values.map((item) => item.value).reduce((total, item) => total + item);
            if (sum === 0) throw new Error();
        } catch (e) {
            return this.chartsService.getEmptyChartWithMessage(metadata, barChart);
        }
        return {
            metadata: metadata,
            options: {
                ...barChart.options,
                title: {
                    x: 'center',
                    text: metadata.name,
                    textStyle: {
                        fontSize: 14,
                        color: barChart.colorsMap['primaryFontColor'],
                    },
                },
                grid: {
                    top: 30,
                    right: 0,
                    left: 0,
                    bottom: 30,
                },
                legend: {
                    ...barChart.options.legend,
                    data: legend,
                },
                yAxis: [
                    {
                        ...barChart.options.yAxis[0],
                        show: false,
                    },
                ],
                xAxis: [
                    {
                        ...barChart.options.xAxis[0],
                        max: sum,
                    },
                ],
                series: metadata.values.map((item) => {
                    // Necessary for Health Index labels e.g. "Very Poor (0-30)" -> "Very Poor"
                    const code = item.name.split(' (')[0];

                    return {
                        name: item.name,
                        type: 'bar',
                        stack: '1',
                        tooltip: {
                            ...barChart.options.tooltip,
                            formatter: (params) => {
                                return `${params.data.value ? Math.round((params.value * 100) / sum) : ''}%<br/>
                                ${params.data.name}: ${params.data.formattedValue}`;
                            },
                        },
                        label: {
                            show: true,
                            position: 'inside',
                            color: 'white',
                            textStyle: {
                                fontSize: 14,
                            },
                            formatter: function (params) {
                                if (params.data.value) {
                                    if (selectedLabel === 'value-percent') {
                                        return params.data.value > 3
                                            ? `${params.data.formattedValue} (${Math.round(
                                                  (params.value * 100) / sum,
                                              )}%)`
                                            : '';
                                    } else {
                                        let percent = Math.round((params.value * 100) / sum);
                                        return percent > 2 ? `${percent}%\n${params.data.formattedValue}` : '';
                                    }
                                } else {
                                    return '';
                                }
                            },
                        },
                        itemStyle: {},
                        color: barChart.colorsMap[code],
                        data: [{name: item.name, value: item.value, formattedValue: item.formattedValue}],
                    };
                }),
            },
        };
    }
}
