<div class="h-100">
    <nb-card>
        <nb-card-header>{{ getHeader() }}</nb-card-header>
        <nb-card-body class="p-0">
            <div class="m-3" *ngFor="let field of fields" [ngSwitch]="field.fieldType">
                <ng-container *ngSwitchCase="FieldType.NUMERIC">
                    <ngx-form-field-numeric
                        *ngIf="checkRenderField(field)"
                        [pageMode]="pageMode"
                        [field]="field"
                        [result]="this.formService.extractResultsNumeric(field, results)"
                        [required]="getFieldRequired(field)"
                        [viewMode]="viewMode"
                        [cellType]="cellType"
                        [fieldResultForm]="fieldResultForm"
                        (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                        [checkValidation]="checkValidation"
                    ></ngx-form-field-numeric>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.TEXT">
                    <ngx-form-field-text
                        *ngIf="checkRenderField(field)"
                        [pageMode]="pageMode"
                        [field]="field"
                        [result]="this.formService.extractResultsText(field, results)"
                        [required]="getFieldRequired(field)"
                        [viewMode]="viewMode"
                        [cellType]="cellType"
                        [fieldResultForm]="fieldResultForm"
                        (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                        [checkValidation]="checkValidation"
                    ></ngx-form-field-text>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.SINGLE_SELECT">
                    <ngx-form-field-single-select
                        [pageMode]="pageMode"
                        *ngIf="checkRenderField(field)"
                        [field]="field"
                        [result]="this.formService.extractResultsSingleSelect(field, results)"
                        [required]="getFieldRequired(field)"
                        [viewMode]="viewMode"
                        [cellType]="cellType"
                        [fieldResultForm]="fieldResultForm"
                        (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                        [checkValidation]="checkValidation"
                    ></ngx-form-field-single-select>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.MULTI_SELECT">
                    <ngx-form-field-multi-select
                        *ngIf="checkRenderField(field)"
                        [pageMode]="pageMode"
                        [field]="field"
                        [result]="this.formService.extractResultsMultiSelect(field, results)"
                        [required]="getFieldRequired(field)"
                        [viewMode]="viewMode"
                        [cellType]="cellType"
                        [fieldResultForm]="fieldResultForm"
                        (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                        [checkValidation]="checkValidation"
                    ></ngx-form-field-multi-select>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.IMAGE">
                    <ngx-form-field-photo
                        *ngIf="checkRenderField(field)"
                        [pageMode]="pageMode"
                        [field]="field"
                        [result]="this.formService.extractResultsMedia(field, results)"
                        [required]="getFieldRequired(field)"
                        [viewMode]="viewMode"
                        [cellType]="cellType"
                        [fieldResultForm]="fieldResultForm"
                        (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                        [checkValidation]="checkValidation"
                        [s3service]="s3service"
                    ></ngx-form-field-photo>
                </ng-container>

                <!-- TODO: checkbox; video; video_photo; signature; issue; table -->
            </div>
        </nb-card-body>
    </nb-card>
</div>
