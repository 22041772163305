<div class="position-relative">
    <div [nbPopover]="tabs" nbPopoverPlacement="bottom">
        <input
            nbInput
            fullWidth
            #input
            placeholder="min — max"
            [class.active]="isPopoverShown"
            [formControl]="inputControl"
        />
        <div class="icon-wrapper fullwidth">
            <nb-icon icon="chevron-down" status="basic"></nb-icon>
        </div>

        <div *ngIf="isPopoverShown && inputControl.value" class="icon-wrapper reset" (click)="reset()">
            <nb-icon icon="close-outline" status="basic"></nb-icon>
        </div>
    </div>

    <ng-template #tabs>
        <nb-card [style.width.px]="input.getBoundingClientRect().width">
            <div class="flex p-2">
                <div class="form-field">
                    <label for="min" class="label">Min</label>
                    <input
                        nbInput
                        fullWidth
                        fieldSize="small"
                        type="number"
                        [min]="globalDefault?.min"
                        [max]="globalDefault?.max"
                        id="min"
                        autofocus
                        class="p-1"
                        (keydown.e)="$event.preventDefault()"
                        [formControl]="minControl"
                        (keydown.enter)="max.focus()"
                    />
                </div>

                <div class="form-field">
                    <label for="max" class="label">Max</label>
                    <input
                        nbInput
                        fullWidth
                        fieldSize="small"
                        type="number"
                        [min]="globalDefault?.min"
                        [max]="globalDefault?.max"
                        id="max"
                        #max
                        class="p-1"
                        (keydown.e)="$event.preventDefault()"
                        [formControl]="maxControl"
                        (keydown.enter)="popover.hide()"
                    />
                </div>
            </div>
        </nb-card>
    </ng-template>
</div>
