import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {
    InvestmentOverviewRequest,
    OutcomeChartRequest,
    OutcomeChartResponse,
    OutcomeKpiResponse,
    PmReportingService,
} from '@core/interfaces/engin/program-management/pm-reporting';
import {PmReportingApi} from '@core/backend/engin/api/program-management/pm-reporting.api';

@Injectable()
export class PmReportingServiceImpl extends PmReportingService {
    constructor(private api: PmReportingApi) {
        super();
    }

    /*
     * APIs for Investment Overview
     */
    public getInvestmentOverview(param: InvestmentOverviewRequest): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getInvestmentOverview(param);
    }

    /*
     * APIs for Outcomes (legacy Optimizer)
     */
    public getChartDataSummarySpending(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataSummarySpending(request);
    }

    public getChartDataSummaryOutcomes(request: OutcomeChartRequest): Observable<APIResponse<OutcomeKpiResponse>> {
        return this.api.getChartDataSummaryOutcomes(request);
    }

    public getChartDataProactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataProactiveForecast(request);
    }

    public getChartDataReactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataReactiveForecast(request);
    }

    public getChartDataFailureForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataFailureForecast(request);
    }

    public getChartDataOutageForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataOutageForecast(request);
    }

    public getChartDataRiskForecastByImpact(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataRiskForecastByImpact(request);
    }

    public getChartDataRiskForecastByCategory(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataRiskForecastByCategory(request);
    }
}
