<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <!-- Main content -->
    <ng-container *ngIf="cellType === FormCellType.PREVIOUS; else currentField">
        <p class="value">{{ getValueOrDefault("-") }}</p>
    </ng-container>

    <ng-template #currentField [ngSwitch]="viewMode">
        <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
            <div>
                <textarea
                    #textarea
                    [ngClass]="{
                        'view-mode': pageMode === FormMode.VIEW,
                        'edit-mode': pageMode === FormMode.EDIT,
                        required: fieldForm?.touched && fieldForm?.hasError('required')
                    }"
                    nbInput
                    fullWidth
                    [formControl]="fieldForm"
                    (input)="adjustHeight()"
                ></textarea>
                <ngx-validation-message
                    label="Letter"
                    [showMaxLength]="fieldForm?.hasError('maxlength')"
                    [maxLength]="maxLength"
                ></ngx-validation-message>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
            <ng-container>
                <div
                    [nbTooltip]="this.currentValue?.value"
                    [nbTooltipTrigger]="
                        getValueOrDefault('-').toString().length > 30 ? NbTrigger.HOVER : NbTrigger.NOOP
                    "
                >
                    <p class="value">{{ getValueOrDefault("-") }}</p>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
            <input nbInput fullWidth class="disable-btn" />
        </ng-container>
    </ng-template>
</div>
