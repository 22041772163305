import {GeospatialService} from '../../helper/geospatial-service';
import {AssetFieldsService} from '../../fields/asset-fields.service';
import {Injectable} from '@angular/core';
import {AssetPolygonRendererService} from '../../renderers/asset-polygon-renderer.service';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Polygon from '@arcgis/core/geometry/Polygon';
import {SpatialEntityDataExtended} from '@core/utils/engin/geospatial-viewer/helper/engin-data-prepare.service';
import {GenericPopupService} from '@core/utils/engin/geospatial-viewer/popups/generic-popup.service';

@Injectable()
export class PolygonLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private genericPopupService: GenericPopupService,
        private rendererService: AssetPolygonRendererService,
        private fieldsService: AssetFieldsService,
    ) {}

    /**
     * Create polygon layer for asset population
     * @return FeatureLayer
     */
    public preparePolygonLayer(polygonData: SpatialEntityDataExtended[], id: string, theme: string): FeatureLayer {
        const polygonSource: Graphic[] = this.createPolygonGraphics(polygonData);

        return new FeatureLayer({
            id: id ? id : 'asset_polygon_layer',
            source: polygonSource,
            renderer: this.rendererService.layerRenderer(theme),
            fields: this.fieldsService.getMapFields(),
            popupTemplate: {
                title: '{id}',
                outFields: ['*'],
                content: this.genericPopupService.getGenericPopupFunction.bind(this.genericPopupService),
            },
        });
    }

    /*
     * Create array of Graphics, type = point. Return Graphic[]
     */
    private createPolygonGraphics(areaData: SpatialEntityDataExtended[]): Graphic[] {
        return areaData.map((area: SpatialEntityDataExtended) => {
            return new Graphic({
                geometry: new Polygon({
                    rings: area.geoJsonGeometry.coordinates,
                }),
                attributes: {...area},
            });
        });
    }
}
