import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse, PagingResponse} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    FeederOutlierCreate,
    FeederOutlierDto,
    FeederOutlierEdit,
} from '@core/interfaces/engin/load-profile-analysis/feeder-outlier';

@Injectable()
export class LpFeederOutlierApi {
    private readonly prefix = 'load-profile-analysis/feeder-outliers';

    constructor(private api: HttpService) {}

    public getFeederOutliersList(batchNumber: number, feederId: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list/${batchNumber}/${feederId}`);
    }

    public listFeederOutliersDto(
        batchNumber: number,
        feederId: string,
        pageSize: number,
    ): Observable<PagingResponse<FeederOutlierDto>> {
        return this.api.get(`${this.prefix}/dto/list/${batchNumber}/${feederId}?pageSize=${pageSize}`);
    }

    public downloadFeederOutliersList(
        batchNumber: number,
        feederId: string,
        paramsString?: string,
    ): Observable<DownloadCSVUrl> {
        return this.api
            .get(`${this.prefix}/dto/list/${batchNumber}/${feederId}/csv${paramsString && '?' + paramsString}`)
            .pipe(map((res: APIResponse<DownloadCSVUrl>) => res.response));
    }

    public createOutlierByBatch(batchNumber: number, data: FeederOutlierCreate): Observable<any> {
        return this.api.post(`${this.prefix}/${batchNumber}`, data);
    }

    public editOutlierByBatch(batchNumber: number, data: FeederOutlierEdit): Observable<any> {
        return this.api.put(`${this.prefix}/${batchNumber}`, data);
    }

    public deleteOutliersByBatch(batchNumber: number, outlierIds: number[], feederId: string): Observable<any> {
        return this.api.delete(`${this.prefix}/${batchNumber}`, {
            body: {outlierIds: outlierIds, feederId: feederId},
        });
    }
}
