import {Observable} from 'rxjs';
import {PagingResponse, PowerUnit} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

export interface FeederOutlierDto {
    id: number;
    startDatetime: Date;
    endDatetime: Date;
    feederId: string;
    feederName: string;
    transferToFeederId: string;
    transferToFeederName: string;
    outlierType: OutlierType;
    transferAmount: number;
    loadUnit: PowerUnit;
    label: string;
    outlierSource: string;
    createdByUserId: string;
    createdByUserName: string;
    transferRequest: string;
    transferWorkOrder: string;
    transferType: string;
    numberOfPoints: number;
}

export enum OutlierType {
    LOAD_TRANSFER = 'LOAD_TRANSFER',
    OUTLIER = 'OUTLIER',
    OTHER = 'OTHER',
}

export const OutlierTypeLabels = {
    [OutlierType.LOAD_TRANSFER]: 'Load Transfer',
    [OutlierType.OUTLIER]: 'Outlier',
    [OutlierType.OTHER]: 'Other',
};

export interface FeederOutlierCreate {
    startDatetime: Date;
    endDatetime: Date;
    feederIds: string[];
    transferToFeederId: string | null;
    outlierType: OutlierType;
    transferAmount: number | null;
    loadUnit: PowerUnit;
    label: string | null;
    outlierSource: 'USER';
    createdByUserId: string;
    createdByUserName: string;
    transferRequest: string | null;
    transferWorkOrder: string | null;
    transferType: string | null;
}

export interface FeederOutlierEdit {
    id: number;
    startDatetime: Date;
    endDatetime: Date;
    outlierType: OutlierType;
    label: string;
    createdByUserId: string;
    createdByUserName: string;
    feederId: string;
}

export abstract class LpFeederOutlierService {
    abstract getFeederOutliersList(batchNumber: number, feederId: string): Observable<DataSource>;
    abstract listFeederOutliersDto(
        batchNumber: number,
        feederId: string,
        pageSize: number,
    ): Observable<PagingResponse<FeederOutlierDto>>;
    abstract downloadFeederOutliersList(
        batchNumber: number,
        feederId: string,
        paramsString?: string,
    ): Observable<DownloadCSVUrl>;
    abstract createOutlierByBatch(batchNumber: number, data: FeederOutlierCreate): Observable<any>;
    abstract editOutlierByBatch(batchNumber: number, data: FeederOutlierEdit): Observable<any>;
    abstract deleteOutliersByBatch(batchNumber: number, outlierIds: number[], feederId: string): Observable<any>;
}
