import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PagesStore} from '../../../@store';
import {ChartDataDownloadService, ChartDownloadData, ECHARTS_TYPE} from './chart-data-download-service';
import {BehaviorSubject} from 'rxjs';
import {GraphLabel, GraphLabelOption} from '@core/interfaces/common/pages';
import {ECharts} from 'echarts';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';

@Component({
    selector: 'ngx-chart-settings-popup',
    templateUrl: './chart-settings-popup.component.html',
    styleUrls: ['./chart-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSettingsPopupComponent implements OnInit {
    // Data binding for unit selector functionality
    @Input() unitOptions: {label: string; value: string}[];
    @Input() unitItem: any;
    @Input() labelOptions: BehaviorSubject<GraphLabel[]>;
    @Input() graphId: string;
    @Output() itemChanged = new EventEmitter<any>();
    /*
     * Data download component is enabled by default, and expects chartOptions and chartType data sources
     * - Optional input to disable download button
     * - Optional input of custom CSV file name
     * - Optional input of custom data object
     */
    @Input() disableDataDownload?: boolean;
    @Input() csvFileName?: string;
    @Input() chartOptions?: any;
    @Input() chartType?: ECHARTS_TYPE;
    @Input() preparedDownloadData?: ChartDownloadData;
    // chartInstance: for image download options
    @Input() chartInstance?: ECharts;
    // Input function used for changing axis (if not provided, axis selection is disabled
    @Input() axisChangeFunction: Function;
    @Input() axisOptions: GraphLabelOption[];

    showLabelSelection;
    graphLabels: GraphLabel;
    labelSelected: any;
    showAxisSelection;
    axisSelected: any;

    constructor(public pagesStore: PagesStore, private usageAnalyticsService: UsageAnalyticsService) {}

    ngOnInit() {
        const labelOptionsValue = this.labelOptions ? this.labelOptions.value : null;
        const graphLabelOptions = labelOptionsValue
            ? labelOptionsValue.filter((g) => g.graphId === this.graphId)
            : null;
        // Show selection if the label settings is initialized, has values for this graph, and values are not placeholder
        if (labelOptionsValue && graphLabelOptions && graphLabelOptions[0]) {
            if (graphLabelOptions[0].options.length > 1 && graphLabelOptions[0].options[0].value !== 'none') {
                this.showLabelSelection = true;
                this.labelSelected = graphLabelOptions[0].options.filter((e) => e.selected)[0].value;
                this.graphLabels = graphLabelOptions[0];
            } else {
                this.showLabelSelection = false;
            }
        } else {
            this.showLabelSelection = false;
        }

        // Axis selection is disabled by default
        this.showAxisSelection = false;
        if (this.axisChangeFunction && this.axisOptions) {
            this.showAxisSelection = true;
            this.axisSelected = this.axisOptions.find((a) => a.selected);
        }
    }

    unitsEnabled() {
        return this.unitItem !== null && this.unitItem !== undefined && this.unitOptions && this.unitOptions.length > 0;
    }

    dataDownloadCSV() {
        const fileName = this.getDataDownloadFilename();
        this.usageAnalyticsService.logDownload(fileName, 'Chart Data');
        // If custom data has been provided, send it to the user
        if (this.preparedDownloadData != null) {
            ChartDataDownloadService.downloadChartData(this.preparedDownloadData, fileName);
        } else {
            ChartDataDownloadService.prepareAndDownloadChartData(this.chartOptions, this.chartType, fileName);
        }
    }

    dataDownloadImage(format: 'png' | 'jpg') {
        const fileName = this.getDataDownloadFilename();
        ChartDataDownloadService.downloadChartImage(this.chartInstance, fileName, format);
    }

    private getDataDownloadFilename() {
        let filename = '';
        if (this.csvFileName == null) {
            if (this.chartOptions != null && this.chartOptions.title != null) {
                filename = this.chartOptions.title ? this.chartOptions.title.text.replace(/ /g, '_') : 'chart_data';
            } else {
                filename = this.getCurrentTime();
            }
        } else {
            filename = this.csvFileName;
        }
        return filename;
    }

    private getCurrentTime() {
        const today = new Date();
        /* eslint-disable */
        return `${today.getDate()}_${
            today.getMonth() + 1
        }_${today.getFullYear()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
    }

    public labelChanged(event) {
        this.labelSelected = event;
        let newGraphLabels = this.labelOptions.value;
        newGraphLabels = newGraphLabels.map((graph) => {
            // Extract options for this graph only
            if (graph.graphId === this.graphId) {
                const newLabelOptions = graph.options.map((option) => {
                    // For the item selected in the event, set selected to true
                    if (event === option.value) {
                        return {
                            ...option,
                            selected: true,
                        };
                    } else {
                        // Set all other items to unselected
                        return {
                            ...option,
                            selected: false,
                        };
                    }
                });

                return {
                    ...graph,
                    options: newLabelOptions,
                };
            } else {
                // Do not change options for other graphs
                return {
                    ...graph,
                };
            }
        });
        this.labelOptions.next(newGraphLabels);
        this.usageAnalyticsService.logView('Chart Settings > Label');
    }

    // Axis change through callback function provided into chart settings; specific for each graph
    public axisChanged(event) {
        this.axisChangeFunction(this.chartInstance, event);
    }
}
