import {ModuleWithProviders, NgModule} from '@angular/core';
import {OptimizerService} from '../../interfaces/engin/optimizer';
import {OptimizerServiceImpl} from './services/optimizer.service';
import {AssetsApi} from './api/assets.api';
import {AnalyzerApi} from './api/analyzer.api';
import {OptimizerApi} from './api/optimizer.api';
import {AssetsServiceImpl} from './services/assets.service';
import {AssetsService} from '../../interfaces/engin/assets';
import {AnalyzerService} from '../../interfaces/engin/analyzer';
import {AnalyzerServiceImpl} from './services/analyzer.service';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from '../common/common-backend.module';
import {ForecasterApi} from './api/forecaster.api';
import {ForecasterService} from '../../interfaces/engin/forecaster';
import {ForecasterServiceImpl} from './services/forecaster.service';
import {PlannerApi} from './api/planner.api';
import {AssetDataAuditApi} from './api/data-audit/asset-data-audit.api';
import {AssetDataAuditService} from '../../interfaces/engin/data-audit/asset-data-audit';
import {AssetDataAuditServiceImpl} from './services/data-audit/asset-data-audit.service';
import {WorkflowApi} from './api/workflow.api';
import {WorkflowService} from '@core/interfaces/engin/workflow';
import {WorkflowServiceImpl} from './services/workflow.service';
import {WorkflowValidationService} from '../../interfaces/engin/workflow-validation';
import {WorkflowValidationServiceImpl} from './services/workflow-validation.service';
import {WorkflowValidationApi} from './api/workflow-validation.api';
import {EvaluationDataAuditApi} from './api/data-audit/evaluation-data-audit.api';
import {EvaluationDataAuditServiceImpl} from './services/data-audit/evaluation-data-audit.service';
import {EvaluationDataAuditService} from '../../interfaces/engin/data-audit/evaluation-data-audit';
import {ReportingApi} from '@core/backend/engin/api/reporting.api';
import {ReportingService} from '@core/interfaces/engin/reporting';
import {ReportingServiceImpl} from '@core/backend/engin/services/reporting.service';
import {ConnectivityApi} from '@core/backend/engin/api/connectivity.api';
import {ConnectivityService} from '@core/interfaces/engin/connectivity';
import {ConnectivityServiceImpl} from '@core/backend/engin/services/connectivity.service';
import {JobsApi} from './api/jobs.api';
import {JobsService} from '../../interfaces/engin/jobs';
import {JobsServiceImpl} from './services/jobs.service';
import {LiveApi} from '@core/backend/engin/api/live/live.api';
import {LiveService} from '@core/interfaces/live/live';
import {LiveServiceImpl} from '@core/backend/engin/services/live/live.service';
import {AssetDetailsApi} from '@core/backend/engin/api/asset-details.api';
import {AssetDetailsService} from '@core/interfaces/engin/asset-details';
import {AssetDetailsServiceImpl} from '@core/backend/engin/services/asset-details.service';
import {Sf6GasLeakService} from '@core/interfaces/live/sf6-gas-leak';
import {Sf6GasLeakServiceImpl} from '@core/backend/engin/services/live/sf6-gas-leak.service';
import {HydrogenTrendingService} from '@core/interfaces/live/hydrogen-tending';
import {HydrogenTrendingServiceImpl} from '@core/backend/engin/services/live/hydrogen-trending.service';
import {OilTemperatureMoistureService} from '@core/interfaces/live/oil-temperature-moisture';
import {OilTemperatureMoistureServiceImpl} from '@core/backend/engin/services/live/oil-temperature-moisture.service';
import {Co2LoadService} from '@core/interfaces/live/co2-load';
import {Co2LoadServiceImpl} from '@core/backend/engin/services/live/co2-load.service';
import {OnlineDgaService} from '@core/interfaces/live/online-dga';
import {OnlineDgaServiceImpl} from '@core/backend/engin/services/live/online-dga.service';
import {InsulationMoistureService} from '@core/interfaces/live/insulation-moisture';
import {InsulationMoistureServiceImpl} from '@core/backend/engin/services/live/insulation-moisture.service';
import {Sf6GasLeakApi} from '@core/backend/engin/api/live/sf6-gas-leak.api';
import {HydrogenTrendingApi} from '@core/backend/engin/api/live/hydrogen-trending.api';
import {OilTemperatureMoistureApi} from '@core/backend/engin/api/live/oil-temperature-moisture.api';
import {Co2LoadApi} from '@core/backend/engin/api/live/co2-load.api';
import {OnlineDgaApi} from '@core/backend/engin/api/live/online-dga.api';
import {InsulationMoistureApi} from '@core/backend/engin/api/live/insulation-moisture.api';
import {LoadForecastApi} from '@core/backend/engin/api/load-forecast/load-forecast.api';
import {LoadForecastService} from '@core/interfaces/engin/load-forecast/load-forecast';
import {LoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/load-forecast.service';
import {DataPreparationService} from '@core/interfaces/engin/data-preparation';
import {DataPreparationServiceImpl} from '@core/backend/engin/services/data-preparation.service';
import {DataPreparationApi} from '@core/backend/engin/api/data-preparation.api';
import {ScenarioLoadForecastService} from '@core/interfaces/engin/load-forecast/scenario';
import {ScenarioLoadForecastApi} from '@core/backend/engin/api/load-forecast/scenario.api';
import {ScenarioLoadForecastServiceImpl} from './services/load-forecast/scenario.service';
import {StationLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/station.service';
import {SystemLoadForecastService} from '@core/interfaces/engin/load-forecast/system';
import {SystemLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/system.service';
import {StationLoadForecastService} from '@core/interfaces/engin/load-forecast/station';
import {FeederLoadForecastService} from '@core/interfaces/engin/load-forecast/feeder';
import {FeederLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/feeder.service';
import {VaultLoadForecastService} from '@core/interfaces/engin/load-forecast/vault';
import {DemandFactorService} from '@core/interfaces/engin/load-forecast/demand-factor';
import {VaultLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/vault.service';
import {DemandFactorServiceImpl} from '@core/backend/engin/services/load-forecast/demand-factor.service';
import {SystemLoadForecastApi} from '@core/backend/engin/api/load-forecast/system.api';
import {StationLoadForecastApi} from '@core/backend/engin/api/load-forecast/station.api';
import {FeederLoadForecastApi} from '@core/backend/engin/api/load-forecast/feeder.api';
import {VaultLoadForecastApi} from '@core/backend/engin/api/load-forecast/vault.api';
import {DemandFactorApi} from '@core/backend/engin/api/load-forecast/demand-factor.api';
import {SectionLoadForecastServiceImpl} from './services/load-forecast/line-section.service';
import {SectionLoadForecastService} from '@core/interfaces/engin/load-forecast/line-section';
import {SectionLoadForecastApi} from '@core/backend/engin/api/load-forecast/line-section.api';
import {MeterLoadForecastApi} from './api/load-forecast/meter.api';
import {DxTransformerLoadForecastService} from '@core/interfaces/engin/load-forecast/dx-transformer';
import {DxTransformerLoadForecastServiceImpl} from './services/load-forecast/dx-transformer.service';
import {MeterLoadForecastService} from '@core/interfaces/engin/load-forecast/meter';
import {MeterLoadForecastServiceImpl} from './services/load-forecast/meter.service';
import {DxTransformerLoadForecastApi} from '@core/backend/engin/api/load-forecast/dx-transformer.api';
import {LandingZoneService} from '@core/interfaces/landing-zone';
import {LandingZoneServiceImpl} from '@core/backend/engin/services/landing-zone.service';
import {LandingZoneApi} from '@core/backend/engin/api/landing-zone.api';
import {ReportConfigApi} from './api/reporting/report-config.api';
import {ReportDataApi} from '@core/backend/engin/api/reporting/report-data.api';
import {ReportRenderApi} from '@core/backend/engin/api/reporting/report-render.api';
import {ReportConfigService} from '@core/interfaces/engin/reporting/report-config';
import {ReportRenderService} from '@core/interfaces/engin/reporting/report-render';
import {ReportDataService} from '@core/interfaces/engin/reporting/report-data';
import {ReportConfigServiceImpl} from '@core/backend/engin/services/reporting/report-config.service';
import {ReportDataServiceImpl} from '@core/backend/engin/services/reporting/report-data.service';
import {ReportRenderServiceImpl} from '@core/backend/engin/services/reporting/report-render.service';
import {PmProgramService} from '@core/interfaces/engin/program-management/pm-program';
import {PmProgramServiceImpl} from './services/program-management/pm-program.service';
import {PmSegmentApi} from './api/program-management/pm-segment.api';
import {PmSegmentService} from '@core/interfaces/engin/program-management/pm-segment';
import {PmSegmentServiceImpl} from './services/program-management/pm-segment.service';
import {PmProjectService} from '@core/interfaces/engin/program-management/pm-project';
import {PmProjectServiceImpl} from './services/program-management/pm-project.service';
import {PmProgramApi} from './api/program-management/pm-program.api';
import {PmProjectApi} from './api/program-management/pm-project.api';
import {PmSensitivityService} from '@core/interfaces/engin/program-management/pm-sensitivity';
import {PmSensitivityServiceImpl} from './services/program-management/pm-sensitivty.service';
import {PmSensitivityApi} from './api/program-management/pm-sensitivity.api';
import {AssetRegistryServiceImpl} from '@core/backend/engin/services/asset-registry.service';
import {AssetRegistryService} from '@core/interfaces/asset-registry';
import {AssetRegistryApi} from '@core/backend/engin/api/asset-registry.api';
import {StatusChangeService} from '@core/interfaces/engin/status-change';
import {StatusChangeServiceImpl} from './services/status-change.service';
import {StatusChangeApi} from './api/status-change.api';
import {MaintenancePlanningService} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {MaintenancePlanningServiceImpl} from '@core/backend/engin/services/maintenance-planning/maintenance-planning.service';
import {MaintenancePlanningApi} from '@core/backend/engin/api/maintenace-planning/maintenance-planning.api';
import {S3Api} from '@core/backend/common/api/s3.api';
import {S3ServiceImpl} from '@core/backend/engin/services/s3Service';
import {S3Service} from '@core/interfaces/common/s3';

const API = [
    AnalyzerApi,
    AssetsApi,
    OptimizerApi,
    ForecasterApi,
    WorkflowApi,
    WorkflowValidationApi,
    AssetDataAuditApi,
    EvaluationDataAuditApi,
    ConnectivityApi,
    ReportingApi,
    PlannerApi,
    PmSensitivityApi,
    PmProgramApi,
    PmSegmentApi,
    PmProjectApi,
    LandingZoneApi,
    LiveApi,
    JobsApi,
    AssetDetailsApi,
    AssetRegistryApi,
    StatusChangeApi,
    MaintenancePlanningApi,

    ReportConfigApi,
    ReportDataApi,
    ReportRenderApi,
    S3Api,
];

const LIVE_API = [
    LiveApi,
    Sf6GasLeakApi,
    HydrogenTrendingApi,
    OilTemperatureMoistureApi,
    Co2LoadApi,
    OnlineDgaApi,
    InsulationMoistureApi,
];

const FORECAST_API = [
    LoadForecastApi,
    ScenarioLoadForecastApi,
    SystemLoadForecastApi,
    StationLoadForecastApi,
    FeederLoadForecastApi,
    VaultLoadForecastApi,
    SectionLoadForecastApi,
    DxTransformerLoadForecastApi,
    MeterLoadForecastApi,
    DemandFactorApi,
    DataPreparationApi,
];

const SERVICES = [
    {provide: AnalyzerService, useClass: AnalyzerServiceImpl},
    {provide: AssetsService, useClass: AssetsServiceImpl},
    {provide: OptimizerService, useClass: OptimizerServiceImpl},
    {provide: ForecasterService, useClass: ForecasterServiceImpl},
    {provide: WorkflowService, useClass: WorkflowServiceImpl},
    {provide: WorkflowValidationService, useClass: WorkflowValidationServiceImpl},
    {provide: AssetDataAuditService, useClass: AssetDataAuditServiceImpl},
    {provide: EvaluationDataAuditService, useClass: EvaluationDataAuditServiceImpl},
    {provide: ConnectivityService, useClass: ConnectivityServiceImpl},
    {provide: ReportingService, useClass: ReportingServiceImpl},
    {provide: PmSensitivityService, useClass: PmSensitivityServiceImpl},
    {provide: PmProgramService, useClass: PmProgramServiceImpl},
    {provide: PmSegmentService, useClass: PmSegmentServiceImpl},
    {provide: PmProjectService, useClass: PmProjectServiceImpl},
    {provide: LandingZoneService, useClass: LandingZoneServiceImpl},
    {provide: AssetRegistryService, useClass: AssetRegistryServiceImpl},
    {provide: JobsService, useClass: JobsServiceImpl},
    {provide: LiveService, useClass: LiveServiceImpl},
    {provide: AssetDetailsService, useClass: AssetDetailsServiceImpl},
    {provide: ReportConfigService, useClass: ReportConfigServiceImpl},
    {provide: ReportDataService, useClass: ReportDataServiceImpl},
    {provide: ReportRenderService, useClass: ReportRenderServiceImpl},
    {provide: StatusChangeService, useClass: StatusChangeServiceImpl},
    {provide: MaintenancePlanningService, useClass: MaintenancePlanningServiceImpl},
    {provide: S3Service, useClass: S3ServiceImpl},
];

const LIVE_SERVICES = [
    {provide: LiveService, useClass: LiveServiceImpl},
    {provide: Sf6GasLeakService, useClass: Sf6GasLeakServiceImpl},
    {provide: HydrogenTrendingService, useClass: HydrogenTrendingServiceImpl},
    {provide: OilTemperatureMoistureService, useClass: OilTemperatureMoistureServiceImpl},
    {provide: Co2LoadService, useClass: Co2LoadServiceImpl},
    {provide: OnlineDgaService, useClass: OnlineDgaServiceImpl},
    {provide: InsulationMoistureService, useClass: InsulationMoistureServiceImpl},
];

const FORECAST_SERVICES = [
    {provide: LoadForecastService, useClass: LoadForecastServiceImpl},
    {provide: ScenarioLoadForecastService, useClass: ScenarioLoadForecastServiceImpl},
    {provide: SystemLoadForecastService, useClass: SystemLoadForecastServiceImpl},
    {provide: StationLoadForecastService, useClass: StationLoadForecastServiceImpl},
    {provide: FeederLoadForecastService, useClass: FeederLoadForecastServiceImpl},
    {provide: VaultLoadForecastService, useClass: VaultLoadForecastServiceImpl},
    {provide: SectionLoadForecastService, useClass: SectionLoadForecastServiceImpl},
    {provide: DxTransformerLoadForecastService, useClass: DxTransformerLoadForecastServiceImpl},
    {provide: MeterLoadForecastService, useClass: MeterLoadForecastServiceImpl},
    {provide: DemandFactorService, useClass: DemandFactorServiceImpl},
    {provide: DataPreparationService, useClass: DataPreparationServiceImpl},
];

@NgModule({
    imports: [CommonModule, CommonBackendModule],
    exports: [CommonBackendModule],
})
export class EnginBackendModule {
    static forRoot(): ModuleWithProviders<EnginBackendModule> {
        return {
            ngModule: EnginBackendModule,
            providers: [...API, ...LIVE_API, ...FORECAST_API, ...SERVICES, ...LIVE_SERVICES, ...FORECAST_SERVICES],
        };
    }
}
