import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {APIException, ExceptionDisplayLevel, ExceptionDto} from './exception.dto';
import {NbToastrService} from '@nebular/theme';
import {UsersStore} from '@store/common/users.store';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private authUrls: string[] = ['/authorize/token', '/authorize/refresh-token'];

    // Note: never put an application service (such as a store) inside a class which implements HttpInterceptor ???
    constructor(private toastrService: NbToastrService, private router: Router, private userStore: UsersStore) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse, caught) => {
                const reqUrl: string = error?.url || 'none';
                const reqFromLogin: boolean =
                    this.authUrls.map((authUrl) => reqUrl.includes(authUrl)).filter((r) => r).length > 0;

                // Failed request for login API is handled through auth process, retain entire error message
                if (reqFromLogin) {
                    return throwError(error);
                }

                let errorMessage = '';
                // Client-side error is type ErrorEvent
                if (error.error instanceof ErrorEvent) {
                    errorMessage = `Error: ${error.error.message}`;
                    this.toastrService.danger(`Error: ${error.error.message}`, 'External error. Please try again.', {
                        duration: 10000,
                    });
                    return throwError(errorMessage);
                } else if (error.status === 401 && !reqFromLogin) {
                    const jwt = JSON.parse(localStorage.getItem('auth_app_token'));
                    if (this.userStore.getCurrentUser() && jwt) {
                        this.userStore.logout();
                    }
                    return throwError(errorMessage);
                } else if (error.status === 403) {
                    setTimeout(() => {
                        this.toastrService.warning(error.error.error, 'Insufficient Permissions', {duration: 0});
                    }, 1500);
                    return throwError(errorMessage);
                } else {
                    const apiException = error.error.response as APIException;
                    if (apiException != null && apiException.exceptions) {
                        const exceptions = apiException.exceptions as ExceptionDto[];
                        exceptions
                            .filter((e) => e.errorDisplayType === 'GLOBAL')
                            .forEach((e) => {
                                this.sendToastr(`Error: ${e.message}`, e.exceptionDetail, 10000, e.errorLevel);
                            });
                        return throwError(apiException);
                    } else {
                        this.toastrService.danger(
                            `Error: ${error.error.error}`,
                            'Internal error. Please try again or contact your administrator.',
                            {duration: 10000},
                        );
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        return throwError(errorMessage);
                    }
                }
            }),
        );
    }

    private sendToastr(title, body, duration, level: ExceptionDisplayLevel) {
        switch (level) {
            case ExceptionDisplayLevel.DANGER:
                this.toastrService.danger(body, title, {duration: duration});
                break;
            case ExceptionDisplayLevel.WARNING:
                this.toastrService.warning(body, title, {duration: duration});
                break;
            case ExceptionDisplayLevel.INFO:
                this.toastrService.info(body, title, {duration: duration});
                break;
            case ExceptionDisplayLevel.NONE:
                break;
        }
    }
}
