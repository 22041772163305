import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {
    AssetInspectionResultDto,
    CheckboxFieldResultDto,
    FieldType,
    FormField,
    IssueFieldResultDto,
    MediaFieldResultDto,
    NumericFieldResultDto,
    SelectFieldOptionResultDto,
    TableFieldResultDto,
    TextFieldResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';

@Injectable()
export class FormService {
    /*
     * Check if a field is required, based on configuration, and also viable, i.e., field can be completed by user.
     * - e.g., select-type field with no configured options is not viable
     */
    public checkRequiredAndViable(fieldConfig: FormField, currentFormResults: AssetInspectionResultDto): boolean {
        if (this.fieldIsSelectType(fieldConfig) && this.checkIfFieldHasNoOptions(fieldConfig)) {
            return false;
        }

        return this.requiredCheck(fieldConfig, currentFormResults);
    }

    public requiredCheck(fieldConfig: FormField, currentFormResults: AssetInspectionResultDto): boolean {
        switch (fieldConfig.requiredType) {
            case 'FIXED':
                return this.fixedProcess(fieldConfig, 'requiredFixedValue');
            case 'CUSTOM':
                return this.customProcess(fieldConfig, currentFormResults);
        }
    }

    public visibleCheck(fieldConfig: FormField, currentFormResults: AssetInspectionResultDto): boolean {
        switch (fieldConfig.visibleType) {
            case 'FIXED':
                return this.fixedProcess(fieldConfig, 'visibleFixedValue');
            case 'CUSTOM':
                return this.customProcess(fieldConfig, currentFormResults);
        }
    }

    private fixedProcess(fieldConfig: FormField, property: string): boolean {
        return fieldConfig[property];
    }

    private customProcess(fieldConfig: FormField, currentFormResults: AssetInspectionResultDto): boolean {
        const conditionMatched = fieldConfig.conditions?.some((condition) => condition.conditionType === 'REQUIRED');
        if (conditionMatched) {
            if (currentFormResults == null) return false;
            const condition = fieldConfig.conditions[0];
            const resultList = [];
            Object.keys(currentFormResults).map((key) => {
                if (typeof currentFormResults[key] === 'object') {
                    currentFormResults[key].map((item) => {
                        if (item.fieldId === condition.relatedFieldId) {
                            resultList.push(item.value);
                        }
                    });
                }
            });
            return this.compareValue([condition.relatedFieldValues], resultList)
                ? condition.conditionValue
                : condition.defaultValue;
        } else {
            return false;
        }
    }

    private compareValue(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        let sortedArr1 = arr1.slice().sort();
        let sortedArr2 = arr2.slice().sort();
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }

    spaceValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const value = control.value.length ? control.value.trim() : null;
            if (value?.length === 0) {
                return of({required: true});
            } else {
                return of(null); // Replace with actual validation logic as needed
            }
        };
    }

    /** Returns true if a field is SINGLE/MULTI SELECT, or ISSUE type */
    private fieldIsSelectType(controlField: FormField): boolean {
        return [FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT, FieldType.ISSUES].includes(controlField?.fieldType);
    }

    /** Returns true field has no configured options */
    private checkIfFieldHasNoOptions(controlField: FormField): boolean {
        return controlField.options?.length == 0;
    }

    /*
     * Extract field results by field type
     */
    public getResultsByField(
        field: FormField,
        results: AssetInspectionResultDto,
    ):
        | NumericFieldResultDto
        | TextFieldResultDto
        | CheckboxFieldResultDto
        | SelectFieldOptionResultDto
        | SelectFieldOptionResultDto[]
        | MediaFieldResultDto[]
        | MediaFieldResultDto
        | TableFieldResultDto
        | IssueFieldResultDto[] {
        switch (field.fieldType) {
            case FieldType.NUMERIC:
                return results?.numberFields?.find((f) => f.fieldId === field.id);
            case FieldType.TEXT:
                return results?.textFields?.find((f) => f.fieldId === field.id);
            case FieldType.CHECKBOX:
                return results?.checkboxFields?.find((f) => f.fieldId === field.id);
            case FieldType.SINGLE_SELECT:
                return results?.selectFields?.find((f) => f.fieldId === field.id);
            case FieldType.MULTI_SELECT:
                return results?.selectFields?.filter((f) => f.fieldId === field.id);
            case FieldType.IMAGE:
            case FieldType.VIDEO:
            case FieldType.IMAGE_VIDEO:
                return results?.mediaFields?.filter((f) => f.fieldId === field.id);
            case FieldType.SIGNATURE:
                return results?.mediaFields?.find((f) => f.fieldId === field.id);
            case FieldType.TABLE:
                return {
                    fieldId: results?.tableNumberColumns[0]?.fieldId,
                    fieldType: FieldType.TABLE,
                    tableNumberColumns: results?.tableNumberColumns?.filter((f) => f.fieldId === field.id),
                    tableTextColumns: results?.tableTextColumns?.filter((f) => f.fieldId === field.id),
                };
            case FieldType.ISSUES:
                return results?.issueFields?.filter((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsNumeric(field: FormField, results: AssetInspectionResultDto): NumericFieldResultDto {
        switch (field.fieldType) {
            case FieldType.NUMERIC:
                return results?.numberFields?.find((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsText(field: FormField, results: AssetInspectionResultDto): TextFieldResultDto {
        switch (field.fieldType) {
            case FieldType.TEXT:
                return results?.textFields?.find((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsCheckbox(field: FormField, results: AssetInspectionResultDto): CheckboxFieldResultDto {
        switch (field.fieldType) {
            case FieldType.CHECKBOX:
                return results?.checkboxFields?.find((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsSingleSelect(field: FormField, results: AssetInspectionResultDto): SelectFieldOptionResultDto {
        switch (field.fieldType) {
            case FieldType.SINGLE_SELECT:
                return results?.selectFields?.find((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsMultiSelect(
        field: FormField,
        results: AssetInspectionResultDto,
    ): SelectFieldOptionResultDto[] {
        switch (field.fieldType) {
            case FieldType.MULTI_SELECT:
                return results?.selectFields?.filter((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsMedia(field: FormField, results: AssetInspectionResultDto): MediaFieldResultDto[] {
        switch (field.fieldType) {
            case FieldType.IMAGE:
            case FieldType.VIDEO:
            case FieldType.IMAGE_VIDEO:
            case FieldType.SIGNATURE:
                return results?.mediaFields?.filter((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    public extractResultsSignature(field: FormField, results: AssetInspectionResultDto): MediaFieldResultDto {
        switch (field.fieldType) {
            case FieldType.SIGNATURE:
                return results?.mediaFields?.find((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }

    // public extractResultsTable(field: FormField, results: AssetInspectionResultDto): {numberTable: TableColumnNumberResultDto[], textTable: TableColumnTextResultDto[]} {
    public extractResultsTable(field: FormField, results: AssetInspectionResultDto): any {
        switch (field.fieldType) {
            case FieldType.TABLE:
                return {
                    tableNumberColumns: results?.tableNumberColumns?.filter((f) => f.fieldId === field.id),
                    tableTextColumns: results?.tableTextColumns?.filter((f) => f.fieldId === field.id),
                };
        }
    }

    public extractResultsIssues(field: FormField, results: AssetInspectionResultDto): IssueFieldResultDto[] {
        switch (field.fieldType) {
            case FieldType.ISSUES:
                return results?.issueFields?.filter((f) => f.fieldId === field.id);
            default:
                return null;
        }
    }
}
