import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {APIResponse, Filter, FilterOperatorType, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {
    ProgramService,
    Program,
    ProgramBudgetOutcome,
    ProgramUpdateBudgetDto,
    ProgramCreateDto,
    ProgramFullDto,
    ProgramUpdateDto,
    ProgramDto,
    ProgramBudgetRequest,
    ProgramBudgetByOutcomeRequest,
    InvestmentPriorityType,
} from '@core/interfaces/engin/program-management/program';
import {ProgramApi} from '../../api/program-management/program.api';
import {map} from 'rxjs/operators';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';
import {KpiType} from '@core/interfaces/engin/program-management/segment';

@Injectable()
export class ProgramServiceImpl extends ProgramService {
    constructor(private api: ProgramApi) {
        super();
    }

    /*
     * APIs for Programs model
     */
    public createProgram(programCreate: ProgramCreateDto): Observable<APIResponse<Program>> {
        return this.api.createProgram(programCreate);
    }

    public editProgramDetails(programId: number, programUpdate: ProgramUpdateDto): Observable<APIResponse<Program>> {
        return this.api.editProgramDetails(programId, programUpdate);
    }

    public deleteProgram(programId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteProgram(programId);
    }

    public editProgramBudget(
        workflowItemId: number,
        updateDto: ProgramUpdateBudgetDto,
    ): Observable<APIResponse<Program>> {
        return this.api.editProgramBudget(workflowItemId, updateDto);
    }

    public getProgramList(params?: Filter[], pageSize?: number): Observable<Program[]> {
        return this.api.getProgramList(params, pageSize).pipe(map((resp) => resp.items));
    }

    /*
     * APIs for ProgramDto
     */
    public getProgramDto(programId: number, request: PmDataRequest): Observable<APIResponse<ProgramDto>> {
        return this.api.getProgramDto(programId, request);
    }

    public getProgramDtoList(request: PmDataRequest, params: Filter[], pageSize?: number): Observable<ProgramDto[]> {
        return this.api.getProgramDtoList(request, params, pageSize).pipe(map((resp) => resp.items));
    }

    public getProgramDtoListCsv(request: PmDataRequest, params?: Filter[]): Observable<APIResponse<DownloadURL>> {
        return this.api.getProgramDtoListCsv(request, params);
    }

    public getProgramDtoListTable(request: PmDataRequest): Observable<DataSource> {
        return this.api.getProgramDtoListTable(request);
    }

    /*
     * APIs for ProgramFullDto
     */
    public getProgramDtoFull(programId: number, req: PmDataRequest): Observable<APIResponse<ProgramFullDto>> {
        return this.api.getProgramDtoFull(programId, req);
    }

    /*
     * APIs related to analysis of results and budgets
     */
    public findProgramKpis(programId: number, req: PmDataRequest): Observable<APIResponse<KpiData[]>> {
        return this.api.findProgramKpis(programId, req);
    }

    public getBudgetChart(req: ProgramBudgetRequest): Observable<APIResponse<SimpleMultiSeries<number>>> {
        return this.api.getBudgetChart(req);
    }

    public getProgramBudgetByOutcomes(
        req: ProgramBudgetByOutcomeRequest,
    ): Observable<APIResponse<ProgramBudgetOutcome[]>> {
        return this.api.getProgramBudgetByOutcomes(req);
    }

    /*
     * Other support APIs
     */
    public getInvestmentPriorityOptions(): Observable<APIResponse<InvestmentPriorityType[]>> {
        return this.api.getInvestmentPriorityOptions();
    }

    public getKpiOptions(): Observable<APIResponse<KpiType[]>> {
        return this.api.getKpiOptions();
    }

    public validateProgramName(programName: string): Observable<boolean> {
        return this.api.validateProgramName(programName).pipe(map((res: APIResponse<boolean>) => res.response));
    }

    /*
     * Helpers - call other APIs
     */
    public findAllBySegment(segmentId: number, request: PmDataRequest): Observable<ProgramDto[]> {
        const filter: Filter = {
            fieldKey: 'segmentId',
            operator: FilterOperatorType.EQUAL,
            value: segmentId,
        };
        return this.getProgramDtoList(request, [filter], 100);
    }

    // TODO: refactor
    downloadProgramAssets(programId: number, workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadProgramAssets(programId, workflowItemId);
    }
}
