import {AdditionalMapLayer, MapLayerDetails} from '../../../../../../pages/geospatial-viewer/model/layers';
import {DistrictData} from '../../../../../../pages/geospatial-viewer/model/api';
import {DistrictSummarizeFieldsService} from '../../fields/district-summarize-fields.service';
import {DistrictSummarizeRendererService} from '../../renderers/district-summarize-renderer.service';
import {DistrictSummarizePopupService} from '../../popups/district-summarize-popup.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';

@Injectable()
export class DistrictSummarizeLayerService {
    constructor(
        private fieldsService: DistrictSummarizeFieldsService,
        private popupService: DistrictSummarizePopupService,
        private rendererService: DistrictSummarizeRendererService,
    ) {}

    public prepareDistrictSummarizeLayer(
        layer: AdditionalMapLayer,
        data: DistrictData[],
        details: MapLayerDetails[],
    ): FeatureLayer {
        const districtSummarizeLayer: FeatureLayer = new FeatureLayer({
            id: layer.code,
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createGraphics(data),
            renderer: this.rendererService.customLayerRenderer(),
            fields: this.fieldsService.getCustomMapFields(),
            popupTemplate: {
                outFields: ['*'],
                title: '{id}',
                content: this.popupService.getCustomPopoutFunction,
            },
        });

        return districtSummarizeLayer;
    }

    /*
     * Create array of Graphics, type = polyline. Return Graphic[]
     */
    private createGraphics(data: DistrictData[]): Graphic[] {
        return data.map((district: DistrictData) => {
            return new Graphic({
                geometry: new Polygon({
                    rings: district.geoJsonGeometry.coordinates,
                }),
                attributes: {
                    // Unique identifiers
                    ObjectID: district.id,
                    // Data
                    districtName: district.districtName,
                    districtDescription: district.districtDescription,
                    data: 0.0, // todo
                    displayCategory: '', // todo
                },
            });
        });
    }
}
