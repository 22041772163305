import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeospatialFilterSettings} from '@core/interfaces/common/popout';
import {GeospatialViewerControlsApi} from './geospatial-viewer-controls.api';
import {GeospatialViewerConfiguration} from '../model/viewer-config';
import {APIResponse, FilterFieldOption} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialViewerControlsService {
    constructor(private api: GeospatialViewerControlsApi) {}

    // Configuration
    public getConfig(): Observable<GeospatialViewerConfiguration> {
        return this.api.getConfig();
    }

    getSpatialFilterSettings(): Observable<GeospatialFilterSettings> {
        return this.api.getGeospatialFilterSettings();
    }

    // Filters
    public getFilterFieldOptions(
        workflowItemId: number,
        fieldKey: string,
        limit?: number,
        search?: string,
        filters?: any[],
    ): Observable<APIResponse<FilterFieldOption[]>> {
        return this.api.getFilterFieldOptions(workflowItemId, fieldKey, limit, search, filters);
    }
}
