import {Component, forwardRef, Input} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    Validator,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

@Component({
    selector: 'ngx-input-by-type',
    template: `
        <input
            nbInput
            fullWidth
            [ngClass]="{required: showRequired, 'has-error': hasError}"
            (input)="onInput($event)"
            [value]="value"
            [placeholder]="placeholder"
        />
    `,
    styleUrls: ['input-box-by-type.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputBoxByTypeComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputBoxByTypeComponent),
            multi: true,
        },
    ],
})
export class InputBoxByTypeComponent implements ControlValueAccessor, Validator {
    @Input() inputType: 'DOUBLE' | 'INTEGER' | 'STRING' | 'TEXT' = 'STRING';
    @Input() placeholder: string = '';
    @Input() showRequired?: boolean = false;

    value: any = null;
    hasError = false;

    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        let inputValue = inputElement.value.trim();

        if (inputValue.length === 0) {
            this.value = null;
        } else {
            switch (this.inputType.toUpperCase()) {
                case 'INTEGER':
                    if (/^\d+$/.test(inputValue)) {
                        this.value = parseInt(inputValue, 10);
                    } else {
                        this.setInvalid();
                    }
                    break;

                case 'DOUBLE':
                    if (/^\d*\.?\d*$/.test(inputValue)) {
                        this.value = parseFloat(inputValue);
                    } else {
                        this.setInvalid();
                    }
                    break;

                default:
                    this.value = inputValue;
            }
        }

        this.hasError = !this.validateInput(this.value);

        this.onChange(this.value);
        this.onTouched();
    }

    writeValue(value: any): void {
        this.value = value;
        this.hasError = !this.validateInput(value);
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.hasError ? {invalid: true} : null;
    }

    private validateInput(value: any): boolean {
        if (value === null) return false;

        switch (this.inputType.toUpperCase()) {
            case 'INTEGER':
                return Number.isInteger(value);
            case 'DOUBLE':
                return typeof value === 'number' && !isNaN(value);
            default:
                return true;
        }
    }

    private setInvalid(): void {
        this.value = null;
        this.hasError = true;
        this.onChange(null);
    }
}
