import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    AssignMeterParentRequest,
    LpAnalysisStatusType,
    LpReviewStatusType,
} from '@core/interfaces/engin/load-profile-analysis/feeder-review';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

export interface OutputMeterDto {
    parentFeederId: string;
    parentFeederName: string;
    reviewStatus: LpReviewStatusType;
    meterId: string;
    meterName: string;
    meterType: LpMeterType;
    loadType: MeterLoadType;
    meterLoadTypeDesc: string;
    station: string;
    region: string;
    statusDataCompleteness: LpAnalysisStatusType;
    completenessActual: number;
    completenessExpected: number;
}

export enum MeterLoadType {
    LOAD = 'LOAD',
    SOURCE = 'SOURCE',
}

export const MeterLoadTypeLabels = {
    [MeterLoadType.LOAD]: 'Load',
    [MeterLoadType.SOURCE]: 'Source',
};

export interface MetersSummary {
    parentExistsPercent: number;
    parentNotExistsPercent: number;
}

export enum LpMeterType {
    LOAD = 'LOAD',
    DER = 'DER',
}

export const LpMeterTypeLabels = {
    [LpMeterType.LOAD]: 'Load',
    [LpMeterType.DER]: 'DER',
};

export interface MergeMeterRequest {
    newMeterId: string;
    newMeterName: string;
    newLoadType: MeterLoadType;
    newMeterType: LpMeterType;
    selectedMeterIds: string[];
    newFeederId: string;
}

export abstract class OutputMeterLPService {
    abstract getMeterDataList(batchNumber: number): Observable<DataSource>;
    abstract downloadMeterDataList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl>;

    abstract getMetersSummary(batchNumber: number): Observable<MetersSummary>;

    abstract deleteFeeders(batchNumber: number, meterIds: string[]): Observable<any>;

    abstract mergeMeters(batchNumber: number, mergeMeterRequest: MergeMeterRequest): Observable<any>;

    abstract assignNewParent(batchNumber: number, data: AssignMeterParentRequest): Observable<any>;
}
