import {
    CustomerDemographicData,
    DataDetails,
    DistrictData,
    RoadMoratoriumData,
    SpatialEntityData,
} from '../../../../../pages/geospatial-viewer/model/api';
import {Metric, ValueType} from '../../../../../pages/geospatial-viewer/model/metric';
import {EnginDataPrepareService, SpatialEntityDataExtended} from './engin-data-prepare.service';
import {Injectable} from '@angular/core';
import {Project} from '@core/interfaces/engin/program-management/project';
import {ProjectLayerData} from '../../../../../pages/geospatial-viewer/model/project';
import {GeospatialTooltipType} from '../../../../../pages/geospatial-viewer/model/tooltip.model';

@Injectable()
export class GeospatialService {
    constructor(private enginDataPrepareService: EnginDataPrepareService) {}

    // Import from renderer service?
    public getDisplayCategoryParser(valueType: ValueType, dataDetails: DataDetails) {
        if (valueType === ValueType.CATEGORY) {
            return function (rawData) {
                // name.split(" (")[0]) handles cases with labels like "Very Poor (0-25)" being mapped to "Very Poor"
                switch (rawData.split(' (')[0]) {
                    // Category 5
                    case 'FP >=20%':
                    case 'Risk >=$1M':
                    case 'Ratio >=2':
                    case '1-year asset outages: >=5':
                    case '1-year circuit outages: >=10':
                    case '1-year asset CI: >2500':
                    case '1-year circuit CI: >10k':
                    case '1-year asset CHI: >1000':
                    case '1-year circuit CHI: >2500':
                    case '3-year asset outages: >=5':
                    case '3-year circuit outages: >=10':
                    case '3-year asset CI: >2500':
                    case '3-year circuit CI: >10k':
                    case '3-year asset CHI: >1000':
                    case '3-year circuit CHI: >2500':
                    case '5-year asset outages: >=5':
                    case '5-year circuit outages: >=10':
                    case '5-year asset CI: >2500':
                    case '5-year circuit CI: >10k':
                    case '5-year asset CHI: >1000':
                    case '5-year circuit CHI: >2500':
                    case 'Very Poor':
                    case 'High Risk':
                    case 'HI5':
                        return 'CATEGORY_5';

                    // Category 4
                    case 'FP [10%, 20%)':
                    case 'Risk [$200k, $1M)':
                    case 'Ratio [1.0, 2.0)':
                    case '1-year asset outages: [3, 4]':
                    case '1-year circuit outages: [6, 9]':
                    case '1-year asset CI: (500, 2500]':
                    case '1-year circuit CI: (1000, 10k]':
                    case '1-year asset CHI: (200, 1000]':
                    case '1-year circuit CHI: (500, 2500]':
                    case '3-year asset outages: [3, 4]':
                    case '3-year circuit outages: [6, 9]':
                    case '3-year asset CI: (500, 2500]':
                    case '3-year circuit CI: (1000, 10k]':
                    case '3-year asset CHI: (200, 1000]':
                    case '3-year circuit CHI: (500, 2500]':
                    case '5-year asset outages: [3, 4]':
                    case '5-year circuit outages: [6, 9]':
                    case '5-year asset CI: (500, 2500]':
                    case '5-year circuit CI: (1000, 10k]':
                    case '5-year asset CHI: (200, 1000]':
                    case '5-year circuit CHI: (500, 2500]':
                    case 'Poor':
                    case 'HI4':
                        return 'CATEGORY_4';

                    // Category 3
                    case 'FP [5%, 10%)':
                    case 'Risk [$50k, $200k)':
                    case 'Ratio [0.25, 1.0)':
                    case '1-year asset outages: 2':
                    case '1-year circuit outages: [3, 5]':
                    case '1-year asset CI: (100, 500]':
                    case '1-year circuit CI: (100, 1000]':
                    case '1-year asset CHI: (50, 200]':
                    case '1-year circuit CHI: (100, 500]':
                    case '3-year asset outages: 2':
                    case '3-year circuit outages: [3, 5]':
                    case '3-year asset CI: (100, 500]':
                    case '3-year circuit CI: (100, 1000]':
                    case '3-year asset CHI: (50, 200]':
                    case '3-year circuit CHI: (100, 500]':
                    case '5-year asset outages: 2':
                    case '5-year circuit outages: [3, 5]':
                    case '5-year asset CI: (100, 500]':
                    case '5-year circuit CI: (100, 1000]':
                    case '5-year asset CHI: (50, 200]':
                    case '5-year circuit CHI: (100, 500]':
                    case 'Fair':
                    case 'Moderate Risk':
                    case 'HI3':
                        return 'CATEGORY_3';

                    // Category 2
                    case 'FP [2%, 5%)':
                    case 'Risk [$20k, $50k)':
                    case 'Ratio [0.10, 0.25)':
                    case '1-year asset outages: 1':
                    case '1-year circuit outages: [1, 2]':
                    case '1-year asset CI: (0, 100]':
                    case '1-year circuit CI: (0, 100]':
                    case '1-year asset CHI: (0, 50]':
                    case '1-year circuit CHI: (0, 100]':
                    case '3-year asset outages: 1':
                    case '3-year circuit outages: [1, 2]':
                    case '3-year asset CI: (0, 100]':
                    case '3-year circuit CI: (0, 100]':
                    case '3-year asset CHI: (0, 50]':
                    case '3-year circuit CHI: (0, 100]':
                    case '5-year asset outages: 1':
                    case '5-year circuit outages: [1, 2]':
                    case '5-year asset CI: (0, 100]':
                    case '5-year circuit CI: (0, 100]':
                    case '5-year asset CHI: (0, 50]':
                    case '5-year circuit CHI: (0, 100]':
                    case 'Good':
                    case 'HI2':
                        return 'CATEGORY_2';

                    // Category 1
                    case 'FP <2%':
                    case 'Risk <$20k':
                    case 'Ratio <0.10':
                    case '1-year asset outages: 0':
                    case '1-year circuit outages: 0':
                    case '1-year asset CI: 0':
                    case '1-year circuit CI: 0':
                    case '1-year asset CHI: 0':
                    case '1-year circuit CHI: 0':
                    case '3-year asset outages: 0':
                    case '3-year circuit outages: 0':
                    case '3-year asset CI: 0':
                    case '3-year circuit CI: 0':
                    case '3-year asset CHI: 0':
                    case '3-year circuit CHI: 0':
                    case '5-year asset outages: 0':
                    case '5-year circuit outages: 0':
                    case '5-year asset CI: 0':
                    case '5-year circuit CI: 0':
                    case '5-year asset CHI: 0':
                    case '5-year circuit CHI: 0':
                    case 'Very Good':
                    case 'Low Risk':
                    case 'HI1':
                        return 'CATEGORY_1';
                }
                return rawData;
            };
        } else if (valueType === ValueType.NUMERIC) {
            return function (rawData) {
                const val = Number(rawData);
                const stepSize = (dataDetails.max - dataDetails.min) / 5;

                if (val < dataDetails.min + stepSize) {
                    return 'NUMERIC_1';
                } else if (val >= dataDetails.min + stepSize && val < dataDetails.min + 2 * stepSize) {
                    return 'NUMERIC_2';
                } else if (val >= dataDetails.min + 2 * stepSize && val < dataDetails.min + 3 * stepSize) {
                    return 'NUMERIC_3';
                } else if (val >= dataDetails.min + 3 * stepSize && val < dataDetails.min + 4 * stepSize) {
                    return 'NUMERIC_4';
                } else {
                    return 'NUMERIC_5';
                }
            };
        } else {
            return function (rawData) {
                return 'NUMERIC_1';
            };
        }
    }

    // Data prepare services
    public prepareEnginData(
        data: SpatialEntityData[],
        metric: Metric,
        dataDetails: DataDetails,
        tooltipType: GeospatialTooltipType = GeospatialTooltipType.NONE,
    ): SpatialEntityDataExtended[] {
        const mapColourParser = this.getDisplayCategoryParser(metric?.valueType, dataDetails);

        return this.enginDataPrepareService.prepare(data, mapColourParser, tooltipType);
    }

    public prepareProjectData(data: Project[], currentUserId: string): ProjectLayerData[] {
        return data
            .filter((p: Project) => p.shapeGeoJson != null)
            .map((p: any) => {
                return {
                    ...p,
                    // Spatial data
                    projectOwnedByCurrentUser: p.ownerId === currentUserId,
                    geoJsonGeometry: p.geoJsonGeometry ? p.geoJsonGeometry : JSON.parse(p.shapeGeoJson),
                };
            });
    }

    public prepareAdditionalMapData(
        data: RoadMoratoriumData[] | DistrictData[] | CustomerDemographicData[],
    ): RoadMoratoriumData[] | DistrictData[] | CustomerDemographicData[] {
        return data.map((entity: any) => {
            return {
                ...entity,
                // Spatial data
                geoJsonGeometry: JSON.parse(entity.geoJsonGeometryStr),
            };
        });
    }
}
