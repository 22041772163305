import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    FormCellType,
    FormField,
    FormViewModeType,
    TextFieldResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {NbTrigger} from '@nebular/theme';

@Component({
    selector: 'ngx-form-field-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends FormFieldBaseComponent<TextFieldResultDto> implements OnInit {
    @ViewChild('textarea') textarea!: ElementRef;
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    FormMode = FormMode;
    public NbTrigger = NbTrigger;
    public maxLength: number = 1000;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.genericInit();

        timer(100).subscribe(() => {
            if (this.textarea) this.adjustHeight();
        });

        if (this.fieldForm) {
            const currentValidators = this.fieldForm.validator ? [this.fieldForm.validator] : [];
            this.fieldForm.setValidators([...currentValidators, Validators.maxLength(this.maxLength)]);
            this.fieldForm.updateValueAndValidity({emitEvent: false});
        }
    }

    public getValueOrDefault(defaultValue: string): string | number {
        return this.currentValue ? this.currentValue.value : defaultValue;
    }

    public adjustHeight(): void {
        const textarea = this.textarea.nativeElement;
        textarea.style.height = '2.4375rem';
        const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const scrollHeightInRem = textarea.scrollHeight / baseFontSize;
        textarea.style.height = `${scrollHeightInRem}rem`;
    }

    /*
     * Implement abstract methods
     */
    validate(value: TextFieldResultDto): boolean {
        if (this.required && (value.value == null || value.value.trim() == '')) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: any): TextFieldResultDto {
        return {
            ...this.currentValue,
            value: item,
        };
    }

    getFormValue(): any {
        return this.result ? this.result.value : '';
    }

    onValidationCheck(): void {
        if (!this.fieldForm?.value && this.required) {
            this.fieldForm?.markAsTouched();
        }
        this.cd.detectChanges();
    }
}
