import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FilterState} from '@theme/components';
import {GenericPopoutFilter, GenericPopoutSettings} from '@core/interfaces/common/popout';
import {Filter, FilterFieldType, FilterOperatorType} from '@core/interfaces/system/system-common';
import {debounceTime, filter, map, share, shareReplay, startWith, takeUntil, tap} from 'rxjs/operators';

export interface FiltersFullState {
    [fieldKey: string]: FilterState;
}

export abstract class GenericFilterAccordion extends Unsubscribable {
    protected changeSubscription: Subscription;
    protected searchSubscription: Subscription;

    protected formGroupValue = new BehaviorSubject(null);

    readonly currentFilters$: Observable<Filter[]> = this.formGroupValue.asObservable().pipe(
        filter((value) => value !== null),
        map((value) => {
            return this.mapFormGroupValue(value);
        }),
    );

    get currentFilters(): Filter[] {
        const _formGroupValue = this.formGroupValue.value;
        if (_formGroupValue) return this.mapFormGroupValue(_formGroupValue);
        return [];
    }

    public filtersFormGroupChanged$ = new BehaviorSubject<boolean>(false);

    protected formGroup = this.fb.group({});

    protected filterSettings = new BehaviorSubject<GenericPopoutSettings>(null);
    readonly filterSettings$: Observable<GenericPopoutSettings> = this.filterSettings.asObservable().pipe(
        filter((settings) => settings != null),
        share(),
        tap((settings) => {
            this.changeSubscription && this.changeSubscription.unsubscribe();
            this.searchSubscription && this.searchSubscription.unsubscribe();
            this.initGeospatialFilters(settings);
        }),
        shareReplay(1),
    );

    public refresh = new BehaviorSubject<boolean>(false);
    readonly refresh$: Observable<boolean> = this.refresh.asObservable();

    private savedFiltersFullState = new BehaviorSubject<FiltersFullState>({});

    public getFilterState(fieldKey: string): FilterState {
        return this.savedFiltersFullState.value[fieldKey];
    }

    constructor(public fb: FormBuilder) {
        super();
    }

    get filtersFormGroupControls(): any {
        return this.filtersFormGroup?.controls;
    }

    get filtersFormGroup(): FormGroup {
        return this.formGroup.get('filters') as FormGroup;
    }

    public getActiveFilterSettings() {
        return this.filterSettings.value;
    }

    public updateFilterSettings(settings: GenericPopoutSettings, settingName?: string) {
        let resultSettings: GenericPopoutSettings = JSON.parse(JSON.stringify(settings));
        if (settingName) {
            resultSettings = {
                ...this.filterSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                    };
                }),
            };
        }
        this.filterSettings.next(resultSettings);
    }

    public updateFilterOptions(filter: any, searchString?: string, filters?: Filter[]): void {}

    get searchStringFormControl(): FormControl {
        return this.formGroup.get('searchString') as FormControl;
    }

    private subscribeOnChanges() {
        this.changeSubscription = this.filtersFormGroup.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                startWith(this.filtersFormGroup.value),
                tap((value) => {
                    if (this.formGroupValue.value === null) {
                        this.formGroupValue.next(JSON.parse(JSON.stringify(value)));
                    }
                }),
                filter((value) => {
                    return !this.isEquivalent(this.formGroupValue.value, value);
                }),
                debounceTime(500),
            )
            .subscribe((value) => {
                this.formGroupValue.next(JSON.parse(JSON.stringify(value)));
            });

        this.searchSubscription = this.searchStringFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((searchString) => this.search(searchString));
    }

    protected mapFormGroupValue(popoutValue: any): Filter[] {
        // Filters
        const filterList: Filter[] = [];

        this.filterSettings.value.filters.forEach((item: GenericPopoutFilter) => {
            let options: string[] = [];

            if (item.fieldType === FilterFieldType.STRING) {
                popoutValue[item.fieldKey]?.forEach((option) => {
                    options.push(option.key);
                });

                if (item.simple) {
                    if (options.length > 0 && item.options.length !== options.length)
                        filterList.push({
                            fieldKey: item.fieldKey,
                            fieldType: item.fieldType,
                            operator: item.operator,
                            values: options,
                        });
                } else {
                    // Do not add filters if there are no selected values
                    if (options.length > 0) {
                        let _newItem: Filter = {
                            fieldKey: item.fieldKey,
                            fieldType: item.fieldType,
                            operator: item.operator,
                        };

                        if (this.getFilterState(item.fieldKey)?.isAllSelected) {
                            _newItem.value = this.getFilterState(item.fieldKey)?.searchString;
                            _newItem.operator = FilterOperatorType.CONTAIN;
                        } else _newItem.values = options;

                        filterList.push(_newItem);
                    }
                }
            } else if (item.fieldType === FilterFieldType.DATE_RANGE) {
                let _value = popoutValue.filters[item.fieldKey],
                    min = _value?.start,
                    max = _value?.end;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                let _value = popoutValue[item.fieldKey],
                    min = _value?.min,
                    max = _value?.max;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            }
        });

        return filterList;
    }

    public destroy() {
        this.changeSubscription && this.changeSubscription.unsubscribe();
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.filtersFormGroup?.reset();
        this.searchStringFormControl?.reset();
    }

    private initGeospatialFilters(settings: GenericPopoutSettings): void {
        this.initFilters(settings.filters);
        this.resetFiltersFullState(settings.filters);
        this.setSearchStringFormControl(this.fb.control(''));
        this.subscribeOnChanges();
    }

    private initFilters(initialFilters: GenericPopoutFilter[]): void {
        const filters = this.fb.group({});
        initialFilters.forEach((item) => {
            if (item.fieldType === FilterFieldType.STRING) {
                filters.setControl(item.fieldKey, this.fb.control([]));
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                filters.setControl(item.fieldKey, this.fb.control({}));
            }
        });

        this.setFiltersFormGroup(filters);
    }

    public resetGeospatialFilters() {
        this.filtersFormGroup?.reset();
        this.searchStringFormControl?.reset();
    }

    private setFiltersFormGroup(group: FormGroup) {
        if (this.filtersFormGroup) {
            this.filtersFormGroup.reset(group.value);
            Object.keys(group.value).forEach((prop) => {
                this.filtersFormGroupControls[prop].enable();
            });
        } else {
            this.formGroup.setControl('filters', group);
            this.filtersFormGroupChanged$.next(true);
        }
    }

    private setSearchStringFormControl(search: FormControl) {
        if (this.searchStringFormControl) {
            this.searchStringFormControl.reset(search.value);
        } else {
            this.formGroup.setControl('searchString', search);
        }
    }

    private search(searchString: string) {
        const filters = {};
        this.getActiveFilterSettings().filters.forEach((filter) => {
            if (this.filtersFormGroupControls[filter.fieldKey].value && filter.fieldType === FilterFieldType.STRING) {
                filters[filter.fieldKey] = this.filtersFormGroupControls[filter.fieldKey].value.map((item) => {
                    return {
                        ...item,
                        enabled: `${item.key}${item.name}`.toLowerCase().includes(searchString.toLowerCase()),
                    };
                });
            }
        });

        this.filtersFormGroup.patchValue(filters, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    public isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }

    public resetFiltersFullState(initialFilters: GenericPopoutFilter[]) {
        const newFiltersState: FiltersFullState = {};
        initialFilters.forEach((item) => {
            if (item.fieldType === FilterFieldType.STRING) {
                newFiltersState[item.fieldKey] = {
                    isAllSelected: false,
                    searchString: '',
                    selectedOptions: [],
                };
            }
        });
        this.savedFiltersFullState.next(newFiltersState);
    }

    public updateFiltersFullState(fieldKey: string, state: FilterState) {
        const newFiltersState: FiltersFullState = this.savedFiltersFullState.value;
        newFiltersState[fieldKey] = state;
        this.savedFiltersFullState.next(newFiltersState);
    }
}
