<nb-card class="edit-dialog form-scope-modal">
    <nb-card-header>
        <div>
            <p class="primary-header">Scope</p>
            <p class="sub-header">Filter all the assets that will be inspected with this form</p>
        </div>

        <button (click)="close()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div class="generic-filter-container" [nbSpinner]="loading$ | async">
            <p class="number-text">Number of assets: {{ assetCount$ | async }}</p>
            <div *ngIf="(genericFilters$ | async)?.length && (preselectedFilters$ | async)">
                <ngx-generic-filter
                    (updateOptions)="getOptionsDynamically($event)"
                    (valueChange)="addFilter($event)"
                    [filterFields]="genericFilters$ | async"
                    [filtersDynamicOptions]="filtersDynamicOptions$ | async"
                    [selectedFilterFields]="preselectedFilters$ | async"
                ></ngx-generic-filter>
            </div>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="basic" (click)="close()">
            <nb-icon icon="close-circle-outline"></nb-icon>
            Cancel
        </button>
        <button
            nbButton
            status="primary"
            class="ml-3"
            (click)="saveFormFilters()"
            [disabled]="!(filterParams$ | async).length"
        >
            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
            Save
        </button>
    </nb-card-footer>
</nb-card>
