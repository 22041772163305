<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>
    <div [ngClass]="{'view-mode': pageMode === FormMode.VIEW, 'edit-mode': pageMode === FormMode.EDIT}">
        <!-- Main content -->
        <ng-container *ngIf="cellType === FormCellType.PREVIOUS; else currentField">
            <ng-container *ngIf="emptyOptions()">
                <p class="margin-rem">-</p>
            </ng-container>

            <nb-button-group multiple class="flex-column" *ngIf="!emptyOptions()">
                {{ getPreviousValue() }}
            </nb-button-group>
        </ng-container>

        <ng-template #currentField [ngSwitch]="viewMode">
            <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
                <div class="flex-column">
                    <nb-button-group multiple class="btn-group-container">
                        <button
                            class="button-style mb-1"
                            (click)="selectedOption(option)"
                            *ngFor="let option of this.options"
                            nbButtonToggle
                            [pressed]="option.selected"
                            [ngClass]="option.selected ? 'selected-btn' : 'unselected-btn'"
                            size="small"
                        >
                            {{ option.label }}
                        </button>
                    </nb-button-group>
                    <ngx-validation-message
                        label="Value"
                        [showRequired]="fieldForm?.touched && fieldForm?.hasError('required')"
                    ></ngx-validation-message>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
                <div class="flex-column">
                    <nb-button-group multiple class="btn-group-container">
                        <button
                            class="button-style disabled-btn mb-1"
                            *ngFor="let option of this.options"
                            nbButtonToggle
                            [pressed]="option.selected"
                            [ngClass]="option.selected ? 'selected-btn' : 'unselected-btn'"
                            size="small"
                        >
                            {{ option.label }}
                        </button>
                    </nb-button-group>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
                <div class="flex-column">
                    <nb-button-group multiple class="btn-group-container">
                        <button
                            class="button-style disabled-btn mb-1"
                            *ngFor="let option of this.options"
                            nbButtonToggle
                            size="small"
                        >
                            {{ option.label }}
                        </button>
                    </nb-button-group>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>
