// Configuration
export class VisualizationItem {
    value: VisualizationType;
    disabled?: boolean = true;
    children?: Array<VisualizationItem> = [];
}

// Other DTOs

// Enumerations
export enum VisualizationType {
    INDIVIDUAL = 'INDIVIDUAL',
    LINE_CIRCUIT = 'LINE_CIRCUIT',
    LINE_PRID = 'LINE_PRID',
    LINE_IRID = 'LINE_IRID',
    LINE_TRANSMISSION = 'LINE_TRANSMISSION',

    INDIVIDUAL_CONTOURS = 'INDIVIDUAL_CONTOURS',
    BLENDED = 'BLENDED',
    BLENDED_PRID = 'BLENDED_PRID',
    BLENDED_IRID = 'BLENDED_IRID',
    BLENDED_SECTION = 'BLENDED_SECTION',
    HEATMAP = 'HEATMAP',
    POLYGON = 'POLYGON',
    POLYGON_PROJECT = 'POLYGON_PROJECT',
}

// Labels
export const VisualizationTypeLabel = {
    [VisualizationType.INDIVIDUAL]: 'Individual',
    [VisualizationType.LINE_CIRCUIT]: 'Line Circuit',
    [VisualizationType.LINE_PRID]: 'Line PRID',
    [VisualizationType.LINE_IRID]: 'Line IRID',
    [VisualizationType.LINE_TRANSMISSION]: 'Transmission Line',

    [VisualizationType.INDIVIDUAL_CONTOURS]: 'Indiv. w/ Contours',
    [VisualizationType.BLENDED]: 'Blended',
    [VisualizationType.BLENDED_PRID]: 'Blended - PRID',
    [VisualizationType.BLENDED_IRID]: 'Blended - IRID',
    [VisualizationType.BLENDED_SECTION]: 'Blended - Sections',
    [VisualizationType.HEATMAP]: 'Heatmap',
    [VisualizationType.POLYGON]: 'Polygon',
    [VisualizationType.POLYGON_PROJECT]: 'Project Polygons',
};
export enum VisualizationColorCode {
    OWNERSHIP = 'OWNERSHIP',
    STATUS = 'STATUS',
}
