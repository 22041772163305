import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {PasswordData, User, UsersService} from '@core/interfaces/common/users';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';
import {filter, map, switchMap} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersStore} from '@store/common/users.store';

/**
 * Update password component
 * - Requires auth (must be logged in)
 * - User enters current, new, confirm password to reset password before entering main system. e.g. password expired.
 */
@Component({
    selector: 'ngx-auth-update-password',
    templateUrl: './update-password.component.html',
})
export class UpdatePasswordComponent extends Unsubscribable implements OnInit {
    // Info messages
    showingMessage: any[] = [];

    submitted: boolean = false;
    user: User;

    // Success / fail messages from API
    showMessages: any = {
        success: true,
        error: true,
    };
    errors: string[] = [];
    messages: string[] = [];

    // Current user
    user$ = this.usersStore.currentUser$;
    // Password data
    private passwordValidation = new BehaviorSubject<APIResponse<PasswordRequirements>>(null);
    readonly passwordValidation$: Observable<any> = this.passwordValidation.asObservable().pipe(
        filter((item) => item !== null),
        map((config) => {
            return config.response;
        }),
    );
    // Password reset form
    formReady = new BehaviorSubject<boolean>(false);
    resetPasswordForm: FormGroup = this.fb.group({
        currentPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
    });

    constructor(
        private usersService: UsersService,
        private usersStore: UsersStore,
        private fb: FormBuilder,
        protected cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        // todo: may be admin increased password requirements, can add different message in the future.
        this.showingMessage.push({message: 'Your password has expired, please, change it.', type: 'info'});

        this.checkValidation('');

        combineLatest<Observable<APIResponse<PasswordRequirements>>, Observable<any>>([
            this.passwordValidation,
            this.resetPasswordForm.statusChanges,
        ])
            .pipe(
                filter(([requirements, _]: [APIResponse<PasswordRequirements>, any]) => requirements !== null),
                switchMap(
                    ([requirements, formValid]: [APIResponse<PasswordRequirements>, any]): Observable<boolean> => {
                        return of(requirements.response.validation && formValid === 'VALID');
                    },
                ),
            )
            .subscribe((isReady: boolean) => {
                this.formReady.next(isReady);
            });
    }

    public onInputChanges(event: any) {
        this.checkValidation(event.password);
        this.cd.markForCheck();
    }

    private checkValidation(newValue) {
        this.usersService.passwordValidation(newValue, true).subscribe((res) => {
            this.passwordValidation.next(res);
        });
    }

    public submit() {
        this.errors = this.messages = [];
        this.submitted = true;

        let data = this.resetPasswordForm.value as PasswordData;

        this.usersService
            .updatePassword(data.currentPassword, data.newPassword, data.confirmPassword)
            .subscribe((resp) => {
                if (resp && resp.success) {
                    this.messages = resp.messages;
                } else {
                    this.errors = resp.errors;
                    this.submitted = false; // allow re-submit
                }
                this.cd.detectChanges();
            });
    }
}
