import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';

@Component({
    template: `
        <input
            [ngClass]="inputClass"
            [formControl]="inputControl"
            placeholder="{{ column.title }}"
            class="form-control"
            ngxOnlyNumber
        />
    `,
})
export class NumberInputComponent extends DefaultFilter implements OnInit, OnChanges {
    constructor() {
        super();
    }

    inputControl: FormControl = new FormControl('', [Validators.pattern(/^[0-9]*$/)]);

    ngOnInit() {
        if (this.query) {
            this.inputControl.setValue(this.query);
        }
        this.inputControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.delay))
            .subscribe((value: string) => {
                if (this.inputControl.invalid) {
                    this.inputControl.setValue('', {emitEvent: false});
                    this.query = '';
                    this.setFilter();
                } else if (this.inputControl.status === 'VALID') {
                    this.query = value;
                    this.setFilter();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }
}
