import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {isEqual} from 'lodash';
import {
    Filter,
    FilterFieldOption,
    FilterFieldOptionRequest,
    FiltersDynamicOptionsStore,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {share, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

export interface updateOptionsOutput {
    dynamicSearch: boolean;
    data: FilterFieldOptionRequest;
}

@Component({
    selector: 'ngx-generic-filter',
    templateUrl: './generic-filter.component.html',
    styleUrls: ['./generic-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFilterComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() filterFields: TableFilterConfig[] = [];
    @Input() selectedFilterFields: Filter[] = [];
    @Input() filtersDynamicOptions?: FiltersDynamicOptionsStore;
    @Output() valueChange = new EventEmitter<Filter[]>();
    @Output() updateOptions = new EventEmitter<FilterFieldOptionRequest>();

    @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

    _filterOptions$: BehaviorSubject<{option: any; label: string}[]> = new BehaviorSubject(null);

    isExpanded = false;

    clearFilter: BehaviorSubject<string> = new BehaviorSubject(null);
    clearFilter$ = this.clearFilter.asObservable().pipe(share());

    filterControl = new FormControl([]);
    selectedScopes = {};

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.setPreSelectedFilterFields();

        this.filterControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filterFields?.currentValue) {
            let _options = this.filterFields.map((item: TableFilterConfig) => ({
                option: item,
                label: item.fieldName,
            }));
            this._filterOptions$.next(_options);
        }

        if (changes.selectedFilterFields?.currentValue) {
            if (this.filterControl.value?.length) this.resetFilters();
            this.clearFilter.next(null);
            this.setPreSelectedFilterFields();
        }
    }

    setPreSelectedFilterFields() {
        this.selectedFilterFields.forEach((item) => (this.selectedScopes[item.fieldKey] = item));

        let _selectedFields: TableFilterConfig[] = this.selectedFilterFields.map((item: Filter) =>
            this.filterFields.find((filter: TableFilterConfig) => filter.fieldKey === item.fieldKey),
        );

        this.filterControl.setValue(_selectedFields, {emitEvent: false});
    }

    resetFilters(): void {
        this.filterControl.value?.forEach((item: TableFilterConfig) => this.unselectFilter(item.fieldKey));
        this.filterControl.reset([]);
    }

    unselectFilter(fieldKey: string): void {
        this.clearFilter.next(fieldKey);
    }

    getSelectedValue(fieldKey: string): Filter {
        return this.selectedFilterFields.find((item: Filter) => item.fieldKey === fieldKey);
    }

    onValueChange(changedField: Filter) {
        let _field: Filter = this.selectedScopes[changedField.fieldKey];

        if (_field && (changedField.value || changedField.values?.length)) {
            if (!(changedField.value === _field.value && changedField.values === _field.values)) {
                this.selectedScopes[changedField.fieldKey] = changedField;
                this.valueChange.emit(Object.values(this.selectedScopes));
            }
        } else if (!_field && (changedField.value || changedField.values?.length)) {
            this.selectedScopes[changedField.fieldKey] = changedField;
            this.valueChange.emit(Object.values(this.selectedScopes));
        } else {
            delete this.selectedScopes[changedField.fieldKey];
            this.valueChange.emit(Object.values(this.selectedScopes));
        }
    }

    onUpdateOptions(output: updateOptionsOutput) {
        let _selected = Object.values(this.selectedScopes).map((filter: Filter) => ({
            ...filter,
            values: filter.values.map((option: FilterFieldOption) => option.key || option),
        }));

        if (
            this.filtersDynamicOptions[output.data.fieldKey] &&
            !output.dynamicSearch &&
            isEqual(_selected, this.filtersDynamicOptions[output.data.fieldKey].scope)
        )
            return;

        this.updateOptions.emit({
            ...output.data,
            filterParams: _selected,
        });
    }

    unselectOption({option, selected}: {option: TableFilterConfig; selected: boolean}): void {
        if (!selected) this.unselectFilter(option.fieldKey);
    }

    toggleExpanded(): void {
        this.isExpanded = !this.isExpanded;
        this.scrollContainer.nativeElement.scrollTop = 0;
    }
}
