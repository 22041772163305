import Field from '@arcgis/core/layers/support/Field';

export class AssetFieldsService {
    public getMapFields(): Field[] {
        return this._fields.map((e) => new Field(e));
    }

    // TODO: these fields need to be renamed to be more general, less specific
    private _fields: any[] = [
        // Unique identifiers
        {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
        },
        {
            name: 'id',
            alias: 'id',
            type: 'string',
        },
        {
            name: 'classCode',
            alias: 'classCode',
            type: 'string',
        },
        {
            name: 'tooltipType',
            alias: 'tooltipType',
            type: 'string',
        },
        // Data
        {
            name: 'displayCategory',
            alias: 'displayCategory',
            type: 'string',
        },
        {
            name: 'data',
            alias: 'data',
            type: 'string',
        },
        {
            name: 'count',
            alias: 'count',
            type: 'integer',
        },
    ];
}
