import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-input-with-search-box-form',
    templateUrl: './input-with-search-box-form.component.html',
    styleUrls: ['./input-with-search-box-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputWithSearchBoxFormComponent),
            multi: true,
        },
    ],
})
//todo: currently only support multiple selection.
export class InputWithSearchBoxFormComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() options: {value: string; count: number}[] | string[] = [];
    @Input() selectLabelLength: number = 0;
    @Input() showCount: boolean = false;
    @Input() formValueControl: FormControl;
    @Input() nameFieldSearchControl: FormControl;

    constructor() {
        super();
    }

    filteredOptions$: BehaviorSubject<{value: string; count: number}[] | string[]> = new BehaviorSubject<
        {value: string; count: number}[] | string[]
    >([]);
    selectLabel: string = '';
    customSelectLabel: boolean = false;
    @ViewChild('filterInput') filterInput: ElementRef;

    ngOnInit(): void {
        this.customSelectLabel = this.selectLabelLength > 0;
        if (this.customSelectLabel) {
            this.selectLabel = this.getSelectedLabel(this.formValueControl.value, this.selectLabelLength);
        }
        // Update filtered options based on search input
        this.formValueControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((value) => {
                    let isSearch = value?.includes('');
                    if (isSearch) {
                        let _value = value.filter((item) => item !== '');
                        this.formValueControl.setValue(_value, {emitEvent: false});
                    }

                    return !isSearch;
                }),
                map((value: any[]) => {
                    this.selectLabel = this.getSelectedLabel(value, this.selectLabelLength);
                    return value;
                }),
                debounceTime(500),
            )
            .subscribe();
    }

    clearFilter(): void {
        this.nameFieldSearchControl.reset();
        if (this.showCount) {
            this.filteredOptions$.next(this.options.map((item) => item.value));
        } else {
            this.filteredOptions$.next(this.options);
        }
    }

    getShouldShowOption(option) {
        let search = this.nameFieldSearchControl.value?.toLowerCase();
        if (!search) return true;
        search = search.toLowerCase();
        this.filterInput.nativeElement.focus();
        return option.toLowerCase().indexOf(search) > -1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.showCount) {
            this.filteredOptions$.next(this.options.map((item) => item.value));
        } else {
            this.filteredOptions$.next(this.options);
        }
    }

    getSelectedLabel(data: string[], maxLength: number = 15): string {
        if (!data || data.length === 0) return '';

        let text = data.join(', ');
        if (text.length > maxLength) {
            text = text.slice(0, maxLength) + '...';
        }

        return text;
    }

    getCount(option) {
        if (this.showCount) {
            const options = this.options as {value: string; count: number}[];
            return options.find((item) => item.value === option).count;
        }
    }
}
