import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChartDownloadData, ECHARTS_TYPE} from './chart-data-download-service';
import {BehaviorSubject} from 'rxjs';
import {GraphLabel, GraphLabelOption} from '@core/interfaces/common/pages';
import {filter, takeUntil} from 'rxjs/operators';
import {TenantUnits} from '@core/interfaces/common/configuration';
import {ConfigurationStore} from '../../../@store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ECharts} from 'echarts';

@Component({
    selector: 'ngx-chart-settings',
    templateUrl: './chart-settings.component.html',
    styleUrls: ['./chart-settings.component.scss'],
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ChartSettingsComponent), multi: true}],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Combined chart settings component including:
 * - Unit selection
 * - Data download (CSV)
 * Unit selection is disabled by default; enabled if [formControlName] is provided to this component
 * Data download is enabled by default; disabled if either [disableDataDownload] is true, or if no data is provided
 */
export class ChartSettingsComponent extends Unsubscribable implements ControlValueAccessor, OnChanges, OnInit {
    // Unit selector data
    @Input() unitOptions: string[];
    @Input() labelOptions: BehaviorSubject<GraphLabel[]>;
    @Input() graphId: string; // used for indexing labelOptions
    /*
     * Data download component is enabled by default, and expects chartOptions and chartType data sources
     * - Optional input to disable download button
     * - Optional input of custom CSV file name
     * - Optional input of custom data object
     */
    @Input() disableDataDownload?: boolean;
    @Input() csvFileName?: string;
    @Input() chartOptions?: any;
    @Input() chartType?: ECHARTS_TYPE;
    @Input() preparedDownloadData?: ChartDownloadData;

    @Input() chartInstance?: ECharts;

    // Input function used for changing axis (if not provided, axis selection is disabled
    @Input() axisChangeFunction: Function;
    @Input() axisOptions: GraphLabelOption[];

    private defaultUnitOptions: string[] = ['count', 'currency'];
    public fullUnitOptions: {value: string; label: string}[];

    propagateChange: any = () => {};

    constructor(private cd: ChangeDetectorRef, private configurationStore: ConfigurationStore) {
        super();
    }

    ngOnInit() {
        this.configurationStore.units$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((d) => d && !!d),
            )
            .subscribe((units: TenantUnits) => {
                const finalUnitOptions: string[] = this.unitOptions ? this.unitOptions : this.defaultUnitOptions;
                this.fullUnitOptions = finalUnitOptions.map((o) => {
                    return units.graphUnits.filter((g) => g.value === o)[0];
                });
            });
    }

    private _unitItem: any;

    get unitItem(): any {
        return this._unitItem;
    }

    set unitItem(val: any) {
        this._unitItem = val;
    }

    writeValue(value: any): void {
        if (value) {
            this.unitItem = value;
        }
    }

    chartTypeValid() {
        return !isNaN(this.chartType);
    }
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.propagateChange(this.unitItem);
    }

    registerOnTouched() {}

    changed(value) {
        this.unitItem = {...this.unitItem, unit: value};
        this.propagateChange(this.unitItem);
        this.cd.markForCheck();
    }
}
