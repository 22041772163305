/* eslint-disable object-curly-spacing */
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ViewCell} from '@mominsamir/ngx-smart-table';

@Component({
    selector: 'ngx-document-view-action-cell',
    template: `
        <button nbButton (click)="onInfoClicked()">
            <nb-icon icon="arrow-forward-outline"></nb-icon>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentViewCellComponent implements ViewCell {
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() onInfoClickedEvent: EventEmitter<any> = new EventEmitter();

    onInfoClicked() {
        this.onInfoClickedEvent.emit(this.rowData);
    }

    constructor(private router: Router) {}
}
