import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {DefaultFilter, ViewCell} from '@mominsamir/ngx-smart-table';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-check-box-cell',
    template: `
        <div class="action-cell">
            <nb-checkbox
                [checked]="isChecked"
                [ngClass]="(loadedDataTableFlag.asObservable() | async) ? 'check-box' : 'disable-check-box'"
                (checkedChange)="onClick($event)"
            ></nb-checkbox>
        </div>
    `,
    styleUrls: ['./check-box-cell.component.scss'],
})
export class CheckBoxCellComponent<T> extends Unsubscribable implements ViewCell, OnInit {
    @Input() value: string | number;
    @Input() rowData: T;
    @Input() selectedRows$: Observable<T[]>;
    @Input() keyColumnName: string = 'id';

    @Input() multipleSelect: false;
    @Output() onSelectChange: EventEmitter<{selected: boolean; rowData: T}> = new EventEmitter();
    loadedDataTableFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

    isChecked: boolean = false;

    constructor(private cd: ChangeDetectorRef) {
        super();

        // If user select the checkbox during the reloading datatable time, it will be causing an error.
        setTimeout(() => {
            this.loadedDataTableFlag.next(true);
        }, 500);
    }

    public ngOnInit() {
        this.selectedRows$.pipe(takeUntil(this.unsubscribe$)).subscribe((rows: T[]) => {
            this.checkSelectedValues(this.rowData[this.keyColumnName], rows);
        });
    }

    checkSelectedValues(value, array) {
        this.isChecked = array.some((item) => {
            if (typeof value === 'object') return item[this.keyColumnName] === value.value;
            return item[this.keyColumnName] === value;
        });
        this.cd.markForCheck();
    }

    onClick(selected: boolean) {
        this.onSelectChange.emit({selected: selected, rowData: this.rowData});
    }
}

@Component({
    template: `
        <div class="action-cell">
            <nb-checkbox
                [indeterminate]="checkIfAllSelected()"
                [checked]="checkIfAllSelected()"
                [ngClass]="(loadedDataTableFlag.asObservable() | async) ? 'check-box' : 'disable-check-box'"
                (checkedChange)="toggleAllFeeders($event)"
            ></nb-checkbox>
        </div>
    `,
})
export class CheckBoxSelectAllComponent<T> extends DefaultFilter implements OnInit, OnChanges, OnDestroy {
    selectedRows$: Observable<T[]> = of([]);
    toggleAllFeeders = (selected: boolean) => {};
    checkIfAllSelected = () => false;

    loadedDataTableFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

    subscription: Subscription;

    constructor() {
        super();

        // If user select the checkbox during the reloading datatable time, it will be causing an error.
        setTimeout(() => {
            this.loadedDataTableFlag.next(true);
        }, 500);
    }

    ngOnInit(): void {
        this.toggleAllFeeders = this.column?.filter?.config?.toggleAllFeeders;
        this.checkIfAllSelected = this.column?.filter?.config?.checkIfAllSelected;
    }

    public ngOnChanges(changes: SimpleChanges) {
        // Don't delete, this method is needed for the library work
        let i = 1;
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.subscription?.unsubscribe();
    }
}
