import {MeasureType, MetricType} from './metric';
import {VisualizationType} from './visualization';
import {SimpleUnit} from '@core/interfaces/system/system-common';

/*
 * Request
 */
export interface GeospatialTooltipRequest {
    tooltipType: GeospatialTooltipType;
    entityId: string;
    visualizationType?: VisualizationType;
    metrics?: GeospatialMeasureMetric[];
}

export interface GeospatialMeasureMetric {
    measureType: MeasureType;
    metricType: MetricType;
}

/*
 * Response
 */
export interface GeospatialTooltipResponse {
    tooltipType: GeospatialTooltipType;
    entityDetails: GeospatialEntityDetails;
    title: GeospatialTooltipTitle;
    sections: GeospatialTooltipSection[];
    centerCoordinate: CoordinateLatLong;
}

export interface GeospatialEntityDetails {
    entityId: string;
}

export interface GeospatialTooltipTitle {
    label: string;
    iconType: GeospatialIconType;
}

export interface CoordinateLatLong {
    latitude: number;
    longitude: number;
}

export interface GeospatialTooltipSection {
    order: number;
    label: string;
    fields: GeospatialTooltipField[];
}

export interface GeospatialTooltipField {
    label: string;
    value: string;
    dataType: FieldDataType;
    unit?: SimpleUnit;
    colourType?: GeospatialColourType;
}

/*
 * Enums
 */
export enum GeospatialTooltipType {
    // Geospatial viewer: metric, project, system layers
    METRIC_ASSET = 'METRIC_ASSET',
    METRIC_GROUP = 'METRIC_GROUP',
    PROJECT = 'PROJECT',
    SYSTEM_ENTITY = 'SYSTEM_ENTITY',
    // Asset Registry
    REGISTRY_ASSET = 'REGISTRY_ASSET',
    REGISTRY_GROUP = 'REGISTRY_GROUP',
    NONE = 'NONE',
}

export enum GeospatialIconType {
    POINT = 'POINT',
    LINE = 'LINE',
    POLYGON = 'POLYGON',
    NONE = 'NONE',
}

export enum GeospatialColourType {
    METRIC_COLOUR1 = 'METRIC_COLOUR1',
    METRIC_COLOUR2 = 'METRIC_COLOUR2',
    METRIC_COLOUR3 = 'METRIC_COLOUR3',
    METRIC_COLOUR4 = 'METRIC_COLOUR4',
    METRIC_COLOUR5 = 'METRIC_COLOUR5',
    METRIC_ALT_COLOUR1 = 'METRIC_ALT_COLOUR1',
    METRIC_ALT_COLOUR2 = 'METRIC_ALT_COLOUR2',
    METRIC_ALT_COLOUR3 = 'METRIC_ALT_COLOUR3',
    METRIC_ALT_COLOUR4 = 'METRIC_ALT_COLOUR4',
    METRIC_ALT_COLOUR5 = 'METRIC_ALT_COLOUR5',
    GREY = 'GREY',
    NONE = 'NONE',
}

export enum FieldDataType {
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    DATE = 'DATE',
    OPTIONS = 'OPTIONS',
    BOOLEAN = 'BOOLEAN',
}
