import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';

/*
 * Response
 */
export interface PmSensitivityWorkflow {
    id: number;
    workflowItemId: number;
    sensitivityId: number;
    status: PmSensitivityStatusType;
}

/*
 * Request
 */
export interface PmSensitivityRequest {
    workflowItemId: number;
    userId: string;
    sensitivityFlag: boolean;
}

export interface PmSensitivityResetRequest extends PmSensitivityRequest {
    sensitivityId: number;
}

/*
 * Enums
 */
export enum PmSensitivityStatusType {
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
}

export const PmSensitivityStatusTypeLabel = {
    [PmSensitivityStatusType.READY]: 'Ready',
    [PmSensitivityStatusType.IN_PROGRESS]: 'In Progress',
    [PmSensitivityStatusType.FAILED]: 'Failed',
};

export abstract class PmSensitivityService {
    abstract findSensitivity(data: PmSensitivityRequest): Observable<APIResponse<PmSensitivityWorkflow>>;

    abstract resetSensitivity(data: PmSensitivityResetRequest): Observable<APIResponse<PmSensitivityWorkflow>>;

    abstract getOneSensitivityWorkflow(sensitivityWorkflowId: number): Observable<APIResponse<PmSensitivityWorkflow>>;
}

/*
export interface ProgramOutcomes {
    assetClassList: string[];
    investmentPriority: InvestmentPriority;
    outcomeMaintainType: OutcomeMaintainType;
    belowPercent: number;
    startYear: number;
}
export enum InvestmentPriority {
    ECONOMIC = 'ECONOMIC',
    HEALTH = 'HEALTH',
    CALENDAR_AGE = 'CALENDAR_AGE',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
}
export enum OutcomeMaintainType {
    PAST = 'PAST',
    APPROACHING = 'APPROACHING',
}

export enum SimpleUnitType {
    CURRENCY = 'CURRENCY',
    COUNT = 'COUNT',
    PERCENT = 'PERCENT',
    TEMPERATURE = 'TEMPERATURE',
    PRESSURE = 'PRESSURE',
    POWER = 'POWER',
    LENGTH = 'LENGTH',

    CONCENTRATION = 'CONCENTRATION',

    RATE = 'RATE',

    NONE = 'NONE',
}
export interface ProgramBudget {
    sensitivityId: number;
    startYear: number;
    endYear: number;
    budgetMetric: SimpleUnitType;
    value: number;
    scope: AssetScope[];
}
*/
