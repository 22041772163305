import {APIResponse, Filter, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';
import {InvestmentPriorityType} from '@core/interfaces/engin/program-management/program';
import {Graph} from '@core/interfaces/common/pages';

/*
 * Response
 */
export interface OutcomeChartResponse {
    constrained: ClusteredSeries[];
    unconstrained: ClusteredSeries[];
}

export interface ClusteredSeries {
    code: string;
    name: string;
    graphTitle: string;
    graphId: string;
    legend: string[];
    labels: string[];
    data: number[][];
    unit: Unit;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

export interface OutcomeKpiResponse {
    constrained: KpiDataResp[];
    unconstrained: KpiDataResp[];
}

export interface KpiDataResp {
    title: string;
    subtitle: string;
    unit: string;
    residualValue: number;
    delta: number;
}

/*
 * Request
 */
export interface PmDataRequest {
    workflowItemId: number;
    sensitivityId: number;
}

export interface InvestmentOverviewRequest {
    workflowItemId: number;
    sensitivityId: number;
    aggregateBy: 'SEGMENT' | 'PROGRAM' | 'PROJECT' | 'YEAR';
    filterList: Filter[];
}

export interface OutcomeChartRequest {
    sensitivityId: number;
    workflowItemId: number;
    graphList: Graph[];
    filters: Filter[];
}

/*
 * Enums
 */
export enum OutcomeMaintainType {
    PAST = 'PAST',
    APPROACHING = 'APPROACHING',
}

/*
 * DTOs
 */
export interface PmHeaderFilterData {
    program: [];
    segment: [];
    project: [];
}

export abstract class PmReportingService {
    /*
     * APIs for Investment Overview
     */
    abstract getInvestmentOverview(
        param: InvestmentOverviewRequest,
    ): Observable<APIResponse<SimpleMultiSeries<string>>>;

    /*
     * APIs for Outcomes (legacy Optimizer)
     */
    abstract getChartDataSummarySpending(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataSummaryOutcomes(request: OutcomeChartRequest): Observable<APIResponse<OutcomeKpiResponse>>;

    abstract getChartDataProactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataReactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataFailureForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataOutageForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataRiskForecastByImpact(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataRiskForecastByCategory(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>>;
}
