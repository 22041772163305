import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FilterFieldOption} from '@core/interfaces/system/system-common';
import {ReplaySubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-single-select-autocomplete',
    templateUrl: './single-select-autocomplete.component.html',
    styleUrls: ['./single-select-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleSelectAutocompleteComponent),
            multi: true,
        },
    ],
})
export class SingleSelectAutocompleteComponent
    extends Unsubscribable
    implements OnInit, OnChanges, ControlValueAccessor
{
    @Input() options: FilterFieldOption[] = [];
    @Input() placeholder: string = '';
    @Input() selectLabel?: string;
    @Input() optionsPanelClass: string = '';
    @Input() currentSearching?: string = null;
    @Input() withStopPropagation?: boolean = false;

    @Output() selectedChange = new EventEmitter<FilterFieldOption>();
    @Output() onSearch = new EventEmitter<string>();

    @ViewChild('filterInput') filterInput: ElementRef;

    selectControl: FormControl = new FormControl(null);
    filterControl: FormControl = new FormControl();
    filteredOptions$: ReplaySubject<FilterFieldOption[]> = new ReplaySubject(1);

    private onChange = (value: FilterFieldOption | null) => {};
    private onTouched = () => {};
    disabled = false;

    ngOnInit(): void {
        this.filterControlProcess();
        this.selectControlProcess();

        if (this.currentSearching) {
            this.filterControl.setValue(this.currentSearching, {emitEvent: false});
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options?.currentValue?.length) {
            this.filteredOptions$.next(this.options);
        }
    }

    private filterControlProcess(): void {
        this.filterControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe((value: string) => {
                this.filterOptions(value);
                this.filterInput.nativeElement.focus();
                this.onSearch.emit(value);
            });
    }

    private selectControlProcess(): void {
        this.selectControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe((value: FilterFieldOption | null) => {
                this.onChange(value);
                this.selectedChange.emit(value);
            });
    }

    private filterOptions(search: string): void {
        if (!search) {
            this.filteredOptions$.next(this.options);
            return;
        }

        const filtered = this.options.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()));

        this.filteredOptions$.next(filtered);
    }

    writeValue(value: FilterFieldOption | null): void {
        this.selectControl.setValue(value, {emitEvent: false});
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.selectControl.disable();
        } else {
            this.selectControl.enable();
        }
    }
}
