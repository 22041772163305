import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormCellType,
    FormField,
    FormViewModeType,
    TableColumn,
    TableColumnNumberResultDto,
    TableColumnTextResultDto,
    TableColumnType,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {S3Service} from '@core/interfaces/common/s3';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {ResultFieldTypes, TableFieldType} from '@theme/components';
import {filter, map, takeUntil} from 'rxjs/operators';

export enum CellType {
    COMMENT = 'COMMENT',
    REGULAR = 'REGULAR',
}

@Component({
    selector: 'ngx-form-field-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent
    extends FormFieldBaseComponent<TableColumnNumberResultDto[] | TableColumnTextResultDto[]>
    implements OnInit
{
    @ViewChild('textarea') textarea!: ElementRef;
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() s3service: S3Service;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    public FormMode = FormMode;
    tableForm: FormGroup = this.fb.group({});
    CellType = CellType;

    constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        if (this.viewMode !== FormViewModeType.VIEW_CONFIG) {
            this.createTableForms();
        }

        let previousValue = this.tableForm.value;
        this.tableForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            const changedKey = Object.keys(value).find((key) => value[key] !== previousValue[key]);

            const controls = this.tableForm.controls;
            const control = controls[changedKey];
            // Validation of value types for individual fields
            if (control) {
                if (control.errors) {
                    this.fieldForm?.setErrors({invalid: true});
                } else {
                    this.fieldForm?.setErrors(null);
                }
                this.cd.detectChanges();
            }

            if (changedKey) {
                const typedRowIndex = changedKey.split('-')[0];
                const typedColumnIndex = changedKey.split('-')[1];
                this.updateTableFormsResult(typedRowIndex, typedColumnIndex, value[changedKey]);
            }
            previousValue = {...value};
        });
        // Trigger validation top-down
        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value) => !!value),
                map((_) => {
                    this.onValidationCheck();
                }),
            )
            .subscribe();
    }

    createTableForms() {
        const mergedValue = [
            ...this.result[ResultFieldTypes.TABLE_NUMBER_COLUMNS],
            ...this.result[ResultFieldTypes.TABLE_TEXT_COLUMNS],
        ];
        this.field.tableRows.forEach((row, index) => {
            row.columns.forEach((column) => {
                const validators = [];
                if (this.field.requiredFixedValue) {
                    validators.push(Validators.required);
                }
                switch (column.columnType) {
                    case TableColumnType.TEXT:
                        validators.push(Validators.maxLength(255));
                        this.tableForm.addControl(
                            `${row.rowIndex}-${column.order}`,
                            new FormControl(this.getValue(index + 1, column.id, mergedValue || ''), validators),
                        );
                        break;

                    case TableColumnType.NUMERIC:
                        validators.push(Validators.min(column.range.min), Validators.max(column.range.max));
                        this.tableForm.addControl(
                            `${row.rowIndex}-${column.order}`,
                            new FormControl(this.getValue(index + 1, column.id, mergedValue || ''), validators),
                        );
                        break;
                }
            });
        });
        this.cd.detectChanges();
    }

    updateTableFormsResult(rowIndex: string, columnIndex: string, value: any): void {
        const row = this.field.tableRows.find((row) => row.rowIndex === parseInt(rowIndex));
        const col = row?.columns.find((col) => col.order === parseInt(columnIndex));
        const columnType =
            col?.columnType === TableColumnType.TEXT
                ? ResultFieldTypes.TABLE_TEXT_COLUMNS
                : col.columnType === TableColumnType.NUMERIC
                ? ResultFieldTypes.TABLE_NUMBER_COLUMNS
                : null;

        const existValue = this.result[columnType]?.filter(
            (item) => !(item.columnOrder === col.order && item.rowIndex === parseInt(rowIndex)),
        );

        const newValue = {
            fieldId: this.field.id,
            fieldCode: this.field.fieldCode,
            rowIndex: parseInt(rowIndex),
            columnId: col.id,
            columnLabel: col.label,
            columnOrder: col.order,
            value,
            ...(col.columnType === TableColumnType.NUMERIC && {valueType: col.range?.valueType || null}),
        };
        this.emitEvent(
            [...existValue, newValue],
            columnType === ResultFieldTypes.TABLE_TEXT_COLUMNS
                ? TableFieldType.TABLE_TEXT_COLUMNS
                : TableFieldType.TABLE_NUMBER_COLUMNS,
        );
    }

    getValue(index, columnId, mergedValue) {
        const match = mergedValue.find((res) => res.columnId === columnId && res.rowIndex === index);
        return match ? match.value : null;
    }

    filteringColumns(columns, type: CellType): TableColumn[] {
        const commentColumn = columns.filter((item) => item.label.toLowerCase().includes('comment'));
        const regularColumns = columns.filter((item) => item.label !== commentColumn[0].label);
        switch (type) {
            case CellType.REGULAR:
                return regularColumns;
            case CellType.COMMENT:
                return commentColumn;
        }
    }

    adjustHeight(): void {
        const textarea = this.textarea.nativeElement;
        textarea.style.height = '2.4375rem';
        const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const scrollHeightInRem = textarea.scrollHeight / baseFontSize;
        textarea.style.height = `${scrollHeightInRem}rem`;
    }

    getFromControl(rowIndex: number, colOrder: number): FormControl {
        const controlName = `${rowIndex}-${colOrder}`;
        return this.tableForm?.get(controlName) as FormControl;
    }

    getDataType(column) {
        return column.range?.valueType ? column.range?.valueType : 'TEXT';
    }

    getMax(column) {
        return column.range?.min ? column.range?.min : -99999;
    }

    getMin(column) {
        return column.range?.max ? column.range?.max : 99999;
    }

    /*
     * Implement abstract methods
     */
    validate(value: TableColumnNumberResultDto[] | TableColumnTextResultDto[]): boolean {
        if (this.required && (value == null || value.length == 0)) {
            return false;
        }
        // TODO
        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: any): any {
        // TODO
        return [];
    }

    getFormValue(): any {
        // TODO
    }

    fieldRequiredCheck(rowIndex, columnOrder): boolean {
        return this.fieldForm?.touched && this.getFromControl(rowIndex, columnOrder).hasError('required');
    }
    onValidationCheck(): void {
        if (this.tableForm && Object.keys(this.tableForm.value).length > 0 && this.required) {
            this.fieldForm?.markAsTouched();
            this.fieldForm?.setErrors({required: true});
        }
        this.cd.detectChanges();
    }
}
