import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse, SimpleSeries} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';
import {LpInfrastructureRequest} from '@core/interfaces/engin/load-profile-analysis/lp-common.model';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class PowerMeterReadingApi {
    private readonly prefix = 'load-profile-analysis/readings/corrected';

    constructor(private api: HttpService) {}

    public getInfrastructureNetLoad(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]> {
        return this.api
            .post(`${this.prefix}/${batchNumber}/chart/net-load`, data)
            .pipe(map((res: APIResponse<SimpleSeries<Date, number>[]>) => res.response));
    }

    public getMetersByBatch(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]> {
        return this.api
            .post(`${this.prefix}/${batchNumber}/chart/meters`, data)
            .pipe(map((res: APIResponse<SimpleSeries<Date, number>[]>) => res.response));
    }

    public listCorrectedReadingDtos(batchNumber: number, additionalQueryParam: string = ''): Observable<DataSource> {
        const params: string = additionalQueryParam ? '?' + additionalQueryParam : '';
        return this.api.getServerDataSource(`${this.prefix}/${batchNumber}/dto/list${params}`);
    }

    public downloadCorrectedReadings(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl> {
        return this.api
            .get(`${this.prefix}/${batchNumber}/dto/list/csv${paramsString && '?' + paramsString}`)
            .pipe(map((res: APIResponse<DownloadCSVUrl>) => res.response));
    }
}
