import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';
import {
    InvestmentOverviewRequest,
    OutcomeChartRequest,
    OutcomeChartResponse,
    OutcomeKpiResponse,
} from '@core/interfaces/engin/program-management/pm-reporting';

@Injectable()
export class PmReportingApi {
    private readonly prefix = 'pm/reporting';

    constructor(private api: HttpService) {}

    /*
     * APIs for Investment Overview
     */
    public getInvestmentOverview(param: InvestmentOverviewRequest): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/investment/overview`, param);
    }

    /*
     * APIs for Outcomes (legacy Optimizer)
     */
    public getChartDataSummarySpending(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-plan/spending`, request);
    }

    public getChartDataSummaryOutcomes(request: OutcomeChartRequest): Observable<APIResponse<OutcomeKpiResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-plan/outcomes`, request);
    }

    public getChartDataProactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-forecast/proactive`, request);
    }

    public getChartDataReactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-forecast/reactive`, request);
    }

    public getChartDataFailureForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/failure-forecast/failure`, request);
    }

    public getChartDataOutageForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/failure-forecast/outage`, request);
    }

    public getChartDataRiskForecastByImpact(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/risk-forecast/impact`, request);
    }

    public getChartDataRiskForecastByCategory(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/risk-forecast/category`, request);
    }
}
