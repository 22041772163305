import {Observable} from 'rxjs';
import {APIResponse, Filter, FilterFieldOption, PresignedURL} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    AssetInspectionResultDto,
    InspectionResultFullDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {NbComponentStatus} from '@nebular/theme';

export enum PageType {
    VIEW_LATEST = 'VIEW_LATEST',
    VIEW = 'VIEW',
    REVIEW = 'REVIEW',
    SUBMIT_CORRECTION = 'SUBMIT_CORRECTION',
}

export interface InspectionResultDto {
    id: number;
    inspectedDate: string;
    inspectedById: string;
    inspectedByName: string;
    status: InspectionStatusType;
    comment: string;
    approvedDate: string;
    approvedById: string;
    approvedByName: string;
    assetId: string;
    formId: number;
    formName: string;
    formVersion: number;
    latestFormVersion: number;
    groupName?: string;
}

export interface ChangeHistoryDto {
    changeDate: string; // Using string to represent LocalDateTime
    changeDoneByName: string;
    rows: string[];
}

export interface InspectionResultReviewDto {
    status: InspectionStatusType;
    comment: string;
    changeDoneById: string;
    changeDoneByName: string;
    assetInspectionResult: AssetInspectionResultDto;
}

export interface InspectionResultCorrectionDto {
    comment: string;
    changeDoneById: string;
    changeDoneByName: string;
    assetInspectionResult: AssetInspectionResultDto;
}

export enum InspectionStatusType {
    PENDING_REVIEW = 'PENDING_REVIEW',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    CORRECTED = 'CORRECTED',
}
export enum ButtonType {
    START_REVIEW = 'START_REVIEW',
    MAKE_A_CORRECTION = 'MAKE_A_CORRECTION',
    REJECT_WITH_COMMENT = 'REJECT_WITH_COMMENT',
    CHANGE_HISTORY = 'CHANGE_HISTORY',
    APPROVE = 'APPROVE',
    CLOSE = 'CLOSE',
    CANCEL = 'CANCEL',
    SUBMIT = 'SUBMIT',
}

export enum FormMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
}
export interface PageHeaderSetting {
    pageTitle: string;
    pageHeaderButton: PageHeaderButton[];
}
export interface PageHeaderButton {
    title: string;
    code: ButtonType;
    status: NbComponentStatus;
    display: boolean;
    alwaysShow?: boolean;
}
export abstract class MaintenancePlanningService {
    abstract listDto(): Observable<DataSource>;

    abstract findOneDto(resultId: number): Observable<InspectionResultDto>;

    abstract findOneFullDto(resultId: number): Observable<InspectionResultFullDto>;

    abstract getChangeHistory(resultId: number): Observable<ChangeHistoryDto[]>;

    abstract submitReview(resultId: number, req: InspectionResultReviewDto): Observable<any>;

    abstract submitCorrection(
        resultId: number,
        req: InspectionResultCorrectionDto,
    ): Observable<InspectionResultFullDto>;

    abstract listDtoCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>>;

    abstract findLatestInspectionResult(resultId: number): Observable<APIResponse<InspectionResultDto>>;

    abstract getFilterOptions(fieldKey: string, searchString: string): Observable<FilterFieldOption[]>;
    abstract getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>>;
    abstract uploadToPresignedUrl(url: string, data: File);
}
