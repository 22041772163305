import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbCollapseModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';

/*
 * Nebular imports
 */
import {
    NbAccordionModule,
    NbActionsModule,
    NbAlertModule,
    NbAutocompleteModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbRadioModule,
    NbRouteTabsetModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbStepperModule,
    NbTabsetModule,
    NbThemeModule,
    NbToastrModule,
    NbToggleModule,
    NbTooltipModule,
    NbUserModule,
    NbWindowModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

/*
 * Components
 */
import * as components from './components';
import {EmptyDataComponent} from './components';

/*
 * Pipes
 */
import {
    CapitalizePipe,
    DateFormatPipe,
    DatetimeFormatPipe,
    GroupByPipe,
    NumberWithCommasPipe,
    PluralPipe,
    RoundPipe,
    SafePipe,
    SentenceCasePipe,
    ShowEnabledPipe,
    TimeLocalizePipe,
    TimeUnlocalizePipe,
    TimezonePipe,
    TimingPipe,
    TwoLevelLoopsPipe,
    UnitPipe,
} from './pipes';

/*
 * Directives
 */
import {DynamicComponentDirective, ThrottleClickDirective} from './directives';

/*
 * Empty components
 */
import {
    EmptyAreaChartComponent,
    EmptyBarChartStackedComponent,
    EmptyBarChartStackedSingleComponent,
    EmptyColumnChartComponent,
    EmptyKpiChartComponent,
    EmptyLabelValueVerticalComponent,
    EmptyLineChartComponent,
    EmptyPieChartComponent,
    EmptyPieChartDonutComponent,
    EmptyTableComponent,
    EmptyTableSmallComponent,
} from './empty-components';

/*
 * Styles and theme
 */
import {DEFAULT_THEME} from './styles/theme.default';
import {DARK_THEME} from './styles/theme.dark';
import {DEFAULT_ACCESSIBILITY_THEME} from './styles/theme.accessibility.default';
import {DARK_ACCESSIBILITY_THEME} from './styles/theme.accessibility.dark';
// Other
import {AuthModule} from '@auth/auth.module';
import {RouterModule} from '@angular/router';

// External
import {NumericRangeControlComponent} from './components/numeric-range-control/numeric-range-control.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxValidationMessageComponent} from './components/validation-message/validation-message.component';
import {ReportNavbarComponent} from './components/report-navbar/report-navbar.component';
import {ReportTypeIndicatorComponent} from './components/report-type-indicator/report-type-indicator.component';
import {ChartDataSortComponent} from './components/chart-data-sort/chart-data-sort.component';
import {ChartDataSortPopupComponent} from './components/chart-data-sort/chart-data-sort-popup.component';
import {ChartLegendBarComponent} from './components/chart-legend-bar/chart-legend-bar.component';
import {ChartTitleBarComponent} from './components/chart-title-bar/chart-title-bar.component';
import {NestedAccordionComponent} from './components/nested-accordion/nested-accordion.component';
import {InlineRadioGroupComponent} from './components/inline-radio-group/inline-radio-group.component';
import {NgxMiniLineChartComponent} from './components/ngx-mini-line-chart/ngx-mini-line-chart.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {DataAuditWidgetComponent} from './components/data-audit-widget/data-audit-widget.component';
import {SystemInfoDialogComponent} from './components/system-info-dialog/system-info-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {HelpSupportComponent} from './components/help-support/help-support.component';
import {HelpSupportDialogComponent} from './components/help-support/help-support-dialog/help-support-dialog.component';
import {HelpSupportSideBarComponent} from './components/help-support/help-support-side-bar/help-support-side-bar.component';
import {HeaderSettingsComponent, HeaderSettingsDialogComponent} from '@theme/components';
import {NgxSmartTableModule} from '@mominsamir/ngx-smart-table';
import {MiniDataTableComponent} from './components/mini-data-table-card/mini-data-table/mini-data-table.component';
import {MiniTrendComponent} from './components/mini-trend/mini-trend.component';
import {ButtonGroupComponent} from './components/button-group/button-group.component';
import {PageHeaderBreadcrumbComponent} from './components/page-header-breadcrumb/page-header-breadcrumb.component';
import {WorkflowSelectorComponent} from './components/workflow-selector/workflow-selector.component';
import {PopoverMessageBoxComponent} from './components/popover-message-box/popover-message-box.component';
import {TooltipComponent} from '@theme/components/tooltip/tooltip.component';
import {PrevButtonComponent} from './components/pager-button/prev-button/prev-button.component';
import {NextButtonComponent} from './components/pager-button/next-button/next-button.component';
import {AccessibilitySwitcherComponent} from './components/accessibility-switcher/accessibility-switcher.component';
import {UserNotificationComponent} from './components/user-notification/user-notification.component';
import {OnlyNumberDirective} from '@theme/directives/only-number.directive';
import {ChartSimpleLegendBarComponent} from './components/chart-simple-legend-bar/chart-simple-legend-bar.component';
import {DashboardCardComponent} from './components/dashboard/dashboard-card/dashboard-card.component';
import {TruncateTextDirective} from '@theme/directives/truncate-text.directive';
import {DashboardCardTextComponent} from './components/dashboard/components/dashboard-card-text/dashboard-card-text.component';
import {DashboardCardNumberComponent} from './components/dashboard/components/dashboard-card-number/dashboard-card-number.component';
import {DashboardCardTableComponent} from './components/dashboard/components/dashboard-card-table/dashboard-card-table.component';
import {MeasureMetricComponent} from './components/spatial-card/components/measure-metric/measure-metric.component';
import {PointLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/engin-data/point-layer.service';
import {GenerateLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/generate-layer.service';
import {CoordinateConverterService} from '@core/utils/engin/geospatial-viewer/helper/coordinate-converter.service';
import {EnginDataPrepareService} from '@core/utils/engin/geospatial-viewer/helper/engin-data-prepare.service';
import {GeospatialService} from '@core/utils/engin/geospatial-viewer/helper/geospatial-service';
import {AssetPopupService} from '@core/utils/engin/geospatial-viewer/popups/asset-popup.service';
import {GeospatialViewerControlsStore} from '../pages/geospatial-viewer/api/geospatial-viewer-controls.store';
import {GeospatialFilterStore} from '../pages/geospatial-viewer/api/geospatial-filter.store';
import {AssetRendererService} from '@core/utils/engin/geospatial-viewer/renderers/asset-renderer.service';
import {AssetFieldsService} from '@core/utils/engin/geospatial-viewer/fields/asset-fields.service';
import {PolylineLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/engin-data/polyline-layer.service';
import {AssetGroupPopupService} from '@core/utils/engin/geospatial-viewer/popups/asset-group-popup.service';
import {HeatmapLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/engin-data/heatmap-layer.service';
import {PolygonLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/engin-data/polygon-layer.service';
import {AssetPolygonRendererService} from '@core/utils/engin/geospatial-viewer/renderers/asset-polygon-renderer.service';
import {RoadMoratoriumLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/custom-layer/road-moratorium-layer.service';
import {RoadMoratoriumFieldsService} from '@core/utils/engin/geospatial-viewer/fields/road-moratorium-fields.service';
import {RoadMoratoriumPopupService} from '@core/utils/engin/geospatial-viewer/popups/road-moratorium-popup.service';
import {RoadMoratoriumRendererService} from '@core/utils/engin/geospatial-viewer/renderers/road-moratorium-renderer.service';
import {CustomerDemographicLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/custom-layer/customer-demographic-layer.service';
import {CustomerDemographicFieldsService} from '@core/utils/engin/geospatial-viewer/fields/customer-demographic-fields.service';
import {CustomerDemographicPopupService} from '@core/utils/engin/geospatial-viewer/popups/customer-demographic-popup.service';
import {CustomerDemographicRendererService} from '@core/utils/engin/geospatial-viewer/renderers/customer-demographic-renderer.service';
import {DistrictOverlayLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/custom-layer/district-overlay-layer.service';
import {DistrictOverlayFieldsService} from '@core/utils/engin/geospatial-viewer/fields/district-overlay-fields.service';
import {DistrictOverlayPopupService} from '@core/utils/engin/geospatial-viewer/popups/district-overlay-popup.service';
import {DistrictOverlayRendererService} from '@core/utils/engin/geospatial-viewer/renderers/district-overlay-renderer.service';
import {DistrictSummarizeLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/custom-layer/district-summarize-layer.service';
import {DistrictSummarizeFieldsService} from '@core/utils/engin/geospatial-viewer/fields/district-summarize-fields.service';
import {DistrictSummarizePopupService} from '@core/utils/engin/geospatial-viewer/popups/district-summarize-popup.service';
import {DistrictSummarizeRendererService} from '@core/utils/engin/geospatial-viewer/renderers/district-summarize-renderer.service';
import {ProjectLayerService} from '@core/utils/engin/geospatial-viewer/esri-layers/custom-layer/project-layer.service';
import {ProjectFieldsService} from '@core/utils/engin/geospatial-viewer/fields/project-fields.service';
import {ProjectPopupService} from '@core/utils/engin/geospatial-viewer/popups/project-popup.service';
import {ProjectRendererService} from '@core/utils/engin/geospatial-viewer/renderers/project-renderer.service';
import {GeospatialViewerService} from '../pages/geospatial-viewer/api/geospatial-viewer.service';
import {GeospatialViewerApi} from '../pages/geospatial-viewer/api/geospatial-viewer.api';
import {GeospatialViewerControlsService} from '../pages/geospatial-viewer/api/geospatial-viewer-controls.service';
import {GeospatialViewerControlsApi} from '../pages/geospatial-viewer/api/geospatial-viewer-controls.api';
import {LegendBarComponent} from './components/spatial-card/components/legend-bar/legend-bar.component';
import {ChartKpiComponent} from './components/chart-kpi/chart-kpi.component';
import {ChartKpiContainerComponent} from './components/chart-kpi/chart-kpi-container/chart-kpi-container.component';
import {TabButtonsComponent} from '@theme/components/tab-buttons/tab-buttons.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {TagNoteEditDialogComponent} from './components/dialogs/tag-note-edit-dialog/tag-note-edit-dialog.component';
import {ActionCellComponent} from './components/data-table/cells/action-cell/action-cell.component';
import {CustomizedDeleteComponent} from './components/dialogs/customized-delete/customized-delete.component';
import {LogDialogComponent} from './components/dialogs/log-dialog/log-dialog.component';
import {ClickOutsideDirective} from '@theme/directives/click-outside.directive';
import {NbAuthModule} from '@nebular/auth';
import {FiltersAccordionComponent} from './components/control-panel/filters-accordion/filters-accordion.component';
import {PreprocessDialogComponent} from '@theme/components/dialogs/preprocess-dialog/preprocess-dialog.component';
import {AssetDetailsComponent} from './components/asset-details/asset-details.component';
import {ScopeCellComponent} from './components/data-table/cells/scope-cell/scope-cell.component';
import {ExpandRowComponent} from '@theme/components/data-table/cells/action-cell/expand-row.component';
import {FormComponent} from './components/form/./form.component';
import {FormSectionComponent} from './components/form/form-section/form-section.component';
import {FormCanvasComponent} from './components/form/form-canvas/form-canvas.component';
import {FormCellComponent} from './components/form/form-cell/form-cell.component';
import {FormFieldBaseComponent} from './components/form/cells/base/form-field-base.component';
import {TextComponent} from './components/form/cells/text/text.component';
import {NumericComponent} from './components/form/cells/numeric/numeric.component';
import {SingleSelectComponent} from './components/form/cells/single-select/single-select.component';
import {MultiSelectComponent} from './components/form/cells/multi-select/multi-select.component';
import {PhotoComponent} from './components/form/cells/photo/photo.component';
import {UploadImagesDialogComponent} from './components/dialogs/upload-images-dialog/upload-images-dialog.component';
import {ImageCarouselDialogComponent} from './components/image-carousel/image-carousel-dialog/image-carousel-dialog.component';
import {StepperComponent} from '@theme/components/stepper/stepper.component';
import {InputFilterComponent} from '@theme/components/data-table/custom-filters/input-filter.component';
import {FileValidateComponent} from '@theme/components/file-validate/file-validate.component';
import {DeleteDialogComponent} from '@theme/components/file-validate/delete-dialog/delete-dialog.component';
import {FileNameWithIconsComponent} from '@theme/components/file-validate/file-name-with-icon/file-name-with-icon';
import {ActionColumnComponent} from '@theme/components/file-validate/action-column/action-column';
import {FullImageDialogComponent} from './components/image-carousel/image-carousel-dialog/full-image-dialog/full-image-dialog.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
const NB_MODULES = [
    NbEvaIconsModule,
    NbCardModule,
    NbLayoutModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NgbCollapseModule,
    NgbTypeaheadModule,
    NbSecurityModule, // *nbIsGranted directive,
    NbProgressBarModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbStepperModule,
    NbButtonModule,
    NbButtonGroupModule,
    NbListModule,
    NbToastrModule,
    NbInputModule,
    NbFormFieldModule,
    NbAccordionModule,
    NbDatepickerModule,
    NbDialogModule,
    NbWindowModule,
    NbAlertModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbToggleModule,

    NbIconModule,
    NgxEchartsModule,
    NbAutocompleteModule,
];
const APP_MODULES = [NgxSmartTableModule, NgxFileDropModule];

const COMPONENTS = [
    // Auth
    components.PasswordResetComponent,
    components.PasswordForceComponent,
    components.PasswordEmptySsoComponent,
    components.ChangePasswordComponent,
    components.InputPasswordComponent,
    components.CustomNbAuthComponent,

    NgxValidationMessageComponent,
    NgxValidationMessageComponent,
    components.ThemeSwitcherComponent,
    components.FooterComponent,
    components.ValueBoxComponent,
    components.CheckboxGroupComponent,
    components.IconBoxComponent,
    components.PagesGroupPopoverComponent,
    components.MenuComponent,
    components.SubmenuComponent,
    components.SubmenuPopoverComponent,
    components.RouteTabsetComponent,
    components.TablePagerComponent,
    components.SidebarActionComponent,
    components.SidebarActionPopoverComponent,
    components.ChartComponent,
    components.ChartSettingsComponent,
    components.TableSettingsPopupComponent,
    components.ChartSettingsPopupComponent,
    components.SliderComponent,
    components.DataTableComponent,
    components.DateFilterComponent,
    components.FilterFieldComponent,
    components.GenericFilterComponent,
    components.StringDateFilterComponent,
    components.NumberInputBoxComponent,
    components.NumericRangeFilterComponent,
    components.ConfirmDialogComponent,
    components.ReportFilterDialogComponent,
    components.InputErrorComponent,
    components.NumberInputComponent,
    components.EmptyDataComponent,
    components.StatusCellComponent,
    components.TruncatedTextCellComponent,
    components.FileCellComponent,
    components.TableSettingsComponent,
    components.TableSettingsPopupComponent,
    components.MultiSelectWithSearchFilterComponent,
    components.MultiSelectFilterComponent,
    components.AlertStatusCellComponent,
    components.CheckBoxCellComponent,
    components.ToggleCellComponent,
    components.CurrentPositionVisualizerComponent,
    components.GuideDialogComponent,
    components.EditNoteDialogComponent,
    components.UploadFileDialogComponent,
    TabButtonsComponent,
    components.StringDateFilterComponent,

    components.ChangePasswordComponent,
    components.InputPasswordComponent,
    components.MultiselectAutocompleteComponent,
    components.AssetDocumentsComponent,
    components.DocumentPreviewComponent,
    components.DocumentListComponent,

    components.ButtonMultiselectComponent,
    components.NotesComponent,
    components.AssetDetailsComponent,
    components.AlertStatusComponent,

    HelpSupportComponent,
    HelpSupportDialogComponent,
    HelpSupportSideBarComponent,
    HeaderSettingsComponent,
    HeaderSettingsDialogComponent,

    // General
    PageHeaderComponent,
    PageHeaderBreadcrumbComponent,
    PopoverMessageBoxComponent,
    TooltipComponent,
    PrevButtonComponent,
    NextButtonComponent,
    UserNotificationComponent,

    // General, related to data audit tool
    InlineRadioGroupComponent,
    NestedAccordionComponent,
    NgxMiniLineChartComponent,
    ChartDataSortComponent,
    ChartDataSortPopupComponent,
    ChartTitleBarComponent,
    ChartLegendBarComponent,
    ChartSimpleLegendBarComponent,
    DataAuditWidgetComponent,
    MiniTrendComponent,
    ButtonGroupComponent,

    // General, related to reporting tool
    ReportNavbarComponent,
    ReportTypeIndicatorComponent,
    WorkflowSelectorComponent,

    //Data table Card
    MiniDataTableComponent,

    // Empty chart components
    EmptyAreaChartComponent,
    EmptyBarChartStackedComponent,
    EmptyBarChartStackedSingleComponent,
    EmptyColumnChartComponent,
    EmptyKpiChartComponent,
    EmptyLabelValueVerticalComponent,
    EmptyLineChartComponent,
    EmptyPieChartComponent,
    EmptyPieChartDonutComponent,
    EmptyTableComponent,
    EmptyTableSmallComponent,
    components.CustomAutocompleteComponent,

    // Spatial Card Components
    components.SpatialCardComponent,

    components.UploadFileCardComponent,
    components.DropFileComponent,
    components.DisplayFileComponent,

    components.RadioCardComponent,
    components.SensitiveInfoDisclosureLoginComponent,
    components.SensitiveInfoContentsComponent,
    components.SensitiveInfoDisclosureHeaderComponent,

    components.ControlPanelCheckboxGroupComponent,
    components.NumericRangeControlComponent,

    components.FormComponent,
];

const ENTRY_COMPONENTS = [EmptyDataComponent];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    SafePipe,
    ShowEnabledPipe,
    TimezonePipe,
    GroupByPipe,
    TwoLevelLoopsPipe,
    UnitPipe,
    SentenceCasePipe,
    DateFormatPipe,
    DatetimeFormatPipe,
];
const DATA_PIPES = [TimeLocalizePipe, TimeUnlocalizePipe];

const DIRECTIVES = [
    DynamicComponentDirective,
    OnlyNumberDirective,
    TruncateTextDirective,
    ThrottleClickDirective,
    ClickOutsideDirective,
];
const GEOSPATIAL_VIEWER_SERVICE = [
    //service
    PointLayerService,
    GenerateLayerService,
    CoordinateConverterService,
    EnginDataPrepareService,
    GeospatialService,
    AssetPopupService,
    GeospatialViewerControlsStore,
    GeospatialFilterStore,
    AssetRendererService,
    AssetFieldsService,
    PolylineLayerService,
    AssetGroupPopupService,
    HeatmapLayerService,
    PolygonLayerService,
    AssetPolygonRendererService,
    RoadMoratoriumLayerService,
    RoadMoratoriumFieldsService,
    RoadMoratoriumPopupService,
    RoadMoratoriumRendererService,
    CustomerDemographicLayerService,
    CustomerDemographicFieldsService,
    CustomerDemographicPopupService,
    CustomerDemographicRendererService,
    DistrictOverlayLayerService,
    DistrictOverlayFieldsService,
    DistrictOverlayPopupService,
    DistrictOverlayRendererService,
    DistrictSummarizeLayerService,
    DistrictSummarizeFieldsService,
    DistrictSummarizePopupService,
    DistrictSummarizeRendererService,
    ProjectLayerService,
    ProjectFieldsService,
    ProjectPopupService,
    ProjectRendererService,

    //APi
    GeospatialViewerService,
    GeospatialViewerApi,
    GeospatialViewerControlsService,
    GeospatialViewerControlsApi,
];

const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'default',
        },
        // [DEFAULT_THEME, DARK_THEME],
        [DEFAULT_THEME, DARK_THEME, DEFAULT_ACCESSIBILITY_THEME, DARK_ACCESSIBILITY_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
    ...NbDialogModule.forRoot().providers,
    ...NbWindowModule.forRoot().providers,
    ...NbToastrModule.forRoot().providers,
];

@NgModule({
    imports: [
        AuthModule,
        RouterModule,
        ...BASE_MODULES,
        ...NB_MODULES,
        ...APP_MODULES,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        NbAuthModule,
    ],
    exports: [
        ...BASE_MODULES,
        ...NB_MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DATA_PIPES,
        ...DIRECTIVES,
        ...APP_MODULES,
        DashboardCardComponent,
        DashboardCardTextComponent,
        DashboardCardNumberComponent,
        DashboardCardTableComponent,
        ChartKpiComponent,
        ChartKpiContainerComponent,
        NumericRangeControlComponent,
        FiltersAccordionComponent,
        StepperComponent,
        InputFilterComponent,
        FileValidateComponent,
        DeleteDialogComponent,
        FileNameWithIconsComponent,
        ActionColumnComponent,
    ],
    providers: [DecimalPipe, DeviceDetectorService, ...GEOSPATIAL_VIEWER_SERVICE],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DATA_PIPES,
        ...DIRECTIVES,
        SystemInfoDialogComponent,
        AccessibilitySwitcherComponent,
        DashboardCardComponent,
        DashboardCardTextComponent,
        DashboardCardNumberComponent,
        DashboardCardTableComponent,
        MeasureMetricComponent,
        LegendBarComponent,
        ChartKpiComponent,
        ChartKpiContainerComponent,
        SystemInfoDialogComponent,
        NumericRangeControlComponent,
        TagNoteEditDialogComponent,
        ActionCellComponent,
        CustomizedDeleteComponent,
        LogDialogComponent,
        PreprocessDialogComponent,
        FiltersAccordionComponent,
        AssetDetailsComponent,
        ExpandRowComponent,
        ScopeCellComponent,
        FormComponent,
        FormSectionComponent,
        FormCanvasComponent,
        FormCellComponent,
        TextComponent,
        NumericComponent,
        SingleSelectComponent,
        MultiSelectComponent,
        PhotoComponent,
        UploadImagesDialogComponent,
        ImageCarouselDialogComponent,
        StepperComponent,
        FileValidateComponent,
        DeleteDialogComponent,
        FileNameWithIconsComponent,
        ActionColumnComponent,
        InputFilterComponent,
        FullImageDialogComponent,
    ],
    entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NB_THEME_PROVIDERS,
                DateFormatPipe,
                DatetimeFormatPipe,
                TimeLocalizePipe,
                TimeUnlocalizePipe,
                NumberWithCommasPipe,
            ],
        };
    }
}
