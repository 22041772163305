import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormControl, FormGroupDirective} from '@angular/forms';
import {UserSettingPageMode} from '../../../pages/users/user/profile-settings/general/general.component';

type INPUT_MODE = 'PASSWORD' | 'TEXT';

@Component({
    selector: 'ngx-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['./input-password.component.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}],
})
export class InputPasswordComponent implements OnInit {
    @Input() fc: FormControl;
    @Input() controlName: string;
    @Input() id: string;
    @Input() name: string;
    @Input() type: string;
    @Input() fieldSize: string;

    @Output() onInputChanges = new EventEmitter();
    @Input() pageMode: UserSettingPageMode;
    UserSettingPageMode = UserSettingPageMode;

    inputMode: INPUT_MODE = 'PASSWORD';

    constructor(private ref: ChangeDetectorRef) {}

    onInputChangedEvent($event) {
        this.onInputChanges.emit($event);
    }

    setInputMode(mode: any) {
        this.inputMode = mode;
        this.ref.detectChanges();
    }

    get control(): FormControl {
        return this.fc;
    }

    ngOnInit(): void {
        if (this.pageMode === UserSettingPageMode.VIEW) {
            this.fc.disable();
        } else if (this.pageMode === UserSettingPageMode.EDIT) {
            this.fc.enable();
        }
    }
}
