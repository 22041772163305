<nb-card class="spatial-container">
    <!-- Card header -->
    <nb-card-header>
        <div class="spatial-header">
            <div class="title">
                {{ title }}
            </div>
        </div>
    </nb-card-header>

    <!-- Body: map and map components -->
    <nb-card-body>
        <div #mapViewNode class="map-container" id="viewDiv">
            <ng-container *ngIf="metricSelectEnabled">
                <div class="map-controls-container">
                    <div>
                        <ngx-measure-metric
                            *ngIf="cardResponse$ | async as cardResponse"
                            [options]="cardResponse.configData.measures"
                            [initMetric]="cardResponse.sourceData.metricType"
                            (onSelectChange)="onUpdateMetric($event)"
                        ></ngx-measure-metric>
                    </div>
                </div>
                <div *ngIf="legendColor$ | async as legendColor">
                    <ngx-legend-bar [legendData]="legendColor" [valueType]="valueType"></ngx-legend-bar>
                </div>
            </ng-container>
        </div>
    </nb-card-body>
</nb-card>
