import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FilterFieldType} from '@core/interfaces/system/system-common';
import {AnalyzerPopoutFilter} from '@core/interfaces/common/popout';
import {FilterState} from '@theme/components';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {GenericFilterAccordion} from '@theme/components/control-panel/generic-filter-accordion';

@Component({
    selector: 'ngx-generic-filter-accordion',
    templateUrl: './generic-filter-accordion.component.html',
    styleUrls: ['./generic-filter-accordion.component.scss'],
})
export class GenericFilterAccordionComponent extends Unsubscribable implements OnInit {
    @Input() store: GenericFilterAccordion;

    FilterFieldType = FilterFieldType;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    public ngOnInit() {
        this.store.refresh$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    isNumeric(fieldType: FilterFieldType): boolean {
        return FilterFieldType.isNumericFieldType(fieldType);
    }

    isFilterApplied(filterItem: AnalyzerPopoutFilter): boolean {
        if (FilterFieldType.isNumericFieldType(filterItem.fieldType)) {
            let _value = this.store.filtersFormGroupControls[filterItem.fieldKey].value;
            return !(!_value?.min && !_value?.max);
        }
        if (filterItem.fieldType === FilterFieldType.DATE_RANGE) {
            let _value = this.store.filtersFormGroupControls[filterItem.fieldKey].value;
            return !(!_value?.start && !_value?.end);
        } else {
            let _value = this.store.filtersFormGroupControls[filterItem.fieldKey].value;
            return _value?.length;
        }
    }

    handleSearchChange(query: string, filter: AnalyzerPopoutFilter): void {
        this.store.updateFilterOptions(filter, query);
    }

    handleStateChange(state: FilterState, filter: AnalyzerPopoutFilter): void {
        this.store.updateFiltersFullState(filter.fieldKey, state);
    }
}
