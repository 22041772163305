import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {
    PmSensitivityRequest,
    PmSensitivityResetRequest,
    PmSensitivityWorkflow,
} from '@core/interfaces/engin/program-management/pm-sensitivity';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';

@Injectable()
export class PmSensitivityApi {
    private readonly prefix = 'pm/sensitivity';

    constructor(private api: HttpService) {}

    public findSensitivity(req: PmSensitivityRequest): Observable<APIResponse<PmSensitivityWorkflow>> {
        return this.api.post(`${this.prefix}/find`, req);
    }

    public resetSensitivity(req: PmSensitivityResetRequest): Observable<APIResponse<PmSensitivityWorkflow>> {
        return this.api.post(`${this.prefix}/reset`, req);
    }

    public getOneSensitivityWorkflow(sensitivityWorkflowId: number): Observable<APIResponse<PmSensitivityWorkflow>> {
        return this.api.get(`${this.prefix}/workflow/${sensitivityWorkflowId}`);
    }
}
