import {GeospatialViewerService} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {StudiesStore} from '@store/common/studies.store';
import {Injectable} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {LayersService} from '../../../../../pages/geospatial-viewer/layers/layers.service';
import {map} from 'rxjs/operators';
import {
    FieldDataType,
    GeospatialIconType,
    GeospatialColourType,
    GeospatialTooltipRequest,
    GeospatialTooltipResponse,
    GeospatialTooltipSection,
    GeospatialTooltipField,
    GeospatialTooltipType,
    CoordinateLatLong,
} from '../../../../../pages/geospatial-viewer/model/tooltip.model';
import Feature = __esri.Feature;
import {MapColor, MapColoursService} from '@core/utils/engin/geospatial-viewer/renderers/map-colours.service';
import {FormatsService} from '@core/utils';
import {VisualizationType} from '../../../../../pages/geospatial-viewer/model/visualization';

const mainLabelStyle =
    'font-family: Roboto; font-size: 14px; font-style: normal; font-weight: 700; line-height: 20px; margin-bottom: 10px;';
const subLabelStyle =
    'font-size: 14px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 4px; width: 50%; flex:1;';
const valueStyle =
    'font-size: 14px; font-style: normal; font-weight: 400; line-height: 16px; margin-bottom: 4px; width: 50%; flex:1;';
const divStyle = 'display: flex;';

@Injectable()
export class GenericPopupService {
    constructor(
        private studiesStore: StudiesStore,
        private geospatialViewerService: GeospatialViewerService,
        private nbThemeService: NbThemeService,
        private layerService: LayersService,
    ) {}

    /**
     * General tooltip handling for any layer.
     * Note: when implementing from a general service,
     *  `content: this.genericPopupService.getGenericPopupFunction.bind(this.genericPopupService, metrics || []),`
     * - extra args will be passed here as arg1 onwards
     * - feature will be automatically injected as the last arg
     * - due to this, extra args MUST always be passed with values
     * @param metrics
     * @param feature
     */
    getGenericPopupFunction(metrics: any[], feature: Feature): Promise<any> {
        /*
         * TODO: Refactor to get tooltipType and visualizationType based on feature.layer rather than feature.attributes.
         *  - feature.attributes should be as small as possible, since extra data adds load-time for the user.
         */
        const entityId: string = feature.graphic.attributes.id;

        const tooltipType: GeospatialTooltipType = feature.graphic.attributes.tooltipType;
        // This is needed for rendering tooltipType METRIC_GROUP, and data is actually the visualizationType
        const visualizationType: VisualizationType =
            tooltipType === GeospatialTooltipType.METRIC_GROUP ? feature.graphic.attributes.classCode : null;
        const studyId: number = this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY);

        let reqMetrics: any[] =
            metrics && metrics.length > 0
                ? metrics
                : this.layerService.metricLayers
                      .filter((l) => l.getMeasure() && l.getMetric() && l.getVisualizationType())
                      .map((layer) => ({
                          visualizationType: layer.getVisualizationType(),
                          measureType: layer.getMeasure(),
                          metricType: layer.getMetric(),
                      }));

        const popupRequest: GeospatialTooltipRequest = {
            tooltipType,
            entityId,
            visualizationType,
            metrics:
                tooltipType === GeospatialTooltipType.METRIC_ASSET || tooltipType === GeospatialTooltipType.METRIC_GROUP
                    ? reqMetrics
                    : [],
        };

        return this.geospatialViewerService
            .getGeospatialTooltipData(studyId, popupRequest)
            .pipe(map((data: GeospatialTooltipResponse) => this.getGenericPopupTemplate(feature, data)))
            .toPromise();
    }

    private getGenericPopupTemplate(feature: Feature, data: GeospatialTooltipResponse) {
        const template = document.createElement('div');

        template.innerHTML = `
            <div style="margin-top: 7px;">
                <div style="${divStyle}">
                    ${this.getIcon(data.title.iconType)}
                    <p style="${mainLabelStyle}">${data.title.label}</p>
                </div>
                ${this.getSectionsHtml(data.sections)}
                ${this.getExternalLinks(data.centerCoordinate)}
            </div>`;

        return template;
    }

    /** Get icon, for tooltip header **/
    private getIcon(entityIcon: GeospatialIconType): string {
        let icon = '';
        switch (entityIcon) {
            case GeospatialIconType.POINT:
                icon = '<calcite-icon icon="bullet-point" style="margin-right: 8px"></calcite-icon>';
                break;
            case GeospatialIconType.LINE:
                icon = '<calcite-icon icon="line-straight" scale="s" style="margin-right: 8px"></calcite-icon>';
                break;
            case GeospatialIconType.POLYGON:
                icon = '<calcite-icon icon="polygon-area" style="margin-right: 8px"></calcite-icon>';
                break;
        }
        return icon;
    }

    /** Get HTML for each tooltip section **/
    private getSectionsHtml(list: GeospatialTooltipSection[]): string {
        return list
            .sort((a, b) => a.order - b.order)
            .map((section) => {
                const sectionHeader = section.label || '';
                const fieldsHtml = section.fields.map((field) => this.renderField(field)).join('');
                return `<div style="margin-top: 16px"><p style="${mainLabelStyle}">${sectionHeader}</p>${fieldsHtml}</div>`;
            })
            .join('');
    }

    /** Get HTML for each tooltip section > field **/
    private renderField(field: GeospatialTooltipField): string {
        // Field rendering may fail, and causes the entire tooltip to not render
        try {
            const fieldLabel: string = field.label;
            const fieldValue: string = this.formatFieldValue(field) || '—';

            const displayColour: string = this.getMapCategoryColour(field);

            const colorIndicator = displayColour
                ? `<svg height="8" width="8" style="vertical-align:baseline;"><circle cx="4" cy="4" r="4" fill="${displayColour}"/></svg>`
                : '';

            return `<div style="${divStyle}">
                    <p style="${subLabelStyle}">${fieldLabel}</p>
                    <p style="${valueStyle}">${fieldValue} ${colorIndicator}</p>
                </div>`;
        } catch {
            return '';
        }
    }

    /** Prepare value for a single field */
    private formatFieldValue(field: GeospatialTooltipField): string {
        if (field.value == null) {
            return 'N/A';
        }

        switch (field.dataType) {
            // String: no value preparation
            case FieldDataType.STRING:
                return field.value;
            // Numeric: prepare numeric value
            case FieldDataType.DOUBLE:
            case FieldDataType.LONG:
                return FormatsService.prepareValueFromUnit(field.value, field.unit);
            // Other types: no value preparation
            default:
                return field.value;
        }
    }

    /** Get colour, or default to null when colour is not provided */
    //ToDo Change/remove this method later after expanding values in GeospatialMeasureMetricColor on the BE side
    private getMapCategoryColour(field: GeospatialTooltipField): string {
        if (field.colourType == null) return null;
        if (field.colourType === GeospatialColourType.NONE) return null;

        // Find specific colour
        const r: MapColor = MapColoursService.list(this.nbThemeService.currentTheme).find(
            (mapColor: MapColor) => mapColor.value === field.colourType,
        );
        if (r != null) return r.color;

        // Else fallback to old colour scheme; this will be removed eventually
        const colorNumber = field.colourType.slice(-1);

        let transformedFieldColor = '';
        if (field.dataType === FieldDataType.STRING) {
            transformedFieldColor = `CATEGORY_${colorNumber}`;
        } else {
            transformedFieldColor = `NUMERIC_${colorNumber}`;
        }

        const r2: MapColor = MapColoursService.list(this.nbThemeService.currentTheme).find(
            (mapColor: MapColor) => mapColor.value === transformedFieldColor,
        );

        return r2?.color || MapColoursService.getNoColour(this.nbThemeService.currentTheme).color;
    }

    /** Get HTML for external links section. This is optional, so may return empty string. **/
    private getExternalLinks(coordinate: CoordinateLatLong): string {
        if (coordinate == null) return '';

        const lat: number = coordinate.latitude;
        const long: number = coordinate.longitude;

        const streetViewUrl = `http://maps.google.com/maps?layer=c&cbll=${lat},${long}`;
        const mapsUrl = `http://maps.google.com/maps?ll=${lat},${long}`;
        return lat && long
            ? `
        <div class='row' style="margin-top: 16px">
            <div class='col-3'>
            <p style="${mainLabelStyle}">External links: </p>
            </div>
            <a class='col-4' href="${streetViewUrl}" target="_blank" style="display: flex;">
              Google Street View
              <calcite-icon style="margin-left: 4px; width: 16px; height: 16px; min-height: 0; min-width: 0;" icon="launch"/>
            </a>
            <a class='col-4' href="${mapsUrl}" target="_blank" style="display: flex;">
              Google Maps
              <calcite-icon style="margin-left: 4px; width: 16px; height: 16px; min-height: 0; min-width: 0;" icon="launch"/>
            </a>
        </div>
        `
            : '';
    }

    defaultPopupTemplate(feature) {
        const entityId: string = feature.graphic.attributes.id;
        const template = document.createElement('div');
        template.innerHTML = `<div class='container'>
                            <div class='row'>
                                <div class="col">
                                    <p class="m-0 p-0 text-center">${entityId}</p>
                                </div>
                            </div>
                        </div>`;
        return template;
    }
}
