import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {
    FeederReviewDto,
    FeederReviewLPService,
    LpBatchDto,
} from '@core/interfaces/engin/load-profile-analysis/feeder-review';
import {map, share, shareReplay, tap} from 'rxjs/operators';
import {PagingResponse} from '@core/interfaces/system/system-common';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ConfigLoadProfileRendering, ConfigurationService} from '@core/interfaces/common/configuration';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class LoadProfileStore extends Unsubscribable {
    public batchesListLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public batchesListLoading$: Observable<boolean> = this.batchesListLoading.asObservable();

    public selectedFeeders$: BehaviorSubject<FeederReviewDto[]> = new BehaviorSubject([]);

    public batchesList: BehaviorSubject<LpBatchDto[]> = new BehaviorSubject([]);
    public batchesList$: Observable<LpBatchDto[]> = this.batchesList.asObservable();

    protected selectedBatch: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    readonly selectedBatch$: Observable<number> = this.selectedBatch.asObservable().pipe(share(), shareReplay(1));

    public allFeedersApproved: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public frequency: BehaviorSubject<ConfigLoadProfileRendering> = new BehaviorSubject(null);
    public frequency$: Observable<ConfigLoadProfileRendering> = this.frequency.asObservable();

    protected _refreshMetersSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly refreshMetersSummary$: Observable<number> = combineLatest<Observable<number>, Observable<boolean>>([
        this.selectedBatch$,
        this._refreshMetersSummary.asObservable(),
    ]).pipe(map(([batchNumber]) => batchNumber));

    protected _refreshFeederOverviewSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly refreshFeederOverviewSummary$: Observable<number> = combineLatest<Observable<number>, Observable<boolean>>(
        [this.selectedBatch$, this._refreshFeederOverviewSummary.asObservable()],
    ).pipe(map(([batchNumber]) => batchNumber));

    constructor(
        private feederLpService: FeederReviewLPService,
        private configService: ConfigurationService,
        private toastrService: NbToastrService,
    ) {
        super();

        this.feederLpService
            .getBatchesList()
            .pipe(
                map((res: PagingResponse<LpBatchDto>) => res.items),
                tap((list: LpBatchDto[]) => {
                    if (!list?.length) {
                        this.toastrService.warning(
                            'Please upload Batches via Persisted Data Management',
                            'Batches are not uploaded',
                            {duration: 50000},
                        );
                    }

                    this.batchesList.next(list);

                    this.setBatch(list?.length ? list[0]?.batchNumber : null);

                    this.batchesListLoading.next(false);
                }),
                share(),
            )
            .subscribe();

        this.configService
            .getConfigLoadProfileRendering()
            .subscribe((frequency: ConfigLoadProfileRendering) => this.frequency.next(frequency));
    }

    getBatch() {
        return this.selectedBatch.value;
    }

    setBatch(value: number | null) {
        return this.selectedBatch.next(value);
    }

    getBatchFullDto() {
        return this.batchesList.value.find((item) => item.batchNumber === this.getBatch());
    }

    getFrequency(): ConfigLoadProfileRendering {
        return this.frequency.value;
    }

    getBatchList(): LpBatchDto[] {
        return this.batchesList.value;
    }

    refreshMetersSummary() {
        this._refreshMetersSummary.next(true);
    }

    refreshFeederOverviewSummary() {
        this._refreshFeederOverviewSummary.next(true);
    }
}
