import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'ngx-check-box-cell',
    template: `
        <div class="action-cell">
            <nb-checkbox
                [checked]="checkSelectedValues(rowData.id, this.selectedFiles)"
                [ngClass]="(loadingDataTableFlag.asObservable() | async) ? 'check-box' : 'disable-check-box'"
                (checkedChange)="onClick($event, rowData)"
            ></nb-checkbox>
        </div>
    `,
    styleUrls: ['./check-box-cell.component.scss'],
})
export class CheckBoxCellComponent implements ViewCell {
    @Input() value: string | number;
    @Input() rowData: any;
    @Input() selectedFiles: any[] = [];
    @Input() multipleSelect: false;
    @Output() onSelectChange = new EventEmitter();
    loadingDataTableFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() {
        // If user select the checkbox during the reloading datatable time, it will be causing an error.
        setTimeout(() => {
            this.loadingDataTableFlag.next(true);
        }, 500);
    }
    checkSelectedValues(value, array) {
        return array.some((item) => item.id === value);
    }

    onClick(selected: any, rowData: any) {
        this.onSelectChange.emit({selected: selected, rowData: rowData});
    }
}
