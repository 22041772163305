import {Injectable} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {GenericFilterAccordion} from '@theme/components/control-panel/generic-filter-accordion';
import {GenericPopoutFilter, GenericPopoutSettings} from '@core/interfaces/common/popout';
import {Filter, FilterFieldType, FilterOperatorType} from '@core/interfaces/system/system-common';
import {UsersStore} from '@store/common/users.store';
import {takeUntil} from 'rxjs/operators';
import {StudiesStore} from '@store/common/studies.store';
const settings: GenericPopoutSettings = {
    filters: [
        {
            fieldKey: 'status',
            fieldName: 'Status',
            fieldType: FilterFieldType.STRING,
            operator: FilterOperatorType.IN,
            options: [
                {key: 'SCOPE', name: 'Scope'},
                {key: 'PROJECT', name: 'Project'},
                {key: 'BUILT', name: 'Built'},
                {key: 'GENERATED', name: 'Generated'},
            ],
            searchEnabled: false,
            simple: true,
        },
        {
            fieldKey: 'ownerId',
            fieldName: 'Ownership',
            fieldType: FilterFieldType.STRING,
            operator: FilterOperatorType.EQUAL,
            options: [
                {key: 'me', name: 'Me'},
                {key: 'other', name: 'Others'},
            ],
            searchEnabled: false,
            simple: true,
        },
    ],
};

@Injectable()
export class ProjectsFilterStore extends GenericFilterAccordion {
    constructor(fb: FormBuilder, private userStore: UsersStore, private studiesStore: StudiesStore) {
        super(fb);

        this.studiesStore.activeWorkflowRisk$.pipe(takeUntil(this.unsubscribe$)).subscribe((activeWorkflowInfo) => {
            if (!!activeWorkflowInfo) {
                this.updateFilterSettings(settings);
            }
        });
    }

    mapFormGroupValue(popoutValue: any): Filter[] {
        // Filters
        const filterList: Filter[] = [];

        this.filterSettings.value.filters.forEach((item: GenericPopoutFilter) => {
            let options: string[] = [];

            if (item.fieldType === FilterFieldType.STRING) {
                popoutValue[item.fieldKey]?.forEach((option) => {
                    options.push(option.key);
                });

                // Do not add filters if there are no selected values
                if (item.fieldKey === 'ownerId' && options.length === 1) {
                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: options[0] === 'me' ? item.operator : FilterOperatorType.NOT_EQUAL,
                        value: this.userStore.getCurrentUser().id,
                    });
                } else if (options.length > 0 && item.options.length !== options.length) {
                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            }
        });

        return filterList;
    }
}
