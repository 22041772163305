import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    PmSensitivityRequest,
    PmSensitivityWorkflow,
    PmSensitivityService,
    PmSensitivityResetRequest,
} from '@core/interfaces/engin/program-management/pm-sensitivity';
import {PmSensitivityApi} from '../../api/program-management/pm-sensitivity.api';

@Injectable()
export class PmSensitivityServiceImpl extends PmSensitivityService {
    constructor(private api: PmSensitivityApi) {
        super();
    }

    public findSensitivity(req: PmSensitivityRequest): Observable<APIResponse<PmSensitivityWorkflow>> {
        return this.api.findSensitivity(req);
    }

    public resetSensitivity(req: PmSensitivityResetRequest): Observable<APIResponse<PmSensitivityWorkflow>> {
        return this.api.resetSensitivity(req);
    }

    public getOneSensitivityWorkflow(sensitivityWorkflowId: number): Observable<APIResponse<PmSensitivityWorkflow>> {
        return this.api.getOneSensitivityWorkflow(sensitivityWorkflowId);
    }
}
