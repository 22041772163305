import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {
    LoadForecastService,
    LoadForecastSettings,
    PeriodFilter,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {StudiesStore} from '@store/common/studies.store';
import {takeUntil} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HeaderFiltersStore extends Unsubscribable {
    // Set to 'true' when data is ready
    private dataReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly dataReady$: Observable<boolean> = this.dataReady.asObservable();

    // Lists of setting values, retrieved from API call or default static
    private scenarioOptions: {key: string; label: string}[] = [];
    private seasonOptions: {key: string; label: string}[] = [];
    private seasonTimingOptions: {key: string; label: string}[] = [];
    private subsystemOptions: {key: string; label: string}[] = [];
    private stationOptions: {key: string; label: string}[] = [];
    private feederOptions: {key: string; label: string}[] = [];
    private vaultOptions: {key: string; label: string}[] = [];
    private lineSectionOptions: {key: string; label: string}[] = [];
    private dxTransformerOptions: {key: string; label: string}[] = [];
    private meterOptions: {key: string; label: string}[] = [];
    private demandFactorsOptions: {key: string; label: string}[] = [
        {key: 'ORGANIC_GROWTH', label: 'Organic Growth'},
        {key: 'LOAD_TRANSFER', label: 'Load Transfers'},
        {key: 'LLA', label: 'Large Load Additions'},
        {key: 'ELECTRIC_VEHICLE', label: 'Electric Vehicles'},
        {key: 'PHOTOVOLTAIC', label: 'Photovoltaics'},
    ];
    private pivotDataOptions: {key: string; label: string}[] = [
        {key: 'LOAD_TYPE_CATEGORY', label: 'Load Type (Category)'},
        {key: 'LOAD_TYPE_DETAIL', label: 'Load Type (Detail)'},
        {key: 'LOAD_CONDITION', label: 'Load Condition'},
        {key: 'VOLTAGE_OPERATING', label: 'Voltage Level'},
    ];
    private viewByOptions: {key: string; label: string}[] = [
        {key: 'STATION', label: 'Station'},
        {key: 'TRANSFORMER', label: 'Transformer'},
        {key: 'FEEDER', label: 'Feeder'},
    ];

    // Default values, retrieved from API call or default static
    private scenarioDefault: string;
    private seasonDefault: string;
    private seasonTimingDefault: string;
    private subsystemDefault: string;
    private stationDefault: string;
    private feederDefault: string;
    private vaultDefault: string;
    private lineSectionDefault: string;
    private dxTransformerDefault: string;
    private meterDefault: string;
    private demandFactorsDefault: string[] = ['ORGANIC_GROWTH'];
    private pivotDataDefault: string = 'LOAD_TYPE_CATEGORY';
    private viewByDefault: string = 'STATION';
    private periodDefault: PeriodFilter;

    // Active values
    readonly scenario$ = new BehaviorSubject<string>(null);
    readonly season$ = new BehaviorSubject<string>(null);
    readonly seasonForApi$ = new BehaviorSubject<string[]>([]);
    readonly seasonTiming$ = new BehaviorSubject<string>(null);
    readonly subsystem$ = new BehaviorSubject<string>(null);
    readonly station$ = new BehaviorSubject<string>(null);
    readonly feeder$ = new BehaviorSubject<string>(null);
    readonly vault$ = new BehaviorSubject<string>(null);
    readonly lineSection$ = new BehaviorSubject<string>(null);
    readonly dxTransformer$ = new BehaviorSubject<string>(null);
    readonly meter$ = new BehaviorSubject<string>(null);
    readonly demandFactors$ = new BehaviorSubject<string[]>(this.demandFactorsDefault);
    readonly pivotData$ = new BehaviorSubject<string>(this.pivotDataDefault);
    readonly viewBy$ = new BehaviorSubject<string>(this.viewByDefault);
    readonly period$ = new BehaviorSubject<PeriodFilter>(null);

    constructor(private loadForecastService: LoadForecastService, private studiesStore: StudiesStore) {
        super();

        this.studiesStore.activeStudyIdLf$.pipe(takeUntil(this.unsubscribe$)).subscribe((workflowItemId: number) => {
            this.loadForecastService
                .initSettingsByWorkflowItem(workflowItemId)
                .subscribe((settings: LoadForecastSettings) => {
                    // Set period
                    if (settings.period) {
                        this.periodDefault = settings.period as PeriodFilter;
                        this.setPeriod({
                            min: this.periodDefault.min,
                            max: this.periodDefault.max,
                            current: this.periodDefault.current || String(new Date().getFullYear()),
                        });
                    }

                    // Set seasons and default season, and seasonTiming
                    if (settings.season && settings.season?.defaultKey && settings.season?.options) {
                        this.seasonTimingDefault = settings.season.defaultKey as string;
                        this.seasonTimingOptions = Object.keys(settings.season.options).map((k) => {
                            return {
                                key: k,
                                label: settings.season.options[k],
                            };
                        });
                        this.seasonOptions = this.seasonTimingOptions.filter((s) => s.key != 'WORST_CASE');
                        // Add "All" option only when there is more than a single other option.
                        if (this.seasonOptions.length > 1) {
                            this.seasonOptions.push({key: 'All', label: 'All'});
                            this.seasonDefault = 'All';
                        } else {
                            this.seasonDefault = this.seasonOptions[0].key;
                        }
                        this.setSeasonTiming();
                        this.setSeason();
                    }

                    // Set scenarios and default scenario
                    if (settings.scenario && settings.scenario?.defaultKey && settings.scenario?.options) {
                        this.scenarioDefault = settings.scenario.defaultKey as string;
                        this.scenarioOptions = Object.keys(settings.scenario.options).map((k) => {
                            return {
                                key: k,
                                label: settings.scenario.options[k],
                            };
                        });
                        this.setScenario();
                    }

                    // Set subsystems and default subsystem
                    if (settings.subsystem && settings.subsystem?.defaultKey && settings.subsystem?.options) {
                        this.subsystemDefault = settings.subsystem.defaultKey as string;
                        this.subsystemOptions = Object.keys(settings.subsystem.options).map((k) => {
                            return {
                                key: k,
                                label: settings.subsystem.options[k],
                            };
                        });
                        this.setSubsystem();
                    }

                    // Set stations and default station
                    if (settings.station && settings.station?.options) {
                        this.stationOptions = Object.keys(settings.station.options).map((k) => {
                            return {
                                key: k,
                                label: settings.station.options[k],
                            };
                        });
                        this.stationDefault =
                            settings.station.defaultKey == ''
                                ? this.stationOptions[0].key
                                : settings.station.defaultKey;
                        this.setStation();
                    }

                    // Set feeders and default feeder
                    if (settings.feeder && settings.feeder?.options) {
                        this.feederOptions = Object.keys(settings.feeder.options).map((k) => {
                            return {
                                key: k,
                                label: settings.feeder.options[k],
                            };
                        });
                        this.feederDefault =
                            settings.feeder.defaultKey == '' ? this.feederOptions[0].key : settings.feeder.defaultKey;
                        this.setFeeder();
                    }

                    // Set vault and default vault
                    if (settings.vault && settings.vault?.options && !settings.vault.disabled) {
                        this.vaultOptions = Object.keys(settings.vault.options).map((k) => {
                            return {
                                key: k,
                                label: settings.vault.options[k],
                            };
                        });
                        this.vaultDefault =
                            settings.vault.defaultKey == '' ? this.vaultOptions[0].key : settings.vault.defaultKey;
                        this.setVault();
                    }

                    // Set line section and default line section
                    if (settings.lineSection && Object.keys(settings.lineSection?.options).length > 0) {
                        this.lineSectionOptions = Object.keys(settings.lineSection.options).map((k) => {
                            return {
                                key: k,
                                label: settings.lineSection.options[k],
                            };
                        });
                        this.lineSectionDefault =
                            settings.lineSection.defaultKey == ''
                                ? this.lineSectionOptions[0].key
                                : settings.lineSection.defaultKey;
                        this.setLineSection();
                    }

                    // Set dx transformer and default dx transformer
                    if (settings.dxTransformer && Object.keys(settings.dxTransformer?.options)) {
                        this.dxTransformerOptions = Object.keys(settings.dxTransformer.options).map((k) => {
                            return {
                                key: k,
                                label: settings.dxTransformer.options[k],
                            };
                        });
                        this.dxTransformerDefault =
                            settings.dxTransformer.defaultKey == ''
                                ? this.dxTransformerOptions[0].key
                                : settings.dxTransformer.defaultKey;
                        this.setDxTransformer();
                    }

                    // Set vault and default vault
                    if (settings.meter && Object.keys(settings.meter?.options)) {
                        this.meterOptions = Object.keys(settings.meter.options).map((k) => {
                            return {
                                key: k,
                                label: settings.meter.options[k],
                            };
                        });
                        this.meterDefault =
                            settings.meter.defaultKey == '' ? this.meterOptions[0].key : settings.meter.defaultKey;
                        this.setMeter();
                    }
                    this.dataReady.next(true);
                });
        });
    }

    /*
     * Getters for options and defaults
     */
    public getScenarioOptions(): {key: string; label: string}[] {
        return this.scenarioOptions;
    }
    public getSeasonOptions(): {key: string; label: string}[] {
        return this.seasonOptions;
    }
    public getSeasonTimingOptions(): {key: string; label: string}[] {
        return this.seasonTimingOptions;
    }
    public getSubsystemOptions(): {key: string; label: string}[] {
        return this.subsystemOptions;
    }
    public getStationOptions(): {key: string; label: string}[] {
        return this.stationOptions;
    }
    public searchStationOptions(station: string): {key: string; label: string}[] {
        if (!station) {
            return this.getStationOptions().slice(0, 10);
        }
        return this.getStationOptions()
            .filter((s) => s.label.toLowerCase().includes(station.toLowerCase()))
            .slice(0, 10);
    }
    public getFeederOptions(): {key: string; label: string}[] {
        return this.feederOptions;
    }
    public searchFeederOptions(feeder: string): {key: string; label: string}[] {
        if (!feeder) {
            return this.getFeederOptions().slice(0, 10);
        }
        return this.getFeederOptions()
            .filter((f) => f.label.toLowerCase().includes(feeder.toLowerCase()))
            .slice(0, 10);
    }
    public getVaultOptions(): {key: string; label: string}[] {
        return this.vaultOptions;
    }
    public searchVaultOptions(vault: string): {key: string; label: string}[] {
        if (!vault) {
            return this.getVaultOptions().slice(0, 10);
        }
        return this.getVaultOptions()
            .filter((v) => v.label.toLowerCase().includes(vault.toLowerCase()))
            .slice(0, 10);
    }
    public getLineSectionOptions(): {key: string; label: string}[] {
        return this.lineSectionOptions;
    }
    public searchLineSectionOptions(lineSection: string): {key: string; label: string}[] {
        if (!lineSection) {
            return this.getLineSectionOptions().slice(0, 10);
        }
        return this.getLineSectionOptions()
            .filter((v) => v.label.toLowerCase().includes(lineSection.toLowerCase()))
            .slice(0, 10);
    }
    public getDxTransformerOptions(): {key: string; label: string}[] {
        return this.dxTransformerOptions;
    }
    public searchDxTransformerOptions(dxTransformer: string): {key: string; label: string}[] {
        if (!dxTransformer) {
            return this.getDxTransformerOptions().slice(0, 10);
        }
        return this.getDxTransformerOptions()
            .filter((v) => v.label.toLowerCase().includes(dxTransformer.toLowerCase()))
            .slice(0, 10);
    }
    public getMeterOptions(): {key: string; label: string}[] {
        return this.meterOptions;
    }
    public searchMeterOptions(meter: string): {key: string; label: string}[] {
        if (!meter) {
            return this.getMeterOptions().slice(0, 10);
        }
        return this.getMeterOptions()
            .filter((v) => v.label.toLowerCase().includes(meter.toLowerCase()))
            .slice(0, 10);
    }
    public getDemandFactorsOptions(): {key: string; label: string}[] {
        return this.demandFactorsOptions;
    }
    public getPivotDataOptions(): {key: string; label: string}[] {
        return this.pivotDataOptions;
    }
    public getViewByOptions(): {key: string; label: string}[] {
        return this.viewByOptions;
    }

    /*
     * Getters for defaults
     */
    public getScenarioDefault(): string {
        return this.scenarioDefault;
    }
    public getSeasonDefault(): string {
        return this.seasonDefault;
    }
    public getSeasonTimingDefault(): string {
        return this.seasonTimingDefault;
    }
    public getSubsystemDefault(): string {
        return this.subsystemDefault;
    }
    public getStationDefault(): string {
        return this.stationDefault;
    }
    public getFeederDefault(): string {
        return this.feederDefault;
    }
    public getVaultDefault(): string {
        return this.vaultDefault;
    }
    public getLineSectionDefault(): string {
        return this.lineSectionDefault;
    }
    public getDxTransformerDefault(): string {
        return this.dxTransformerDefault;
    }
    public getMeterDefault(): string {
        return this.meterDefault;
    }
    public getDemandFactorsDefault(): string[] {
        return this.demandFactorsDefault;
    }
    public getPivotDataDefault(): string {
        return this.pivotDataDefault;
    }
    public getViewByDefault(): string {
        return this.viewByDefault;
    }
    public getPeriodDefault(): PeriodFilter {
        return this.periodDefault;
    }

    /*
     * Getters and setters for active values
     */
    public setScenario(value = this.scenarioDefault) {
        if (value && this.getScenario() !== value) {
            this.scenario$.next(value);
        }
    }
    public setSeason(value = this.seasonDefault) {
        if (value && this.getSeason() !== value) {
            this.season$.next(value);

            if (value === 'All') {
                this.seasonForApi$.next(
                    this.getSeasonOptions()
                        .filter((o) => o.key !== 'All')
                        .map((o) => o.key),
                );
            } else {
                this.seasonForApi$.next([value]);
            }
        }
    }
    public setSeasonTiming(value = this.seasonTimingDefault) {
        if (value && this.getSeasonTiming() !== value) {
            this.seasonTiming$.next(value);
        }
    }
    public setSubsystem(value = this.subsystemDefault) {
        if (value && this.getSubsystem() !== value) {
            this.subsystem$.next(value);
        }
    }
    public setStation(value = this.stationDefault) {
        if (value && this.getStation() !== value) {
            this.station$.next(value);
        }
    }
    public setFeeder(value = this.feederDefault) {
        if (value && this.getFeeder() !== value) {
            this.feeder$.next(value);
        }
    }
    public setVault(value = this.vaultDefault) {
        if (value && this.getVault() !== value) {
            this.vault$.next(value);
        }
    }
    public setLineSection(value = this.lineSectionDefault) {
        if (value && this.getLineSection() !== value) {
            this.lineSection$.next(value);
        }
    }
    public setDxTransformer(value = this.dxTransformerDefault) {
        if (value && this.getDxTransformer() !== value) {
            this.dxTransformer$.next(value);
        }
    }
    public setMeter(value = this.meterDefault) {
        if (value && this.getMeter() !== value) {
            this.meter$.next(value);
        }
    }
    public setDemandFactors(value = this.demandFactorsDefault) {
        if (value && this.getDemandFactors() !== value) {
            this.demandFactors$.next(value);
        }
    }
    public setPivotData(value = this.pivotDataDefault) {
        if (value && this.getPivotData() !== value) {
            this.pivotData$.next(value);
        }
    }
    public setViewBy(value = this.viewByDefault) {
        if (value && this.getViewBy() !== value) {
            this.viewBy$.next(value);
        }
    }
    public setPeriod(value?: PeriodFilter) {
        if (!value) {
            this.period$.next({
                min: this.periodDefault?.min || '',
                max: this.periodDefault?.max || '',
                current: this.periodDefault?.current || String(new Date().getFullYear()),
            });
        } else {
            const patchValue: PeriodFilter = {
                ...value,
                current: this.periodDefault?.current || String(new Date().getFullYear()),
            };
            this.period$.next(patchValue);
        }
    }

    public getScenario(): string {
        return this.scenario$.getValue();
    }
    public getSeason(): string {
        return this.season$.getValue();
    }
    public getSeasonTiming(): string {
        return this.seasonTiming$.getValue();
    }
    public getSubsystem(): string {
        return this.subsystem$.getValue();
    }
    public getStation(): string {
        return this.station$.getValue();
    }
    public getFeeder(): string {
        return this.feeder$.getValue();
    }
    public getVault(): string {
        return this.vault$.getValue();
    }
    public getLineSection(): string {
        return this.lineSection$.getValue();
    }
    public getDxTransformer(): string {
        return this.dxTransformer$.getValue();
    }
    public getMeter(): string {
        return this.meter$.getValue();
    }
    public getDemandFactors(): string[] {
        return this.demandFactors$.getValue();
    }
    public getPivotData(): string {
        return this.pivotData$.getValue();
    }
    public getViewBy(): string {
        return this.viewBy$.getValue();
    }
    public getPeriod(): PeriodFilter {
        return this.period$.getValue();
    }
}
