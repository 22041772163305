import {v4 as uuidv4} from 'uuid';
import {DataDetails, GeospatialResponse, SpatialEntityData} from '../../geospatial-viewer-old/model/api';
import {
    MeasureTypeLabel,
    MetricCategoryTypeCategory,
    MetricCategoryTypeNumeric,
    MetricTypeLabel,
    ValueType,
} from '../model/metric';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, filter, share, shareReplay, take, takeUntil} from 'rxjs/operators';
import {FilterControlsStore} from '../control-panel/components/filter/filter.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {GeospatialFilterStore} from '../api/geospatial-filter.store';
import {ProjectsFilterStore} from '../control-panel/components/projects-tab/project-filter.store';
import {UsersStore} from '@store/common/users.store';
import {StudiesStore} from '@store/common/studies.store';
import {isEqual} from 'lodash';
import {CustomMapLayer} from '../model/layers';
import {VisualizationType} from '../model/visualization';

export enum LayerTypes {
    SYSTEM = 'system',
    PROJECT = 'project',
    METRIC = 'metric',
    CUSTOM = 'custom',
}

export abstract class Layer extends Unsubscribable {
    mapDataDetails: DataDetails;
    mapData: SpatialEntityData[] = [];
    id: string;

    dataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataLoading$: Observable<boolean> = this.dataLoading.asObservable().pipe(share(), shareReplay(1));

    refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    refresh$: Observable<boolean> = this.refresh.asObservable().pipe(share());

    constructor(
        public name: string,
        public type: string,
        public visible: boolean = true,
        public expanded: boolean = true,
    ) {
        super();

        this.id = uuidv4(); // Generate a unique ID when a layer is created
    }

    /** Toggle layer visibility, which is available to users */
    toggleVisibility(): void {
        this.visible = !this.visible;
    }

    /** Update map data source, used as part of data refresh */
    updateMapDataSource(mapDataSource: GeospatialResponse) {
        this.mapDataDetails = mapDataSource.dataDetails;
        this.mapData = mapDataSource.data;
    }

    /** Empty the data from the layer, but retain other details (e.g., visibility, mapDataDetails */
    clearMapDataSource() {
        this.mapData = [];
    }

    /** Update layer name, displayed to user */
    updateName(newName: string) {
        this.name = newName;
    }

    /** Update loading status of the layer */
    setLoading(value: boolean) {
        this.dataLoading.next(value);
    }

    /** Toggle layer expand/collapse status, in control panel? */
    toggleLayer(): void {
        this.expanded = !this.expanded;
    }

    /** Collapse layer controls, in control panel? */
    closeLayer(): void {
        this.expanded = false;
    }
}

export class SystemLayer extends Layer {
    constructor(public visible: boolean = true, public expanded: boolean = false) {
        super('System Layer', LayerTypes.SYSTEM, visible, expanded);
    }
}

export class ProjectLayer extends Layer {
    filtersStore: ProjectsFilterStore;

    constructor(private fb: FormBuilder, private userStore: UsersStore, private studiesStore: StudiesStore) {
        super('Projects Layer', LayerTypes.PROJECT);

        this.filtersStore = new ProjectsFilterStore(fb, userStore, studiesStore);
    }
}

export interface MetricGroup {
    categoriesList?: string[];
    min?: number;
    max?: number;
}

export class MetricLayer extends Layer {
    metrics: MetricGroup;

    layerForm: FormGroup;
    filterControlsStore: FilterControlsStore;

    constructor(private fb: FormBuilder, private geospatialFilterStore: GeospatialFilterStore) {
        super(`New Layer`, LayerTypes.METRIC);
        this.metrics = {
            min: null,
            max: null,
            categoriesList: null,
        };

        // Create a new FilterControlsStore instance
        this.filterControlsStore = new FilterControlsStore(fb, geospatialFilterStore);

        // Initialize the form
        this.initForm();

        // Listen for changes in the component's metricLayerForm and store filters
        this.listenToFormChanges();
    }

    private initForm() {
        this.layerForm = this.fb.group({
            visible: [true],
            name: [this.name, Validators.required],
            measure: [null, Validators.required],
            metric: [{value: null, disabled: true}, Validators.required],
            categoriesSelected: [null],
            visualizationType: [null, Validators.required],
            renderedVisualizationType: [null],
        });

        // Merge with FilterControlsStore's formGroup (filters)
        // Check if the filters form group is ready before adding it
        const filtersFormGroup = this.filterControlsStore.filtersFormGroup;

        // Add the filters form group only if it exists
        if (filtersFormGroup) {
            this.layerForm.addControl('filters', filtersFormGroup);
        } else {
            // If filtersFormGroup is not ready yet, wait until it is initialized
            this.filterControlsStore.filtersFormGroupChanged$
                .pipe(
                    filter((initialised) => !!initialised),
                    take(1),
                )
                .subscribe(() => {
                    this.layerForm.addControl('filters', this.filterControlsStore.filtersFormGroup, {
                        emitEvent: false,
                    });
                });
        }
    }

    updateName(newName: string) {
        this.name = newName;
        this.layerForm.get('name').setValue(newName);
    }

    toggleVisibility(): void {
        this.visible = !this.visible;
        this.layerForm.get('visible').setValue(this.visible);
    }

    private listenToFormChanges() {
        this.layerForm
            .get('measure')
            .valueChanges.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe((measure) => {
                this.updateName(this.configureMetricLayerName(measure));

                //reset metric and enable/disable
                if (measure) {
                    this.layerForm.get('metric').enable();
                } else this.layerForm.get('metric').disable();
                this.layerForm.get('metric').reset(null, {emitEvent: false});

                //Full reset categories
                this.resetCategories();
            });

        this.layerForm
            .get('metric')
            .valueChanges.pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((value) => !!value),
            )
            .subscribe((metric) => {
                this.updateName(this.configureMetricLayerName(this.layerForm.value.measure, metric));

                //reset category filter to default for this valueType
                this.resetCategories(metric.valueType);
            });
    }

    public configureMetricLayerName(measure, metric?) {
        if (!measure) return 'New Layer';
        if (!metric) return MeasureTypeLabel[measure?.code];
        return `${MeasureTypeLabel[measure?.code]}: ${MetricTypeLabel[metric.code]}`;
    }

    getFilters() {
        return this.filterControlsStore.currentFilters;
    }

    getMeasure() {
        return this.layerForm.value.measure?.code;
    }

    getMetric() {
        return this.layerForm.value.metric?.code;
    }

    getValueType() {
        return this.layerForm.value.metric?.valueType;
    }

    getVisualizationType() {
        return this.layerForm.value.visualizationType.value;
    }

    getRenderedVisualizationType() {
        return this.layerForm.value?.renderedVisualizationType || this.getVisualizationType();
    }

    setDataDetails(dataDetails: DataDetails) {
        if (this.metrics.categoriesList && !isEqual(this.metrics.categoriesList, dataDetails.categoryList))
            this.resetCategories();

        this.metrics.categoriesList = dataDetails.categoryList;
        this.metrics.min = dataDetails.min;
        this.metrics.max = dataDetails.max;

        this.refresh.next(true);
    }

    resetCategories(valueType?: ValueType) {
        this.metrics.categoriesList = null;
        this.metrics.min = null;
        this.metrics.max = null;
        this.updateCategoriesSelected(valueType);

        this.refresh.next(true);
    }

    updateCategoriesSelected(
        valueType?: ValueType,
        categoriesSelected?: MetricCategoryTypeCategory | MetricCategoryTypeNumeric,
    ) {
        if (!valueType) {
            this.layerForm.get('categoriesSelected').setValue(null);
        } else if (valueType === ValueType.NUMERIC) {
            this.layerForm.get('categoriesSelected').setValue(
                categoriesSelected || {
                    numberMin: this.metrics.min,
                    numberMax: this.metrics.max,
                },
            );
        } else {
            this.layerForm.get('categoriesSelected').setValue(this.metrics.categoriesList ? categoriesSelected : null);
        }
    }

    getCategories(
        categoriesSelected = this.layerForm.value.categoriesSelected,
    ): MetricCategoryTypeCategory | MetricCategoryTypeNumeric {
        if (this.layerForm.value.metric.valueType === ValueType.NUMERIC) {
            return categoriesSelected
                ? {
                      numberMin: categoriesSelected.numberMin,
                      numberMax: categoriesSelected.numberMax,
                  }
                : {
                      numberMin: this.metrics.min,
                      numberMax: this.metrics.max,
                  };
        } else if (!categoriesSelected || categoriesSelected.length === this.metrics.categoriesList?.length)
            return {stringFilter: ['ALL']};
        return {stringFilter: categoriesSelected};
    }

    showCategorySection() {
        return this.metrics.categoriesList?.length || (this.metrics.min !== null && this.metrics.max !== null);
    }

    updateRenderedVisualizationType(visualizationType: VisualizationType) {
        this.layerForm.get('renderedVisualizationType').setValue(visualizationType, {emitEvent: false});
    }
}

export class CustomLayer extends Layer {
    private config: CustomMapLayer;

    constructor(private customLayerConfig: CustomMapLayer, public expanded: boolean = false) {
        super(customLayerConfig.label, LayerTypes.CUSTOM, !customLayerConfig.disabled, expanded);
        this.config = customLayerConfig;
    }

    /** Toggle layer expand/collapse status, in control panel? */
    toggleLayer(): void {
        this.expanded = !this.expanded;
    }

    getDescription(): string {
        return this.config.description;
    }
}
