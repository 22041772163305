import {FormatsService} from './formats.service';
import {ColorsService} from './colors.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AssetHealthIndex} from '../interfaces/engin/assets';
import {AnalyzerResponse} from '../interfaces/engin/analyzer';
import {FontsService} from './fonts.service';
import {Injectable} from '@angular/core';
import {SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {AssetDetailsStore} from '@store/analyzer/asset-details.store';

export interface Chart {
    options: any;
    colorsMap: any[];
    metadata?: any;
}

@Injectable()
export class ChartsService {
    private colors = this.colorsService.colorsMapShared$;

    public getChartFooterIconsMap(name: string): any {
        return [
            {
                name: ['Past Economic EOL', 'Past TUL'],
                icon: 'alert-circle',
                iconContainerClass: 'color-gradient-category-5',
            },
            {
                name: ['Approaching Economic EOL', 'Approaching TUL'],
                icon: 'alert-triangle',
                iconContainerClass: 'color-gradient-category-4',
            },
            {
                name: ['Not Near EOL', 'Not Near TUL'],
                icon: 'checkmark-circle-2',
                iconContainerClass: 'color-gradient-category-1',
            },
            {
                name: ['N/A'],
                icon: '',
                iconContainerClass: 'chart-color-none empty-icon',
            },
            {
                name: ['Very Poor', 'High Risk'],
                icon: '',
                iconContainerClass: 'color-gradient-category-5 empty-icon',
            },
            {
                name: ['Poor'],
                icon: '',
                iconContainerClass: 'color-gradient-category-4 empty-icon',
            },
            {
                name: ['Fair', 'Moderate Risk'],
                icon: '',
                iconContainerClass: 'color-gradient-category-3 empty-icon',
            },
            {
                name: ['Good'],
                icon: '',
                iconContainerClass: 'color-gradient-category-2 empty-icon',
            },
            {
                name: ['Very Good', 'Low Risk'],
                icon: '',
                iconContainerClass: 'color-gradient-category-1 empty-icon',
            },
            {
                name: ['Total'],
                icon: '',
                iconContainerClass: 'chart-color-empty empty-icon',
            },
            {
                name: ['Numeric-1'],
                icon: '',
                iconContainerClass: 'color-gradient-numeric-1 empty-icon',
            },
            {
                name: ['Numeric-2'],
                icon: '',
                iconContainerClass: 'color-gradient-numeric-2 empty-icon',
            },
            {
                name: ['Numeric-3'],
                icon: '',
                iconContainerClass: 'color-gradient-numeric-3 empty-icon',
            },
            {
                name: ['Numeric-4'],
                icon: '',
                iconContainerClass: 'color-gradient-numeric-4 empty-icon',
            },
            {
                name: ['Numeric-5'],
                icon: '',
                iconContainerClass: 'color-gradient-numeric-5 empty-icon',
                //iconContainerClass: 'chart-color-10 empty-icon',
            },
        ].find((item) => item.name.includes(name));
    }

    readonly donutChartWithHighlight$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            const numberSize = this.fontsService.pxToRem(window.innerWidth, 'numberSize');
            const fontSize = this.fontsService.pxToRem(window.innerWidth, 'fontSize');
            const titleFontSize = this.fontsService.pxToRem(window.innerWidth, 'titleFontSize');
            const subTitleFontSize = this.fontsService.pxToRem(window.innerWidth, 'subTitleFontSize');
            return {
                options: {
                    title: {
                        x: 'center',
                        y: 'top',
                        itemGap: 5,
                        textStyle: {
                            fontSize: titleFontSize,
                            color: colors['primaryFontColor'],
                        },
                        subtextStyle: {
                            fontSize: subTitleFontSize,
                            color: colors['hintFontColor'],
                        },
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: (params) => {
                            return `${params.data.name}: ${params.data.formattedValue}`;
                        },
                        backgroundColor: colors['tooltipBg'],
                        textStyle: {
                            fontSize: 10,
                            color: colors['primaryFontColor'],
                        },
                        // old style:
                        // textStyle: {
                        //     fontSize: 11,
                        //     color: "white",
                        // },
                        // borderColor: "transparent",
                        // backgroundColor: "rgba(0,0,0,0.7)"
                    },
                    series: [
                        {
                            name: 'data',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: true,
                            label: {
                                position: 'inner',
                                color: 'white',
                                formatter: function (params) {
                                    return (params.percent - 0).toFixed(0) + '%';
                                },
                            },
                            labelLine: {
                                show: false,
                            },
                        },
                        {
                            name: 'highLightedTop',
                            type: 'pie',
                            radius: ['70%', '70%'],
                            tooltip: {
                                show: false,
                            },
                            label: {
                                show: true,
                                position: 'center',
                                formatter: function (params) {
                                    return params.value;
                                },
                                textStyle: {
                                    fontSize: numberSize,
                                    fontWeight: 'bold',
                                    color: colors['primaryFontColor'],
                                },
                            },
                            labelLine: {
                                show: false,
                            },
                            data: [],
                        },
                        {
                            name: 'highLightedBottom',
                            type: 'pie',
                            radius: ['70%', '70%'],
                            tooltip: {
                                show: false,
                            },
                            label: {
                                show: true,
                                position: 'center',
                                formatter: function (params) {
                                    return `\n\n\n${params.value}`;
                                },
                                textStyle: {
                                    fontSize: fontSize,
                                    color: colors['hintFontColor'],
                                },
                            },
                            labelLine: {
                                show: false,
                            },
                            data: [],
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly barChartPercentStacked$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: (params) => {
                            return `${params.data.name}: ${params.data.formattedValue}`;
                        },
                        backgroundColor: colors['tooltipBg'],
                        textStyle: {
                            fontSize: 16,
                            color: colors['primaryFontColor'],
                        },
                    },
                    legend: {
                        y: 'bottom',
                        textStyle: {
                            color: colors['primaryFontColor'],
                        },
                        itemWidth: 20,
                        itemHeight: 20,
                    },
                    xAxis: [
                        {
                            type: 'value',
                            show: false,
                            max: 100,
                        },
                    ],
                    yAxis: [
                        {
                            type: 'category',
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly colChartStacked$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        formatter: (params) => {
                            const colorSpan = (color) =>
                                `<span style="display: inline-block;
              margin-right: 5px; border-radius: 10px; width: 9px; height: 9px; background-color:${color}"></span>`;

                            let retStr = `<b>${params[0].axisId.slice(0, params[0].axisId.length - 1)}: ${
                                params[0].axisValue
                            }</b><br/>`;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].data.value) {
                                    retStr += `${colorSpan(params[i].color)} ${params[i].data.name}: ${
                                        params[i].data.formattedValue
                                    }<br/>`;
                                }
                            }
                            return retStr;
                        },
                        textStyle: {
                            fontSize: 16,
                            color: colors['primaryFontColor'],
                        },
                        backgroundColor: colors['tooltipBg'],
                    },
                    legend: {
                        y: 'bottom',
                        textStyle: {
                            fontSize: 16,
                            color: colors['primaryFontColor'],
                        },
                        itemWidth: 20,
                        itemHeight: 20,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: {
                                interval: 0,
                            },
                            textStyle: {
                                fontSize: 12,
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                interval: 0,
                            },
                            textStyle: {
                                fontSize: 12,
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly lineChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        left: 70,
                        right: 20,
                        top: 10,
                        bottom: 50,
                    },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: colors['tooltipBg'],
                        borderWidth: 1,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                        showDelay: 0,
                        axisPointer: {
                            type: 'line',
                            axis: 'x',
                            lineStyle: {
                                color: colors['hintFontColor'],
                                width: 1,
                                type: 'solid',
                            },
                        },
                    },
                    xAxis: [
                        {
                            nameLocation: 'center',
                            nameGap: 30,
                            type: 'category',
                            splitLine: {
                                show: false,
                            },
                            splitArea: {
                                show: false,
                                lineStyle: {
                                    color: colors['axisLine'],
                                },
                            },
                            axisTick: {
                                show: true,
                                alignWithLabel: true,
                            },
                            nameTextStyle: {
                                fontWeight: '400',
                                color: colors['axisNameText'],
                            },
                            axisLabel: {
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 2,
                                    color: colors['hintFontColor'],
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            nameTextStyle: {
                                fontWeight: '400',
                                color: colors['axisNameText'],
                            },
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}%',
                                fontWeight: 600,
                                color: colors['primaryFontColor'],
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                                margin: 15,
                            },
                            splitArea: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 0,
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            type: 'line',
                            symbol: 'circle',
                            symbolSize: 0,
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    /*
     * Failure Curve tool
     */
    readonly multiSeriesLineChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                colorsMap: colors,
                options: {
                    title: {
                        x: 'center',
                        y: 'top',
                        textStyle: {
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors['primaryFontColor'],
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            axis: 'x',
                            lineStyle: {
                                color: colors['hintFontColor'],
                                width: 1,
                                type: 'solid',
                            },
                        },
                        backgroundColor: colors['tooltipBg'],
                        textStyle: {
                            color: colors['primaryFontColor'],
                        },
                    },
                    xAxis: {
                        nameLocation: 'middle',
                        type: 'category',
                        nameTextStyle: {
                            padding: [20, 0, 0, 0],
                            align: 'center',
                            color: colors['hintFontColor'],
                        },
                        axisLabel: {
                            interval: 9,
                            textStyle: {
                                color: colors['primaryFontColor'],
                            },
                            color: colors['primaryFontColor'],
                        },
                        splitLine: {
                            show: true,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                colors: colors['hintFontColor'],
                            },
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '50',
                        containLabel: true,
                    },
                    yAxis: {
                        type: 'value',
                        nameLocation: 'end',
                        nameTextStyle: {
                            padding: [10, 0, 0, 0],
                            align: 'center',
                            color: colors['hintFontColor'],
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: colors['primaryFontColor'],
                                fontWeight: 'bold',
                            },
                            color: colors['primaryFontColor'],
                        },
                        splitLine: {
                            show: true,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                colors: colors['hintFontColor'],
                            },
                        },
                        axisPointer: {
                            show: true,
                            label: {
                                show: false,
                            },
                        },
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
            };
        }),
    );

    readonly barChartClusteredDrilldown$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: (params) => {
                            return `${params.data.name}: ${params.data.formattedValue}`;
                        },
                        backgroundColor: colors['tooltipBg'],
                        textStyle: {
                            fontSize: 16,
                            color: colors['primaryFontColor'],
                        },
                    },
                    legend: {
                        y: 'bottom',
                        textStyle: {
                            color: colors['primaryFontColor'],
                        },
                        itemWidth: 20,
                        itemHeight: 20,
                    },
                    xAxis: [
                        {
                            triggerEvent: true,
                            type: 'category',
                            nameLocation: 'center',
                            nameGap: 30,
                            nameTextStyle: {
                                color: colors['hintFontColor'],
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 2,
                                    color: colors['hintFontColor'],
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            nameGap: 20,
                            nameTextStyle: {
                                fontSize: 13,
                                fontWeight: 400,
                                color: colors['hintFontColor'],
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                                color: colors['primaryFontColor'],
                                fontWeight: 600,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 0,
                                },
                            },
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly overlapBarChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        right: 50,
                        left: 50,
                        bottom: 120,
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    /*
     * Data audit
     */
    readonly lineChartMultiSeriesHorizontal$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        position: 'top',
                        backgroundColor: colors['tooltipBg'],
                        borderWidth: 0,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                    },
                    grid: {
                        // left: 45,
                        x: 0,
                        y: 35,
                        x1: 0,
                        y2: 0,
                        // width: '90%',
                        containLabel: true,
                    },
                    legend: {
                        show: false,
                        selected: {},
                    },
                    xAxis: {
                        type: 'category',
                        nameLocation: 'center',
                        nameTextStyle: {padding: [10, 0, 0, 0]},
                        boundaryGap: false,
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    yAxis: [
                        {
                            type: 'value',
                            nameTextStyle: {padding: [0, 0, 0, 75]},
                            min: 0,
                            max: 100,
                            interval: 10,
                            position: 'left',
                            splitLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: colors['hintFontColor'],
                                    width: 0.25,
                                },
                            },
                            axisLabel: {
                                formatter: (params) => {
                                    return `${params}%`;
                                },
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: colors['hintFontColor'],
                                    width: 0.25,
                                },
                            },
                            axisTick: {show: false},
                        },
                        {
                            type: 'value',
                            nameTextStyle: {padding: [0, 75, 0, 0]},
                            position: 'right',
                            // interval: 1000,
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                formatter: (params) => {
                                    return `${params}`;
                                },
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: colors['hintFontColor'],
                                    width: 0.25,
                                },
                            },
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    /*
     * Evaluation data audit
     */
    readonly scatterplotTimeseries$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        left: '5%',
                        right: '7%',
                        bottom: '15%',
                        containLabel: true,
                    },
                    tooltip: {
                        showDelay: 0,
                        axisPointer: {
                            show: true,
                            snap: false,
                            animation: false,
                            type: 'cross',
                            lineStyle: {
                                type: 'dashed',
                                width: 1,
                            },
                            label: {
                                backgroundColor: colors['axisPointerBackground'],
                            },
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataZoom: [
                        {
                            startValue: 10,
                        },
                        {
                            type: 'inside',
                        },
                        {
                            endValue: 30,
                        },
                    ],
                    xAxis: [
                        {
                            nameTextStyle: {
                                color: colors['hintFontColor'], // '#8992A3',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: 16,
                                padding: [6, 0, 0, 0],
                            },
                            nameLocation: 'center',
                            type: 'category',
                            scale: true,
                            axisLabel: {
                                color: colors['primaryFontColor'], //todo: hook to dark-mode toggle
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 10,
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: '#F7F9FC',
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#DDE1EB',
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            nameTextStyle: {
                                color: colors['hintFontColor'], // '#8992A3'
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                            },
                            type: 'value',
                            scale: true,
                            axisLabel: {
                                formatter: '{value}',
                                show: true,
                                color: colors['primaryFontColor'], // '#0D1C2E'
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 12,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#DDE1EB',
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly barChartClustered$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        backgroundColor: colors['tooltipBg'],
                    },
                    legend: {
                        show: false,
                        selected: {},
                    },
                    grid: {
                        x: 35,
                        y: 35,
                        x1: 35,
                        y2: 35,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'value',
                        nameLocation: 'center',
                        nameTextStyle: {padding: [10, 0, 0, 0]},
                        min: 0,
                        max: 100,
                        interval: 10,
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisLabel: {
                            formatter: (params) => {
                                return `${params}%`;
                            },
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    yAxis: {
                        type: 'category',
                        nameTextStyle: {padding: [0, 0, 0, 45]},
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly colChartSimple$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        backgroundColor: colors['tooltipBg'],
                    },
                    legend: {
                        show: false,
                    },
                    grid: {
                        x: 35,
                        y: 35,
                        x1: 35,
                        y2: 35,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        nameLocation: 'center',
                        nameTextStyle: {padding: [10, 0, 0, 0]},
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisTick: {show: false},
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly lineChartMiniTrend$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        position: 'top',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                        backgroundColor: colors['tooltipBg'],
                    },
                    grid: {
                        x: 15,
                        y: 15,
                        x1: 15,
                        y2: 15,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly scatterChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    title: {
                        x: 'center',
                        y: 'top',
                        textStyle: {
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors['primaryFontColor'],
                        },
                    },
                    grid: {
                        top: '10%',
                        left: '0',
                        right: '4%',
                        bottom: '25%',
                        containLabel: true,
                    },
                    tooltip: {
                        backgroundColor: colors['backgroundBasicColor1'],
                        borderWidth: 1,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            color: colors['primaryFontColor'],
                        },
                        showDelay: 0,
                    },
                    xAxis: {
                        nameLocation: 'middle',
                        nameGap: 25,
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            textStyle: {
                                color: colors['primaryFontColor'],
                            },
                            color: colors['primaryFontColor'],
                        },
                        scale: true,
                        splitLine: {
                            show: true,
                        },
                        boundaryGap: false,
                    },
                    yAxis: {
                        nameLocation: 'end',
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                            padding: [0, 0, 0, 2],
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: colors['primaryFontColor'],
                                fontWeight: 'bold',
                            },
                            color: colors['primaryFontColor'],
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: colors['axisLine'],
                            },
                        },
                        boundaryGap: false,
                    },
                    series: [
                        {
                            type: 'scatter',
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly denseTimeseriesLine$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        top: '11%',
                        left: '3%',
                        bottom: '15%',
                        right: '13%',
                        containLabel: true,
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            filterMode: 'weakFilter',
                            showDataShadow: false,
                            bottom: '3%',
                            labelFormatter: '',
                            start: 0,
                            end: 100,
                            height: 18,
                        },
                        {
                            type: 'inside',
                            filterMode: 'weakFilter',
                            start: 0,
                            end: 100,
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: colors['tooltipBg'],
                        borderWidth: 1,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors['primaryFontColor'],
                        },
                        showDelay: 50,
                        axisPointer: {
                            type: 'line',
                            axis: 'x',
                            lineStyle: {
                                color: colors['hintFontColor'],
                                width: 1,
                                type: 'solid',
                            },
                        },
                        formatter: function (params) {
                            let tooltip = `Time: <b>${params[0].axisValueLabel}</b><br/>`;
                            params.forEach((p) => {
                                tooltip =
                                    tooltip +
                                    `${p.marker} ${p.seriesName}: <b>${FormatsService.prepareValue(
                                        p.value[1],
                                        '',
                                        '',
                                    )}</b><br/>`;
                            });
                            return tooltip;
                        },
                    },
                    xAxis: {
                        type: 'time',
                        name: 'Time',
                        nameLocation: 'middle',
                        nameGap: 25,
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        boundaryGap: true,
                        scale: true,
                    },
                    yAxis: {
                        type: 'value',
                        alignTicks: true,
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors['axisLine'],
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: colors['axisLine'],
                            },
                        },
                        /*                            splitArea: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },*/
                    },
                    series: [],
                    legend: {
                        show: false,
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    // Empty / default force graph settings
    readonly nodeScale: number = 4.0;
    readonly forceGraphEmpty$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    center: [0, 0],
                    tooltip: {
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                        borderColor: colors['tooltipBg'],
                        backgroundColor: colors['tooltipBg'],
                    },
                    label: {
                        show: false,
                    },
                    series: [
                        {
                            type: 'graph',
                            layout: 'force',
                            roam: true,
                            nodeScaleRatio: 0.8,
                            selectedMode: 'single',
                            select: {
                                width: 20,
                                label: {
                                    show: true,
                                    fontSize: 30,
                                },
                            },
                            label: {
                                show: false,
                                position: 'top',
                                distance: 10,
                                backgroundColor: colors['tooltipBg'],
                                borderWidth: 1,
                            },
                            force: {
                                repulsion: 100,
                                gravity: 0.000005,
                                friction: 1,
                                edgeLength: 50,
                            },
                            draggable: true,
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
                metadata: {
                    lineColor: colors['feederProfile'].lines,
                    assetCategories: [
                        {
                            name: 'Transformer',
                            symbol: 'triangle',
                            itemStyle: {
                                color: colors['feederProfile'].transformer,
                            },
                            // This option can't rotate the legend,
                            // I suspect a echarts bug (they've had rotation bugs in the past)
                            //symbolRotate: 180,
                            symbolSize: 5 * this.nodeScale,
                        },
                        {
                            name: 'Switch',
                            symbol: 'diamond',
                            itemStyle: {
                                color: colors['feederProfile'].switch,
                            },
                            symbolSize: 5 * this.nodeScale,
                        },
                        {
                            name: 'Fuse',
                            legendSymbol: 'star',
                            symbol: 'path://M,152.000,172.000L162.353,190.637L,162.000,169.321L,180.284,180.284L,169.321,162.000L,190.637,162.353L,172.000,152.000L,190.637,141.647L,169.321,142.000L,180.284,123.716L,162.000,134.679L,162.353,113.363L,152.000,132.000L,141.647,113.363L,142.000,134.679L,123.716,123.716L,134.679,142.000L,113.363,141.647L,132.000,152.000L,113.363,162.353L,134.679,162.000L,123.716,180.284L,142.000,169.321L,141.647,190.637L,152.000,172.000z',
                            itemStyle: {
                                color: colors['feederProfile'].fuse,
                            },
                            symbolSize: 5 * this.nodeScale,
                            symbolOffset: [0, '-50%'],
                        },
                        {
                            name: 'Recloser',
                            symbol: 'circle',
                            itemStyle: {
                                color: colors['feederProfile'].recloser,
                            },
                            symbolSize: 5 * this.nodeScale,
                        },
                        {
                            name: 'Breaker',
                            legendSymbol: 'square',
                            symbol: 'rect',
                            itemStyle: {
                                color: colors['feederProfile'].black,
                            },
                            symbolSize: 5 * this.nodeScale,
                        },
                        {
                            name: 'Node',
                            symbol: 'circle',
                            itemStyle: {
                                color: colors['feederProfile'].black,
                            },
                            symbolSize: 3 * this.nodeScale,
                        },
                    ].map(function (a) {
                        a['icon'] = a.symbol;
                        if (!a['legendSymbol']) {
                            a['legendSymbol'] = a.symbol;
                        }
                        return a;
                    }),
                },
            };
        }),
    );

    /*
     * Common
     */
    constructor(private colorsService: ColorsService, private fontsService: FontsService) {}

    public getEmptyChartWithMessage(metadata: any, chartTemplate: any, message?: string) {
        const finalMessage = message || 'No chart data to display, try a different set of filters.';
        return {
            metadata: metadata,
            options: {
                title: {
                    x: 'center',
                    text: metadata.name,
                    textStyle: {
                        fontSize: 14,
                        color: chartTemplate.colorsMap['primaryFontColor'],
                    },
                    subtext: finalMessage,
                    subtextStyle: {
                        fontSize: 14,
                        fontWeight: 'normal',
                        color: chartTemplate.colorsMap['hintFontColor'],
                        align: 'center',
                        verticalAlign: 'center',
                    },
                },
            },
        };
    }

    public prepareStackedPercentageChartData(chartData: AnalyzerResponse, colors: any[]): any {
        if (!chartData) {
            return {};
        }
        const values = Array.isArray(chartData.data[0])
            ? this.getMultipleSeriesStackedPercentageValues(chartData, colors)
            : this.getSingleSeriesStackedValues(chartData, colors);

        const highlightedValue = chartData.highlight ? values[chartData.highlight.indexOf(true)] : null;

        return {
            name: chartData.title,
            legend: chartData.legend,
            labels: chartData.labels,
            graphId: chartData.graphId,
            values: values,
            highlightedValue: highlightedValue,
        };
    }

    public prepareDemographicsChartMetadata(chartData: AnalyzerResponse, colors: any[]): any {
        if (!chartData) {
            return {};
        }

        const values = this.getMultipleSeriesStackedValues(chartData, colors);

        return {
            name: chartData.title,
            legend: chartData.legend,
            labels: chartData.labels,
            graphId: chartData.graphId,
            values: values,
        };
    }

    public prepareRiskProfileChartMetadata(
        chartData: SimpleMultiSeries<string>,
        colors: any[],
        title: string,
        subtitle: string,
        legend: any[],
        graphId: string,
    ): any {
        if (!chartData) {
            return {};
        }

        const values = this.getSimpleMultiSeriesStackedValues(chartData, colors);

        return {
            name: title,
            subtitle,
            legend,
            labels: chartData.labels,
            graphId,
            values,
        };
    }

    public prepareDegradationFactorsChartData(assetHealthIndex: AssetHealthIndex, colors: any[]) {
        let res = assetHealthIndex.resultsFinal;

        const degradedLabel = 'Degraded',
            undegradedLabel = 'Undegraded';

        let defaultColorInd = 0;

        if ((!res.dataAvailability || !res.dataValidity) && !res.hi) {
            return {
                healthIndex: 0,
                undegradedValues: [
                    {
                        value: 0,
                        name: undegradedLabel,
                        itemStyle: {
                            normal: {
                                color: colors['default'][colors['default'].length - 1],
                            },
                        },
                    },
                ],
                undegradedTitleColor: colors[undegradedLabel],
                undegradedLabel: undegradedLabel,
                degradedValues: [
                    {
                        name: degradedLabel,
                        value: 0,
                        itemStyle: {
                            normal: {
                                color: colors['default'][colors['default'].length - 1],
                            },
                        },
                    },
                ],
                degradedTitleColor: colors[degradedLabel],
                degradedLabel: degradedLabel,
                degradedIndex: 0,
            };
        }

        const healthIndex = Math.round(assetHealthIndex.resultsFinal.hi); // Round to integer value
        const degradedIndex = 100 - healthIndex;

        return {
            healthIndex: healthIndex,
            undegradedValues: [
                {
                    value: degradedIndex,
                    name: degradedLabel,
                    itemStyle: {
                        normal: {
                            color: colors[degradedLabel],
                        },
                    },
                },
                {
                    value: healthIndex,
                    name: undegradedLabel,
                    itemStyle: {
                        normal: {
                            color: colors[undegradedLabel],
                        },
                    },
                },
            ],
            undegradedTitleColor: colors[undegradedLabel],
            undegradedLabel: undegradedLabel,
            degradedValues: assetHealthIndex.resultsDetail
                .filter((factor) => {
                    // Different filtering based on which way indicates poor / degradaded asset
                    let val;
                    if (assetHealthIndex.resultsFinal.lowScorePoor === 'YES') {
                        val = factor.weightedScoreMax > factor.weightedScore;
                    } else {
                        val = factor.weightedScore > factor.weightedScoreMin;
                    }
                    return factor.dataAvailable && factor.dataValid && val;
                })
                .map((factor, index) => {
                    const colorsArray = colors['colorsArray'];
                    const colorIndex = index % colorsArray.length;
                    let finalVal;
                    if (assetHealthIndex.resultsFinal.lowScorePoor === 'YES') {
                        if (AssetDetailsStore.normalizeMethodology(assetHealthIndex)) {
                            finalVal = 100 * (factor.weightedScoreMax - factor.weightedScore) /
                                (assetHealthIndex.resultsFinal.maxScore - assetHealthIndex.resultsFinal.minScore);
                        } else {
                            finalVal =
                                (100 * factor.degWeight * (factor.numericScoreMax - factor.numericScore)) /
                                (assetHealthIndex.resultsFinal.maxScore - assetHealthIndex.resultsFinal.minScore);
                        }
                    } else {
                        finalVal =
                            (100 * factor.degWeight * (factor.numericScore - factor.numericScoreMin)) /
                            (assetHealthIndex.resultsFinal.maxScore - assetHealthIndex.resultsFinal.minScore);
                    }
                    finalVal = parseFloat(finalVal.toFixed(1)); // Show 1 decimal place if applicable

                    return {
                        name: factor.degFactor,
                        value: finalVal,
                        itemStyle: {
                            normal: {
                                color: colorsArray[colorIndex]
                                    ? colorsArray[colorIndex]
                                    : colors['default'][defaultColorInd++ % colors['default'].length],
                            },
                        },
                    };
                }),
            degradedTitleColor: colors[degradedLabel],
            degradedLabel: degradedLabel,
            degradedIndex: degradedIndex,
        };
    }

    private getSingleSeriesStackedValues(chartData: any, colors: any[]): any[] {
        // If non-N/A data fields are all zeros, display all data records (including N/A) else filter it out
        const nonNaData = chartData.data.filter((item, index) => chartData.labels[index] !== 'N/A');

        if (nonNaData.filter((e) => e > 0).length > 0) {
            // chartData is type AnalyzerResponse with item data: number[][]
            let defaultColorInd = 0;
            return nonNaData.map((item, index) => {
                // Necessary for Health Index labels e.g. "Very Poor (0-30)" -> "Very Poor"
                const code = chartData.labels[index].split(' (')[0];

                return {
                    name: chartData.labels[index],
                    value: item,
                    prefix: chartData.unit.prefix[index],
                    suffix: chartData.unit.suffix[index],
                    formattedValue: `${FormatsService.prepareValue(
                        item,
                        chartData.unit.prefix[index],
                        chartData.unit.suffix[index],
                    )}`,
                    itemStyle: colors
                        ? {
                              color: colors[code]
                                  ? colors[code]
                                  : colors['default'][defaultColorInd++ % colors['default'].length],
                          }
                        : {},
                };
            });
        } else {
            // chartData is type AnalyzerResponse with item data: number[][]
            let defaultColorInd = 0;
            return chartData.data.map((item, index) => {
                // Necessary for Health Index labels e.g. "Very Poor (0-30)" -> "Very Poor"
                const code = chartData.labels[index].split(' (')[0];

                return {
                    name: chartData.labels[index],
                    value: item,
                    prefix: chartData.unit.prefix[index],
                    suffix: chartData.unit.suffix[index],
                    formattedValue: `${FormatsService.prepareValue(
                        item,
                        chartData.unit.prefix[index],
                        chartData.unit.suffix[index],
                    )}`,
                    itemStyle: colors
                        ? {
                              color: colors[code]
                                  ? colors[code]
                                  : colors['default'][defaultColorInd++ % colors['default'].length],
                          }
                        : {},
                };
            });
        }
    }

    private getMultipleSeriesStackedPercentageValues(chartData: any, colors: any[]): any[] {
        // chartData is type AnalyzerResponse with item data: number[][]
        let defaultColorInd = 0;
        return chartData.legend.map((title, index) => {
            // Necessary for Health Index labels e.g. "Very Poor (0-30)" -> "Very Poor"
            const code = title.split(' (')[0];

            // Get percentages
            const percentages = chartData.data
                .map((item) => {
                    const sum = item.reduce((total, val) => total + val);
                    return item.map((val) => {
                        return (val * 100) / sum;
                    });
                })
                .map((item) => item[index]);
            // Format data element
            const values = chartData.data.map((item) => item[index]);
            return {
                name: title,
                value: percentages,
                origValue: values,
                prefix: chartData.unit.prefix[index],
                suffix: chartData.unit.suffix[index],
                formattedValue: values.map(
                    (item, i) =>
                        `${FormatsService.prepareValue(item, chartData.unit.prefix[i], chartData.unit.suffix[i])}`,
                ),
                itemStyle: {
                    color: colors[code]
                        ? colors[code]
                        : colors['default'][defaultColorInd++ % colors['default'].length],
                    label: {
                        zLevel: 2,
                        show: true,
                        position: 'inside',
                        formatter: function (params) {
                            return params.data.value > 0 ? `${Math.round(params.data.value)}%` : '';
                        },
                        textStyle: {
                            fontSize: 14,
                        },
                    },
                },
            };
        });
    }

    private getMultipleSeriesStackedValues(chartData: any, colors: any[]): any[] {
        // chartData is type AnalyzerResponse with item data: number[][]
        let defaultColorInd = 0;
        return chartData.legend.map((title, index) => {
            // Format data element
            const values = chartData.data.map((item) => item[index]);
            return {
                name: title,
                value: values,
                origValue: values,
                prefix: chartData.unit.prefix[index],
                suffix: chartData.unit.suffix[index],
                formattedValue: values.map(
                    (item, i) =>
                        `${FormatsService.prepareValue(item, chartData.unit.prefix[i], chartData.unit.suffix[i])}`,
                ),
                style: {
                    color: colors[title]
                        ? colors[title]
                        : colors['default'][defaultColorInd++ % colors['default'].length],
                },
            };
        });
    }

    getSimpleMultiSeriesStackedValues(chartData: SimpleMultiSeries<string>, colors: any[]): any[] {
        // chartData is type SimpleMultiSeries
        let defaultColorInd = 0;
        return chartData.series.map((data) => {
            // Format data element
            return {
                code: data.code ? data.code : '',
                name: data.legend,
                value: data.data,
                origValue: data.data,
                prefix: data.unit.prefix,
                suffix: data.unit.suffix,
                formattedValue: data.data.map(
                    (item) => `${FormatsService.prepareValue(item, data.unit.prefix, data.unit.suffix)}`,
                ),
                style: {
                    color: colors[data.code]
                        ? colors[data.code]
                        : colors[data.legend]
                        ? colors[data.legend]
                        : colors['default'][defaultColorInd++ % colors['default'].length],
                },
            };
        });
    }


}
