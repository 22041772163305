import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ReplaySubject} from 'rxjs';
import {FilterFieldOption} from '@core/interfaces/system/system-common';

@Component({
    selector: 'ngx-simple-checkbox-group',
    styleUrls: ['./simple-checkbox-group.component.scss'],
    template: `
        <nb-form-field *ngIf="searchEnabled && !disabled">
            <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
            <input nbInput fieldSize="small" fullWidth [formControl]="filterControl" placeholder="Search..." />
            <button nbSuffix nbButton ghost (click)="filterControl.reset()">
                <nb-icon [icon]="'close'" pack="eva" [attr.aria-label]="'clear search field'"></nb-icon>
            </button>
        </nb-form-field>

        <ngx-icon-box
            *ngIf="selectAllEnabled && !disabled"
            (click)="changeSelection()"
            class="select-all-items"
            [title]="isAllSelected ? 'Unselect All' : 'Select All'"
            [icon]="isAllSelected ? 'square-outline' : 'checkmark-square-2-outline'"
        ></ngx-icon-box>
        <nb-list nbInfiniteList [threshold]="300" (bottomThreshold)="loadNext()">
            <nb-list-item *ngFor="let item of displayOptions$ | async">
                <nb-checkbox
                    [disabled]="disabled"
                    (checkedChange)="selectCheckbox(item, $event)"
                    [checked]="selectedOptions.includes(item)"
                >
                    {{ item.name }}
                </nb-checkbox>
            </nb-list-item>
        </nb-list>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SimpleCheckboxGroupComponent),
            multi: true,
        },
    ],
})
export class SimpleCheckboxGroupComponent extends Unsubscribable implements ControlValueAccessor {
    @Input() selectAllEnabled: boolean = true;
    @Input() searchEnabled: boolean = true;

    selectedOptions: FilterFieldOption[] = [];

    get options() {
        return this._options;
    }

    filteredOptions: FilterFieldOption[] = [];
    displayOptions$: ReplaySubject<{key: string; name: string}[]> = new ReplaySubject(1);

    isAllSelected: boolean = false;

    filterControl: FormControl = new FormControl();
    pageSize: number = 15;
    pageToLoadNext: number = 1;
    loading: boolean = false;
    touched: boolean = false;
    disabled: boolean = false;
    onChange = (value) => {};
    onTouched = () => {};
    private _options: FilterFieldOption[] = [];

    @Input() set options(val: FilterFieldOption[]) {
        this._options = val;
        this.filteredOptions = val;
        this.displayOptions$.next(this.filteredOptions?.slice(0, this.pageSize * this.pageToLoadNext));
    }

    constructor() {
        super();

        this.filterControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(), debounceTime(500))
            .subscribe((value: string) => {
                this.filterOptions(value);
            });
    }

    writeValue(value: any): void {
        this.isAllSelected = value.length === this.options?.length;
        this.selectedOptions = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    changeSelection(): void {
        this.markAsTouched();

        this.isAllSelected = !this.isAllSelected;

        if (this.isAllSelected) {
            this.selectedOptions = [...this.options];
        } else this.selectedOptions = [];
        this.onChange(this.selectedOptions);
    }

    selectCheckbox(checkbox: FilterFieldOption, selected: boolean): void {
        this.markAsTouched();

        if (selected) {
            this.selectedOptions.push(checkbox);

            this.selectedOptions.length === this.options.length && (this.isAllSelected = true);
        } else {
            this.selectedOptions = this.selectedOptions.filter((item) => item.key !== checkbox.key);

            this.selectedOptions.length === this.options.length - 1 && (this.isAllSelected = false);
        }

        this.onChange(this.selectedOptions);
    }

    filterOptions(search: string): void {
        this.pageToLoadNext = 1;

        if (!this.options) {
            return;
        }

        if (!search) {
            this.filteredOptions = this.options;
            this.displayOptions$.next(this.filteredOptions?.slice(0, this.pageSize * this.pageToLoadNext));
            return;
        }

        search = search.toLowerCase();
        this.filteredOptions = this.options.filter((opt) => opt.name.toLowerCase().indexOf(search) > -1);
        this.displayOptions$.next(this.filteredOptions?.slice(0, this.pageSize * this.pageToLoadNext));
    }

    loadNext(): void {
        if (this.loading) {
            return;
        }

        this.loading = true;
        ++this.pageToLoadNext;
        this.displayOptions$.next(this.filteredOptions?.slice(0, this.pageSize * this.pageToLoadNext));
        this.loading = false;
    }
}
