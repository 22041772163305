import {FormatsService} from '@core/utils/formats.service';
import {Filter} from '@core/interfaces/system/system-common';

export class PivotTableSource {
    data: AnnualLoad[];
}

export class AnnualLoad {
    year: number;
    load: number;
}

export class TableService {
    // Prepare and return dynamic header for table
    public static prepareTableSettings(settings: any, startYear: string, endYear: string, currentYear: string) {
        var years = [];
        for (let i = parseInt(startYear); i <= parseInt(endYear); i++) {
            years.push(i);
        }
        const columns = TableService.prepareDynamicHeader(years, {
            currentYear: currentYear,
            fixedCellTotalWidth: TableService.getTotalFixedWidth(settings),
        });
        Object.assign(settings.columns, columns);
        return settings;
    }

    // Prepare dynamic header column for pivot table
    public static prepareDynamicHeader(yearsData: any[], options?: any, unit?: string) {
        const width = options?.width
            ? options.width
            : this.calculateWidth(yearsData.length, options.fixedCellTotalWidth ? options.fixedCellTotalWidth : 0);
        const currentYear = options?.currentYear ? options.currentYear : new Date().getFullYear();

        let dynamicCol = {};
        yearsData.map((yearData) => {
            let val = {
                [yearData]: {
                    title: yearData,
                    filter: false,
                    sort: false,
                    type: 'html',
                    width: width,
                    class: yearData <= currentYear ? 'actual' : 'forecast',
                    valuePrepareFunction: (value, row) => {
                        if (value == '' || isNaN(value)) {
                            return '-';
                        } else {
                            const suffix = row.suffix ? (row.suffix.value ? row.suffix.value : null) : null;
                            if (suffix != null && suffix == '%') {
                                return FormatsService.preparePercentageLabelFromDecimal(value, 2);
                            }
                            return FormatsService.prepareValue(value, unit ? unit : '', '', false, 10);
                        }
                    },
                },
            };
            dynamicCol = {
                ...dynamicCol,
                ...val,
            };
        });
        return dynamicCol;
    }

    public static calculateWidth(numOfHeader: number, fixedCellTotalWidth): string {
        const tableCardWidth = window.innerWidth - 64 - 9 - 48;
        const totalDynamicCellWidth = tableCardWidth * ((100 - fixedCellTotalWidth) / 100);
        const averageWidth = fixedCellTotalWidth ? totalDynamicCellWidth : 1250;
        let width;
        if (numOfHeader < 10) {
            width = averageWidth / numOfHeader;
        } else {
            width = 150;
        }
        return width + 'px';
    }

    public static getTotalFixedWidth(setting) {
        const widthList = [];
        Object.keys(setting.columns).map((key) => {
            if (key !== 'action') {
                Object.keys(setting.columns[key]).map((item) => {
                    if (item === 'width') {
                        widthList.push(parseInt(setting.columns[key][item].replace('%', '')));
                    }
                });
            }
        });
        return widthList.reduce((partialSum, a) => partialSum + a, 0);
    }

    public static createFiltersParamsString(filters: Filter[]) {
        return filters.reduce((acc, curr) => {
            if (acc !== '') acc += '&';
            acc += `filterBy${curr.fieldKey}_${curr.operator}=${curr.value || curr.values?.join('|') || ''}`;
            return acc;
        }, '');
    }
}
