import {AdditionalMapLayer, MapLayerDetails} from '../../../../../../pages/geospatial-viewer/model/layers';
import {DistrictData} from '../../../../../../pages/geospatial-viewer/model/api';
import {DistrictOverlayFieldsService} from '../../fields/district-overlay-fields.service';
import {DistrictOverlayPopupService} from '../../popups/district-overlay-popup.service';
import {DistrictOverlayRendererService} from '../../renderers/district-overlay-renderer.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';

@Injectable()
export class DistrictOverlayLayerService {
    constructor(
        private fieldsService: DistrictOverlayFieldsService,
        private popupService: DistrictOverlayPopupService,
        private rendererService: DistrictOverlayRendererService,
    ) {}

    public prepareDistrictOverlayLayer(
        layer: AdditionalMapLayer,
        data: DistrictData[],
        details: MapLayerDetails[],
    ): FeatureLayer {
        const districtOverlayLayer: FeatureLayer = new FeatureLayer({
            id: layer.code,
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createGraphics(data),
            renderer: this.rendererService.customLayerRenderer(),
            fields: this.fieldsService.getCustomMapFields(),
            popupTemplate: {
                outFields: ['*'],
                title: '{id}',
                content: this.popupService.getCustomPopoutFunction,
            },
        });

        return districtOverlayLayer;
    }

    /*
     * Create array of Graphics, type = polyline. Return Graphic[]
     */
    private createGraphics(data: DistrictData[]): Graphic[] {
        return data.map((district: DistrictData) => {
            return new Graphic({
                geometry: new Polygon({
                    rings: district.geoJsonGeometry.coordinates,
                }),
                attributes: {
                    // Unique identifiers
                    ObjectID: district.id,
                    // Data
                    districtName: district.districtName,
                    districtType: 'district',
                    districtDescription: district.districtDescription,
                },
            });
        });
    }
}
