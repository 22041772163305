import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL, PeriodFilter} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class DemandFactorApi {
    private readonly PREFIX_FACTOR = 'factor';

    constructor(private api: HttpService) {}

    /*
     * Demand factors
     */
    // Pivot data
    public getOrganicGrowthPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FACTOR}/og/${workflowItemId}/scenarios/${scenarioId}/pivot?${params}`,
        );
    }

    public getLoadTransferPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FACTOR}/lt/${workflowItemId}/scenarios/${scenarioId}/pivot?${params}`,
        );
    }

    public getPhotovoltaicPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FACTOR}/pv/${workflowItemId}/scenarios/${scenarioId}/pivot?${params}`,
        );
    }

    public getElectricVehiclePivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FACTOR}/ev/${workflowItemId}/scenarios/${scenarioId}/pivot?${params}`,
        );
    }

    public getLLAConnectionPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FACTOR}/lla/${workflowItemId}/scenarios/${scenarioId}/connection/pivot?${params}`,
        );
    }

    public getLLADevelopPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FACTOR}/lla/${workflowItemId}/scenarios/${scenarioId}/development/pivot?${params}`,
        );
    }

    // Data download
    public getOrganicGrowthPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(`${this.PREFIX_FACTOR}/og/${workflowItemId}/scenarios/${scenarioId}/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('unit', unit),
        });
    }

    public getLoadTransferPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(`${this.PREFIX_FACTOR}/lt/${workflowItemId}/scenarios/${scenarioId}/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('unit', unit),
        });
    }

    public getPhotovoltaicPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(`${this.PREFIX_FACTOR}/pv/${workflowItemId}/scenarios/${scenarioId}/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('unit', unit),
        });
    }

    public getLLAConnectionPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(
            `${this.PREFIX_FACTOR}/lla/${workflowItemId}/scenarios/${scenarioId}/connection/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit),
            },
        );
    }

    public getLLADevelopPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(
            `${this.PREFIX_FACTOR}/lla/${workflowItemId}/scenarios/${scenarioId}/development/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit),
            },
        );
    }

    public getElectricVehiclePivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(`${this.PREFIX_FACTOR}/ev/${workflowItemId}/scenarios/${scenarioId}/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('unit', unit),
        });
    }
}
