import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ReplaySubject} from 'rxjs';
import {FilterFieldOption} from '@core/interfaces/system/system-common';

export interface FilterState {
    isAllSelected: boolean;
    searchString: string;
    selectedOptions: FilterFieldOption[];
}

@Component({
    selector: 'ngx-api-checkbox-group',
    styleUrls: ['./api-checkbox-group.component.scss'],
    template: `
        <nb-form-field>
            <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
            <input nbInput fieldSize="small" fullWidth [formControl]="searchControl" placeholder="Search..." />
            <button nbSuffix nbButton ghost (click)="resetSearch()">
                <nb-icon [icon]="'close'" pack="eva" [attr.aria-label]="'clear search field'"></nb-icon>
            </button>
        </nb-form-field>

        <ngx-icon-box
            (click)="toggleSelectAll()"
            class="select-all-items"
            [title]="isAllSelected ? 'Unselect All' : 'Select All'"
            [icon]="isAllSelected ? 'square-outline' : 'checkmark-square-2-outline'"
        ></ngx-icon-box>
        <nb-list nbInfiniteList [threshold]="300" (bottomThreshold)="loadNext()">
            <nb-list-item *ngFor="let item of displayOptions$ | async">
                <nb-checkbox
                    [disabled]="disabled"
                    (checkedChange)="selectCheckbox(item, $event)"
                    [checked]="isSelected(item)"
                >
                    {{ item.name }}
                </nb-checkbox>
            </nb-list-item>
        </nb-list>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ApiCheckboxGroupComponent),
            multi: true,
        },
    ],
})
export class ApiCheckboxGroupComponent extends Unsubscribable implements OnInit, ControlValueAccessor {
    @Input() set options(val: FilterFieldOption[]) {
        this._options = val;
        this.newOptionsFetched = true;

        this.syncSelections();
        this.updateDisplayOptions();
    }
    @Input() initialState: FilterState;
    @Output() searchChange = new EventEmitter<string>();
    @Output() stateChange = new EventEmitter<FilterState>();

    _options: FilterFieldOption[] = [];

    selectedOptions: FilterFieldOption[] = [];

    displayOptions$: ReplaySubject<{key: string; name: string}[]> = new ReplaySubject(1);

    isAllSelected: boolean = false;

    searchControl: FormControl = new FormControl();
    newOptionsFetched = false;

    pageSize: number = 15;
    pageToLoadNext: number = 1;

    loading: boolean = false;
    touched: boolean = false;
    disabled: boolean = false;
    onChange = (value) => {};
    onTouched = () => {};

    constructor() {
        super();

        this.searchControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(), debounceTime(500))
            .subscribe((value: string) => {
                this.searchChange.emit(value);
            });
    }

    public ngOnInit() {
        if (this.initialState) {
            this.searchControl.setValue(this.initialState.searchString);
        }
    }

    writeValue(value: any): void {
        if (!value) {
            this.resetSelectedOptions(); // Clear the selected options and search when reset happens
        } else {
            this.selectedOptions = value;
            this.syncSelections();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    syncSelections(): void {
        this.isAllSelected = this._options.every((option) =>
            this.selectedOptions.some((selectedOption) => selectedOption.key === option.key),
        );
        this.updateDisplayOptions();
    }

    toggleSelectAll(): void {
        this.markAsTouched();
        this.isAllSelected = !this.isAllSelected;

        if (this.isAllSelected) {
            this.selectedOptions = [...this._options];
        } else this.selectedOptions = [];

        this.updateState();
        this.onChange(this.selectedOptions);
    }

    selectCheckbox(checkbox: FilterFieldOption, selected: boolean): void {
        this.markAsTouched();

        //keep only visible selected options and remove others (from the previous state) from the selected list
        if (this.newOptionsFetched) {
            this.selectedOptions = this.selectedOptions.filter((item) => {
                return this._options.find((o) => o.key === item.key);
            });
            this.newOptionsFetched = false;
        }

        //Default toggle checkbox selection behaviour
        if (selected) {
            this.selectedOptions.push(checkbox);
        } else {
            this.selectedOptions = this.selectedOptions.filter((item) => item.key !== checkbox.key);
        }

        // Update the "select all" state
        this.isAllSelected = this._options.every((option) => this.selectedOptions.includes(option));

        this.updateState();
        this.onChange(this.selectedOptions);
    }

    resetSelectedOptions() {
        this.isAllSelected = false;
        this.resetSearch();
        this.selectedOptions = [];
        this.updateState();
        this.onChange(this.selectedOptions);
    }

    resetSearch(): void {
        this.searchControl.reset();
    }

    loadNext(): void {
        if (this.loading) {
            return;
        }

        this.loading = true;
        ++this.pageToLoadNext;
        this.updateDisplayOptions();
        this.loading = false;
    }

    isSelected(option: FilterFieldOption): boolean {
        return this.selectedOptions.some((selected) => selected.key === option.key);
    }

    private updateDisplayOptions(): void {
        this.displayOptions$.next([...this._options].slice(0, this.pageSize * this.pageToLoadNext));
    }

    private updateState() {
        this.stateChange.emit({
            selectedOptions: this.selectedOptions,
            searchString: this.searchControl.value,
            isAllSelected: this.isAllSelected,
        });
    }
}
