import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, forkJoin, Observable, Subject} from 'rxjs';
import {CustomLayer, Layer, MetricLayer, ProjectLayer, SystemLayer} from './layer';
import {debounceTime, distinctUntilChanged, filter, finalize, skip, takeUntil, tap} from 'rxjs/operators';
import {GeospatialViewerService} from '../api/geospatial-viewer.service';
import {GeospatialRequest} from '../model/api';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {GeospatialViewerControlsStore} from '../api/geospatial-viewer-controls.store';
import {GeospatialResponse} from '../../geospatial-viewer-old/model/api';
import {FormBuilder} from '@angular/forms';
import {GeospatialFilterStore} from '../api/geospatial-filter.store';
import {UsersStore} from '@store/common/users.store';
import {StudiesStore} from '@store/common/studies.store';
import {GeospatialViewerConfiguration} from '../model/viewer-config';
import {CustomMapLayer} from '../model/layers';

@Injectable()
export class LayersService extends Unsubscribable {
    // Separate Observables for each type of layer
    private systemLayers: BehaviorSubject<SystemLayer[]> = new BehaviorSubject<SystemLayer[]>([new SystemLayer()]);
    systemLayers$: Observable<SystemLayer[]> = this.systemLayers.asObservable();

    private projectLayers: BehaviorSubject<ProjectLayer[]> = new BehaviorSubject<ProjectLayer[]>([
        new ProjectLayer(this.fb, this.userStore, this.studiesStore),
    ]);
    projectLayers$: Observable<ProjectLayer[]> = this.projectLayers.asObservable();

    private _metricLayers: BehaviorSubject<MetricLayer[]> = new BehaviorSubject<MetricLayer[]>([
        this.createMetricLayer(),
    ]);
    metricLayers$: Observable<MetricLayer[]> = this._metricLayers.asObservable();

    private _customLayers: BehaviorSubject<CustomLayer[]> = new BehaviorSubject<CustomLayer[]>([]);
    customLayers$: Observable<CustomLayer[]> = this._customLayers.asObservable();

    // Subject that will emit changes for the specific layer that needs to be re-rendered
    private _metricLayerRerender: BehaviorSubject<Layer | null> = new BehaviorSubject<Layer | null>(null);
    metricLayerRerender$: Observable<Layer | null> = this._metricLayerRerender.asObservable();

    // Subject that will indicate the completion of layer queue re-rendering
    private _metricLayersRerenderComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    metricLayersRerenderComplete$: Observable<boolean> = this._metricLayersRerenderComplete.asObservable();

    private cancelRequest$ = new Subject<void>();
    private initConfigComplete: boolean = false;
    private minZoomRendering: number = 13;

    constructor(
        private geospatialViewerService: GeospatialViewerService,
        private geospatialViewerControlsStore: GeospatialViewerControlsStore,
        private fb: FormBuilder,
        private geospatialFilterStore: GeospatialFilterStore,
        private userStore: UsersStore,
        private studiesStore: StudiesStore,
    ) {
        super();

        this.geospatialViewerControlsStore.config$
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((r) => !!r),
                skip(1),
                debounceTime(500),
            )
            .subscribe((config: GeospatialViewerConfiguration) => {
                this.refreshAllMetricLayers();
                // Run this only once
                if (!this.initConfigComplete) {
                    this.initCustomMapLayers(config);
                    this.initZoomLevelCutoff(config);
                    this.initConfigComplete = true;
                }
            });

        this.geospatialViewerControlsStore.currentStudyId$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.resetToDefaultMetricLayer();
        });
    }

    get projectLayer(): ProjectLayer {
        return this.projectLayers.value[0];
    }

    get metricLayers(): MetricLayer[] {
        return this._metricLayers.value;
    }

    get firstMetricLayer(): MetricLayer {
        return this._metricLayers.value[0];
    }

    createMetricLayer(): MetricLayer {
        return new MetricLayer(this.fb, this.geospatialFilterStore);
    }

    addMetricLayer(layer: MetricLayer) {
        const currentLayers = this._metricLayers.getValue();
        this.closeLayers(currentLayers);
        this._metricLayers.next([...currentLayers, layer]);
    }

    duplicateMetricLayer(layer: MetricLayer): MetricLayer {
        const newLayer = this.createMetricLayer();

        // Copy properties from the existing layer to the new one
        newLayer.mapDataDetails = {...layer.mapDataDetails};
        newLayer.visible = layer.visible;
        newLayer.name = layer.name;
        newLayer.mapData = [...layer.mapData];
        newLayer.metrics = {...layer.metrics};

        // Clone the form values (optional, depending on use case)
        newLayer.layerForm.patchValue(layer.layerForm.value);

        //set these values in some time to make sure:
        // 1. metric and categoriesSelected are not reset after measure changes
        // 2. "filters" control already exists in the layerForm
        setTimeout(() => {
            newLayer.filterControlsStore.resetQuickFilters(layer.filterControlsStore.getQuickFilters());

            newLayer.layerForm.patchValue({
                metric: layer.layerForm.value.metric,
                categoriesSelected: layer.layerForm.value.categoriesSelected,
                filters: layer.layerForm.value.filters,
            });
        }, 300);

        // Add the duplicated layer to the list
        this.addMetricLayer(newLayer);

        // Return the new layer instance
        return newLayer;
    }

    closeLayers(currentLayers: Layer[]) {
        currentLayers.forEach((layer) => layer.closeLayer());
    }

    removeMetricLayer(layer: MetricLayer) {
        layer.visible = false;
        this.rerenderMetricLayer(layer);

        const currentLayers = this._metricLayers.getValue().filter((_layer) => _layer.id !== layer.id);
        this._metricLayers.next(currentLayers);
    }

    resetToDefaultMetricLayer() {
        const layerCurrentMetric = this._metricLayers.getValue();

        layerCurrentMetric.forEach((layer: MetricLayer) => {
            layer.visible = false;
            this.rerenderMetricLayer(layer);
        });

        this._metricLayers.next([this.createMetricLayer()]);
    }

    toggleLayerVisibility(layer: Layer) {
        // Do not propagate changes for any CustomLayer - currently no data is ever available.
        if (layer && !(layer instanceof CustomLayer)) {
            layer.toggleVisibility();

            // Trigger re-rendering of the map
            if (layer instanceof MetricLayer) {
                layer.visible ? this.refreshMetricLayerData(layer) : this.rerenderMetricLayer(layer);

                this._metricLayers.next(this._metricLayers.getValue());
            }
        }
    }

    public refreshMetricLayerData(layer: MetricLayer) {
        if (layer.layerForm.valid && layer.layerForm.value.visible) {
            this.getMapGeoData(layer).subscribe();
        }
    }

    public refreshAllMetricLayers() {
        const refreshRequests = this._metricLayers.value
            .filter((layer: MetricLayer) => layer.layerForm.valid && layer.layerForm.value.visible)
            .map((layer: MetricLayer) => this.getMapGeoData(layer));

        if (refreshRequests.length > 0) {
            forkJoin(refreshRequests).subscribe(() => {
                //Trigger complete map re-rendering after all layers in the queue have been processed
                this._metricLayersRerenderComplete.next(true);
            });
        }
    }

    cancelRequest() {
        this.cancelRequest$.next();
        this.cancelRequest$.complete();
        this.cancelRequest$ = new Subject<void>();
    }

    public getMapGeoData(layer: MetricLayer) {
        this.cancelRequest();
        const centerConfig = this.geospatialViewerControlsStore.getConfig()?.centerCoordinate;

        let req: GeospatialRequest = {
            visualizationType: layer.getVisualizationType(),
            measure: layer.getMeasure(),
            metric: layer.getMetric(),
            valueType: layer.getValueType(),
            filterList: layer.getFilters(),
            valueFilter: layer.getCategories(),
            studyId: this.geospatialViewerControlsStore.getCurrentStudyId(),
            centerConfig,
        };

        layer.setLoading(true);

        /*
         * Clear all metric layer content when zoomed out further than zoomLevelCutoff (default: 13)
         * - Above (i.e., zoomed in): refresh data as normal
         * - Below (i.e., zoomed out): clear data from all metric layers, do not fetch new data
         */
        if (centerConfig.zoomLevel >= this.minZoomRendering) {
            layer.visible = true;
            return this.geospatialViewerService.getMapGeoData(req.studyId, req).pipe(
                debounceTime(1500),
                takeUntil(this.cancelRequest$),
                finalize(() => {
                    layer.setLoading(false);
                }),
                tap((mapDataSource: GeospatialResponse) => {
                    layer.updateMapDataSource(mapDataSource);
                    layer.setDataDetails(mapDataSource.dataDetails);
                    layer.updateRenderedVisualizationType(mapDataSource.visualizationType);

                    this.rerenderMetricLayer(layer); // Re-render the map

                    this._metricLayers.next(this._metricLayers.getValue());
                }),
            );
        } else {
            // Clear data from all metric layers, do not fetch new data
            layer.clearMapDataSource();

            layer.setLoading(false);
            this.rerenderMetricLayer(layer);
            return EMPTY;
        }
    }

    // Method to emit updates for a specific Metrics layer
    private rerenderMetricLayer(layer: Layer) {
        this._metricLayerRerender.next(layer); // Emit the specific layer that was updated
    }

    /*
     * Support for custom map layers
     */
    public initCustomMapLayers(config: GeospatialViewerConfiguration): void {
        if (config?.customLayers?.length > 0) {
            this._customLayers.next(config.customLayers.map((l: CustomMapLayer) => new CustomLayer(l)));
        }
    }

    /*
     * Support for zoom level cut-off
     */
    public initZoomLevelCutoff(config: GeospatialViewerConfiguration): void {
        if (config?.centerCoordinate?.minZoomRendering) {
            this.minZoomRendering = config.centerCoordinate.minZoomRendering;
        }
    }
}
