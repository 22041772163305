import {GeospatialService} from '../../helper/geospatial-service';
import {AssetRendererService} from '../../renderers/asset-renderer.service';
import {AssetFieldsService} from '../../fields/asset-fields.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polyline from '@arcgis/core/geometry/Polyline';
import {GenericPopupService} from '@core/utils/engin/geospatial-viewer/popups/generic-popup.service';
import {SpatialEntityDataExtended} from '@core/utils/engin/geospatial-viewer/helper/engin-data-prepare.service';
import {SpatialCardType} from '@theme/components/spatial-card/model/spatialCardType';

@Injectable()
export class PolylineLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private genericPopupService: GenericPopupService,
        private rendererService: AssetRendererService,
        private fieldsService: AssetFieldsService,
    ) {}

    _prepareLineSettings = {
        popupTemplate: true,
        metrics: [],
        mapType: null,
    };

    /**
     * Prepare polyline layer (geometry type = polyline).
     * Works for point assets (lines) or regions (multi-lines).
     * @return FeatureLayer
     */
    public preparePolylineLayer(
        linearData: SpatialEntityDataExtended[],
        id: string,
        theme: string,
        settings: any = this._prepareLineSettings,
    ): FeatureLayer {
        // Load Esri dependencies from AMD
        const source = this.createPolylineGraphics(linearData);

        let linePopupTemplate = null;
        if (settings?.popupTemplate) {
            linePopupTemplate = {
                outFields: ['*'],
                content: this.genericPopupService.getGenericPopupFunction.bind(
                    this.genericPopupService,
                    settings?.metrics || [],
                ),
            };
        }

        if (settings?.mapType === SpatialCardType.APP_REGISTRY) {
            linePopupTemplate = {
                title: '{id}',
                outFields: ['*'],
                content: this.genericPopupService.defaultPopupTemplate.bind(this.genericPopupService),
            };
        }

        return new FeatureLayer({
            id: id ? id : 'asset_line_layer',
            objectIdField: 'ObjectID',
            source: source,
            renderer: this.rendererService.layerRenderer('simple-line', theme),
            fields: this.fieldsService.getMapFields(),
            outFields: ['*'],
            popupTemplate: linePopupTemplate,
        });
    }

    /*
     * Create array of Graphics, type = polyline. Return Graphic[]
     */
    private createPolylineGraphics(lineData: SpatialEntityDataExtended[]): Graphic[] {
        return lineData.map((area) => {
            const geometry = new Polyline({
                paths: area.geoJsonGeometry.coordinates,
            });

            return new Graphic({
                geometry: geometry,
                attributes: {
                    ...area,
                },
            });
        });
    }
}
