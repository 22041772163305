import {SpatialEntityData} from '../../../../../pages/geospatial-viewer/model/api';
import {GeospatialTooltipType} from '../../../../../pages/geospatial-viewer/model/tooltip.model';
import {VisualizationType} from '../../../../../pages/geospatial-viewer/model/visualization';

export interface SpatialEntityDataExtended extends SpatialEntityData {
    tooltipType: GeospatialTooltipType;
}

export class EnginDataPrepareService {
    constructor() {}

    /**
     * Prepare data for point asset layer.
     * @return SpatialEntityDataExtended[]
     */
    public prepare(
        data: SpatialEntityData[],
        mapColourParser: any,
        tooltipType: GeospatialTooltipType,
    ): SpatialEntityDataExtended[] {
        return data.map((entity: SpatialEntityData) => {
            const mapDisplayCategory = mapColourParser(entity.data);

            return {
                // Unique identifiers
                id: entity.id,
                classCode: entity.classCode,
                tooltipType: tooltipType,
                // Spatial data
                geoJsonGeometry: entity.geoJsonGeometryStr ? JSON.parse(entity.geoJsonGeometryStr) : null,
                // Data
                displayCategory: mapDisplayCategory,
                data: entity.data + '', // ensure data is a string otherwise console is spammed with warnings
                count: entity.count ? entity.count : 1,
            };
        });
    }
}
