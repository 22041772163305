<div class="form-container">
    <nb-select
        fullWidth
        [formControl]="selectControl"
        [placeholder]="placeholder"
        [optionsPanelClass]="optionsPanelClass"
    >
        <!-- Custom label -->
        <nb-select-label *ngIf="selectLabel">
            {{ selectLabel }}
        </nb-select-label>

        <!-- Default label -->
        <nb-select-label *ngIf="!selectLabel">
            {{ selectControl.value?.name || "Select an option" }}
        </nb-select-label>

        <!-- Search field -->
        <nb-option [value]="''" class="search" (click)="withStopPropagation && $event.stopPropagation()">
            <nb-form-field>
                <input
                    #filterInput
                    nbInput
                    fullWidth
                    [formControl]="filterControl"
                    (click)="$event.stopPropagation()"
                    (keydown)="$event.stopImmediatePropagation()"
                    placeholder="Search..."
                />
                <button nbSuffix nbButton ghost (click)="filterControl.reset()">
                    <nb-icon icon="close" pack="eva" aria-label="Clear search"></nb-icon>
                </button>
            </nb-form-field>
        </nb-option>

        <!-- Options -->
        <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
            {{ option.name }}
        </nb-option>
    </nb-select>
</div>
