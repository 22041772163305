import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {
    FormCellType,
    FormField,
    FormFieldOption,
    FormViewModeType,
    SelectFieldOptionResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';

interface Option extends SelectFieldOptionResultDto {
    label: string;
    order: number;
    selected: boolean;
}

@Component({
    selector: 'ngx-form-field-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent extends FormFieldBaseComponent<SelectFieldOptionResultDto[]> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() pageMode: FormMode;
    public FormMode = FormMode;
    options: Option[] = [];
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        // Set options
        this.options = this.field.options.map((option: FormFieldOption) => {
            return {
                fieldId: this.field.id,
                optionType: this.field.options[0].optionType,
                optionId: option.id,
                label: option.optionLabel,
                order: option.order,
                selected: !(this.result?.find((o) => o.optionId == option.id) == undefined),
            };
        });

        this.genericInit();

        if (this.fieldForm) {
            // Validator will already exist for required
            this.fieldForm.updateValueAndValidity({emitEvent: false});
        }
    }

    public selectedOption(pressedOption: Option): void {
        pressedOption.selected = !pressedOption.selected;

        // This component does not trigger fieldForm.valueChanges so perform this manually
        const newValue: SelectFieldOptionResultDto[] = this.applyValueChange(this.options.filter((o) => o.selected));
        this.fieldForm.setValue(newValue);
        // Manually trigger onValidationCheck since it does not fire for some reason
        this.onValidationCheck();
    }

    public emptyOptions(): boolean {
        return this.options?.length == 0;
    }

    public getPreviousValue(): string {
        const selectedOptions = this.options.filter((item) => item.selected);
        const result = [];
        selectedOptions.forEach((item) => [result.push(item.label)]);
        return result.length > 0 ? result.join(',') : '-';
    }

    /*
     * Implement abstract methods
     */
    validate(value: SelectFieldOptionResultDto[]): boolean {
        if (this.required && (value == null || value.length == 0)) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormArray;
    }

    applyValueChange(items: Option[]): SelectFieldOptionResultDto[] {
        if (items === undefined || items === null) return null;
        return items as SelectFieldOptionResultDto[];
    }

    getFormValue(): any {
        return this.options?.filter((o) => o.selected) || null;
    }

    onValidationCheck(): void {
        if (this.fieldForm?.value.length === 0 && this.required) {
            this.fieldForm?.markAsTouched();
        }
        this.cd.detectChanges();
    }
}
