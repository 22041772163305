import {Observable} from 'rxjs';
import {SimpleSeries} from '@core/interfaces/system/system-common';
import {
    InfrastructureType,
    LpInfrastructureRequest,
} from '@core/interfaces/engin/load-profile-analysis/lp-common.model';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {OutlierType} from '@core/interfaces/engin/load-profile-analysis/feeder-outlier';

export interface ReadingCorrectedDto {
    batchNumber: number;
    infrastructureType: InfrastructureType;
    infrastructureId: string;
    readingDatetime: Date;
    kw: number;
    kva: number;
    kvar: number;
    powerFactor: number;
    phaseAngle: number;

    outlierIdentified: boolean;
    outlierType: OutlierType;
    outlierLabel: string;
}

export abstract class PowerMeterReadingService {
    abstract getInfrastructureNetLoad(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]>;

    abstract getMetersByBatch(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]>;

    abstract listCorrectedReadingDtos(batchNumber: number, additionalQueryParam?: string): Observable<DataSource>; //interface ReadingCorrectedDto
    abstract downloadCorrectedReadings(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl>;
}
