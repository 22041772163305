import {Directive, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[ngxOnlyNumber]',
})
export class OnlyNumberDirective {
    @Input() integer: boolean = true;
    @Input() negative: boolean = false;

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const isCtrlOrCmd = event.ctrlKey || event.metaKey;

        // Allow Ctrl/Cmd + V (paste)
        if ((isCtrlOrCmd && event.key === 'v') || event.key === 'c') {
            return;
        }
        // Allow backspace (8), tab (9), return/enter (13), arrows (left, up, right, down), delete (46)
        if ([8, 9, 13, 37, 38, 39, 40, 46].includes(event.keyCode)) return;
        if (!this.textRegexSingleKey(event.key)) {
            event.preventDefault();
        }
    }

    // Listen for the "paste" event (to validate pasted values)
    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        let pastedData = event.clipboardData?.getData('text') || '';

        // Regular expression to check for valid number (integer or decimal based on input)
        const isValidNumber = this.textRegexFullValue(pastedData);

        if (!isValidNumber) {
            event.preventDefault();
        } else {
            event.preventDefault(); // Prevent the default paste behavior

            const target = event.target as HTMLInputElement;
            const currentValue = target.value;
            if (target.type === 'number') {
                target.value = pastedData;
            } else {
                // Get the caret (cursor) position
                const start = target.selectionStart || 0;
                const end = target.selectionEnd || 0;
                // Replace the selected text with the pasted data
                target.value = currentValue.slice(0, start) + pastedData + currentValue.slice(end);
                // Set caret position to after the pasted data
                target.setSelectionRange(start + pastedData.length, start + pastedData.length);
            }
            target.dispatchEvent(new Event('input'));
        }
    }

    /*
     * Integer regex:
     * - Allow value of 0
     * - Allow unnecessary leading zeros, e.g., 023 valid
     * - Allow a leading + (optional) for positive values e.g., 23 is valid, +23 is valid
     * - Allow negatives: ^([+-]?\d*)$
     * - Prevent negatives: ^([+]?\d*)$
     * Double regex:
     * - Require 1 or more digits after decimal
     * - Leading digit is optional, e.g., .23 is valid
     * - Allow negatives: ^([+-]?\d*\.?\d+)$
     * - Prevent negatives: ^([+]?\d*\.?\d+)$
     */
    private textRegexFullValue(val): boolean {
        // Integer values only
        if (this.integer) {
            if (this.negative) {
                // Allow leading +/- sign when we allow negatives
                return /^([+-]?\d*)$/.test(val);
            } else {
                // Positive values only
                return /^([+]?\d*)$/.test(val);
            }
        } else {
            // Allow decimals
            if (this.negative) {
                return /^(\d*\.?\d+)$/.test(val);
            } else {
                return /^(\d*\.?\d+)$/.test(val);
            }
        }
    }

    /*
     * Integer regex:
     * - Allow digits
     * - Allow negatives: [+\-0-9]
     * - Prevent negatives: [+0-9]
     * Double regex:
     * - Allow decimal "."
     * - Allow negatives: [+\-0-9,\.]
     * - Prevent negatives: [+0-9,\.]
     */
    private textRegexSingleKey(key): boolean {
        // Integer values only
        if (this.integer) {
            if (this.negative) {
                // Allow leading +/- sign when we allow negatives
                return /[+\-0-9]/.test(key);
            } else {
                // Positive values only
                return /[+0-9]/.test(key);
            }
        } else {
            // Allow decimals
            if (this.negative) {
                return /[+\-0-9,\.]/.test(key);
            } else {
                return /[+0-9,\.]/.test(key);
            }
        }
    }
}
