import {GeoJSONGeometry} from './api';
import {User} from '@core/interfaces/common/users';
import {GeospatialViewerConfiguration} from './viewer-config';
import {Filter, SimpleSeries} from '@core/interfaces/system/system-common';

export class Project {
    id: number;
    projectCreateType: ProjectCreateType;
    projectName: string;
    description: string;
    ownerId: string;
    ownerName: string;
    investmentDriver: string;
    filterParams: Filter[];
    projectStatus: ProjectStatus;
    visible: boolean;
    // Relation to Program
    programId: number;
    programName?: string;
    // Geometry
    projectGeography?: string;
    geoJsonGeometry?: GeoJSONGeometry; // remove?
    // Web-defined for rendering
    projectOwnedByCurrentUser?: boolean;
    workflowItemId: number;

    static getRings(p: Project): any[] {
        return p.geoJsonGeometry ? p.geoJsonGeometry.coordinates : JSON.parse(p.projectGeography).coordinates;
    }
}
export class ProjectDto extends Project {
    assetCount: number;
    assetTotalCost: number;
    adjustedTotalCost: number;
    costDiscountPercent: number;
    bcRatioDeferral: number;
    bcRatioRisk: number;
    totalCost: number;
    programName: string;
    segmentId: number;
    segmentName: string;
}
export class ProjectCreate {
    projectCreateType: ProjectCreateType;
    projectName: string;
    description: string;
    projectStatus: ProjectStatus;
    ownerId: string;
    ownerName: string;
    programId: number;
    investmentDriver: string;
    filterParams: Filter[];
    // Geometry
    projectGeography?: string; // geoJsonGeometry
    visible: boolean;
    workflowItemId: number;
}
export class ProjectUpdate {
    id?: number;
    projectName: string;
    description: string;
    projectStatus: ProjectStatus;
    ownerId: string;
    ownerName: string;
    programId: number;
    investmentDriver: string;
    // Geometry
    projectGeography?: string; // geoJsonGeometry
    visible: boolean;
    filterParams?: Filter[];
    workflowItemId: number;
}
export class ProjectLayerData {
    id: number;
    projectName?: string;
    projectCreateType?: string;
    projectStatus?: string;
    ownerId: string;
    geoJsonGeometryStr?: string;
    projectGeography?: string;
    geoJsonGeometry?: GeoJSONGeometry;
}

export class ProjectInfoData {
    currentUser: User;
    userList: User[];
    config: GeospatialViewerConfiguration;
}

export enum ProjectCreateType {
    SPATIAL = 'SPATIAL',
    PROGRAM_MANAGEMENT = 'PROGRAM_MANAGEMENT',
    DATA_LOAD = 'DATA_LOAD',
}

export enum ProjectStatus {
    SCOPE = 'SCOPE',
    PROJECT = 'PROJECT',
    BUILT = 'BUILT',
    GENERATED = 'GENERATED',
}
export const ProjectStatusLabel = {
    [ProjectStatus.SCOPE]: 'Scope',
    [ProjectStatus.PROJECT]: 'Project',
    [ProjectStatus.BUILT]: 'Built',
    [ProjectStatus.GENERATED]: 'Generated',
};

export class ProjectPopupRequest {
    projectId: string;
    studyId: number;
}

export enum ProjectQuickFilter {
    OWNERSHIP = 'ownership',
    PROJECT_STATUS = 'project_status',
}

export interface EconomicsOverTimeResponse {
    data: SimpleSeries<string, number>;
    info: {
        tco_forced: string;
        tco_optimal: string;
        tco_force_delta: string;
    };
}

export interface ProjectCostAnalysis {
    data: SimpleSeries<string, number>;
    info: {
        capex_total: string;
        opex_total: string;
        opex_annual_average: string;
        opex_5year_total: string;
        opex_lifetime_total: string;
    };
    list: CostComponent[];
}

export interface CostComponent {
    costComponent: string;
    material: number;
    labour: number;
    vehicleTools: number;
    other: number;
}
