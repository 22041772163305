import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {QUICK_FILTER_LIST, QuickFilterCategory} from '../../../model/filter';
import {GenericPopoutFilter} from '@core/interfaces/common/popout';
import {take, takeUntil} from 'rxjs/operators';
import {Filter, FilterFieldOption, FilterOperatorType} from '@core/interfaces/system/system-common';
import {GeospatialFilterStore} from '../../../api/geospatial-filter.store';
import {GenericFilterAccordion} from '@theme/components/control-panel/generic-filter-accordion';

@Injectable()
export class FilterControlsStore extends GenericFilterAccordion {
    // Control panel > Filter > quick filters; map control > quick filters
    private quickFilters: BehaviorSubject<QuickFilterCategory[]> = new BehaviorSubject<QuickFilterCategory[]>(
        QUICK_FILTER_LIST,
    );
    readonly quickFilters$: Observable<QuickFilterCategory[]> = this.quickFilters.asObservable();

    constructor(fb: FormBuilder, private geospatialFilterStore: GeospatialFilterStore) {
        super(fb);

        this.geospatialFilterStore.geospatialFilterSettings$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((settings) => this.updateFilterSettings(settings));
    }

    public updateFilterOptions(filter: any, searchString?: string, filters?: Filter[]): void {
        if (filter.operator === FilterOperatorType.BETWEEN) {
            return;
        }
        this.geospatialFilterStore
            .getFilterOptions(filter, searchString, filters)
            .pipe(take(1))
            .subscribe((data) => {
                this.filterSettings.value.filters.find((f) => f.fieldKey === filter.fieldKey).options = data.response;
                this.refresh.next(true);
            });
    }

    /*
     * Support for quick filtering
     */

    public resetQuickFilters(filters?: QuickFilterCategory[]) {
        this.quickFilters.next(filters || QUICK_FILTER_LIST);
    }

    public getQuickFilters(): QuickFilterCategory[] {
        return this.quickFilters.value;
    }

    // Update geospatial filters selected based on quick filter selections
    // Quick filters will turn certain asset class combinations on/off
    private propgateQuickFilters(quickFilters: QuickFilterCategory[]) {
        this.getActiveFilterSettings().filters.forEach((filter: GenericPopoutFilter) => {
            if (['assetclass', 'assetclasscode'].includes(filter.fieldKey.toLowerCase()))
                this.applyQuickFilters(filter, quickFilters);
        });
    }

    // Toggle quick filter option
    public quickFilterOptionToggle(group: string, option: string) {
        if (group && option) {
            const newQuickFilters = this.quickFilters.value.map((g: QuickFilterCategory) => {
                return {
                    ...g,
                    options: g.options.map((o) => {
                        return {
                            ...o,
                            selected: g.code === group && o.code === option ? !o.selected : o.selected,
                        };
                    }),
                };
            });
            this.quickFilters.next(newQuickFilters);
            // Quick filters simply pre-selects certain general filter options
            this.propgateQuickFilters(newQuickFilters);
        }
    }

    private applyQuickFilters(filter: GenericPopoutFilter, quickFilters: QuickFilterCategory[]) {
        const lineEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'shape', 'line');
        const pointEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'shape', 'point');
        const ohEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'orientation', 'overhead');
        const ugEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'orientation', 'underground');

        if (filter.fieldKey.toLowerCase() === 'assetclass') {
            let newSelectedOptions = filter.options.filter((option: FilterFieldOption) => {
                return this.setSelectedByClass(option.key, lineEnabled, pointEnabled, ohEnabled, ugEnabled);
            });

            this.filtersFormGroup.get('assetclass').reset(newSelectedOptions);
        } else {
            let newSelectedOptions = filter.options.filter((option: FilterFieldOption) => {
                return this.setSelectedByClassCode(option.key, lineEnabled, pointEnabled, ohEnabled, ugEnabled);
            });

            this.filtersFormGroup.get('assetclasscode').reset(newSelectedOptions);
        }
    }

    /*
     * This method checks witch Quick filter should be applied:
     * a) Quick filter option is selected
     * b) All Quick filter options in this group are unselected (i.e. no quick filtering is applied in the group)
     */
    private checkQuickFilterEnabled(quickFilters: QuickFilterCategory[], group: string, option: string): boolean {
        const findGroup = quickFilters.filter((g) => g.code.toLowerCase() === group.toLowerCase());
        if (findGroup.length > 0 && findGroup[0] != null) {
            const noOptionsSelected: boolean =
                findGroup[0].options.filter((o) => !o.selected).length === findGroup[0].options.length;
            const findOption = findGroup[0].options.filter((o) => o.code.toLowerCase() === option.toLowerCase());
            if (findOption.length > 0 && findOption[0] != null) {
                return findOption[0].selected ? findOption[0].selected : noOptionsSelected;
            }
        }
        return false;
    }

    private setSelectedByClassCode(
        code: string,
        lineEnabled: boolean,
        pointEnabled: boolean,
        ohEnabled: boolean,
        ugEnabled: boolean,
    ): boolean {
        const c = code.toLowerCase();
        // All underground classes include "ug_" in class code
        if (c.includes('ug_')) {
            // Check linear codes
            if (c.includes('cable')) {
                return ugEnabled && lineEnabled;
            }
            // Else assume point asset
            return ugEnabled && pointEnabled;
        }

        // Else code is overhead
        if (c.includes('cond')) {
            return ohEnabled && lineEnabled;
        }
        // Else assume point asset
        return ohEnabled && pointEnabled;
    }

    private setSelectedByClass(
        className: string,
        lineEnabled: boolean,
        pointEnabled: boolean,
        ohEnabled: boolean,
        ugEnabled: boolean,
    ): boolean {
        const c = className.toLowerCase();
        // All underground classes include "ug_" in class code
        if (c.includes('underground') || c.includes('u/g')) {
            // Check linear codes
            if (c.includes('cable')) {
                return ugEnabled && lineEnabled;
            }
            // Else assume point asset
            return ugEnabled && pointEnabled;
        }

        // Else code is overhead
        if (c.includes('cond') || c.includes('conductor')) {
            return ohEnabled && lineEnabled;
        }
        // Else assume point asset
        return ohEnabled && pointEnabled;
    }
}
