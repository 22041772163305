import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, combineLatest, of} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {UsersStore} from '@store/common/users.store';
import {StudiesStore} from '@store/common/studies.store';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {User} from '@core/interfaces/common/users';
import {
    PmSensitivityWorkflow,
    PmSensitivityService,
    PmSensitivityRequest,
} from '@core/interfaces/engin/program-management/pm-sensitivity';

@Injectable()
export class ProgramManagementSensitivityStore {
    protected _sensitivityIsOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _sensitivityWorkflow: BehaviorSubject<PmSensitivityWorkflow> = new BehaviorSubject<PmSensitivityWorkflow>(
        null,
    );
    readonly sensitivityWorkflow$: Observable<PmSensitivityWorkflow> = this._sensitivityWorkflow.asObservable();
    readonly sensitivityWorkflowId$: Observable<number> = this.sensitivityWorkflow$.pipe(
        map((sensitivityWorkflow: PmSensitivityWorkflow) => sensitivityWorkflow?.id),
    );
    readonly sensitivityId$: Observable<number> = this.sensitivityWorkflow$.pipe(
        map((sensitivityWorkflow: PmSensitivityWorkflow) => sensitivityWorkflow?.sensitivityId),
    );

    constructor(
        private pmSensitivityService: PmSensitivityService,
        private usersStore: UsersStore,
        private studiesStore: StudiesStore,
    ) {
        this.studiesStore.activeWorkflowRisk$
            .pipe(
                take(1),
                map(() => this.fetchSensitivity()),
            )
            .subscribe();
    }

    // Trigger fetch sensitivity
    public fetchSensitivity(): void {
        combineLatest<Observable<User>, Observable<number>>([
            this.usersStore.currentUser$,
            this.studiesStore.activeStudyIdRisk$,
        ])
            .pipe(
                filter(([user, activeStudyId]) => user !== null && activeStudyId !== undefined && activeStudyId !== -1),
                switchMap(([user, activeStudyId]: [User, number]) => {
                    const req: PmSensitivityRequest = {
                        workflowItemId: activeStudyId,
                        userId: user.id,
                        sensitivityFlag: this._sensitivityIsOn.value,
                    };
                    return this.pmSensitivityService.findSensitivity(req);
                }),
            )
            .subscribe((res: APIResponse<PmSensitivityWorkflow>) => {
                this._sensitivityWorkflow.next(res.response);
            });
    }

    // Toggle "on" means user sensitivity; "off" means global scenario
    public toggleSensitivity(sensitivityIsOn: boolean = false): any {
        this._sensitivityIsOn.next(sensitivityIsOn);
        this.fetchSensitivity();
    }

    // Getters, in order of most to least likely to be used
    get sensitivityIsOn() {
        return this._sensitivityIsOn.getValue();
    }
    get sensitivityId(): number {
        return this._sensitivityWorkflow.getValue()?.sensitivityId;
    }
    get sensitivityWorkflow(): PmSensitivityWorkflow {
        return this._sensitivityWorkflow.getValue();
    }
    get sensitivityWorkflowId(): number {
        return this._sensitivityWorkflow.getValue()?.id;
    }
}
