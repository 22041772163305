<div *ngIf="store.filterSettings$ | async as filterData" class="general-filter-container">
    <nb-accordion [formGroup]="store.filtersFormGroup" [multi]="true" class="accordion">
        <nb-accordion-item *ngFor="let filterItem of filterData.filters" class="general-filter">
            <nb-accordion-item-header class="filter-header">
                <div class="accordion-header-right">
                    <span class="title">{{ filterItem.fieldName }}</span>
                    <span [class.alert-badge]="isFilterApplied(filterItem)"></span>
                </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body class="accordion-body">
                <div
                    *ngIf="!filterItem.fieldType || filterItem.fieldType === FilterFieldType.STRING"
                    class="checkbox-group"
                >
                    <ngx-simple-checkbox-group
                        *ngIf="filterItem.simple"
                        [formControlName]="filterItem.fieldKey"
                        [options]="filterItem.options"
                        [searchEnabled]="filterItem.searchEnabled ?? true"
                        [selectAllEnabled]="filterItem.selectAllEnabled ?? true"
                    ></ngx-simple-checkbox-group>

                    <ngx-api-checkbox-group
                        *ngIf="!filterItem.simple"
                        [formControlName]="filterItem.fieldKey"
                        [options]="filterItem.options"
                        [initialState]="store.getFilterState(filterItem.fieldKey)"
                        (searchChange)="handleSearchChange($event, filterItem)"
                        (stateChange)="handleStateChange($event, filterItem)"
                    ></ngx-api-checkbox-group>
                </div>

                <ngx-analyzer-numeric-range-control
                    *ngIf="isNumeric(filterItem.fieldType)"
                    [formControlName]="filterItem.fieldKey"
                    [options]="{min: filterItem?.min, max: filterItem?.max}"
                ></ngx-analyzer-numeric-range-control>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>

<div
    *ngIf="!(store.filterSettings$ | async)"
    [nbSpinner]="!(store.filterSettings$ | async)?.filters"
    class="spinner-container"
></div>
