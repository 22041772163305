// Configuration
export class Measure {
    code: MeasureType;
    disabled?: boolean = true;
    selectDefault?: boolean = false;
    metrics?: Metric[];
}

export class Metric {
    code: MetricType;
    disabled?: boolean = true;
    selectDefault?: boolean = false;
    valueType: ValueType;
}

// Other DTOs
export class MapValueFilter {
    valueType: ValueType;
    categoryList?: string[];
    range?: {
        min: number;
        max: number;
    };
}

export interface MetricCategoryTypeCategory {
    stringFilter: string[];
}

export interface MetricCategoryTypeNumeric {
    numberMin: number;
    numberMax: number;
}

// Enumerations
export enum ValueType {
    CATEGORY = 'CATEGORY',
    NUMERIC = 'NUMERIC',
}

export enum MeasureType {
    // Standard
    CALENDAR_AGE = 'CALENDAR_AGE',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
    HEALTH_INDEX = 'HEALTH_INDEX',
    ASSET_FAILURES = 'ASSET_FAILURES',
    TCO = 'TCO',
    MONETIZED_RISK = 'MONETIZED_RISK',
    LIFE_CYCLE_ANALYSIS = 'LIFE_CYCLE_ANALYSIS',

    // Not available
    DATA_QUALITY = 'DATA_QUALITY',

    // New - refactored
    HISTORICAL_OUTAGES = 'HISTORICAL_OUTAGES',
    HISTORICAL_OUTAGES_1Y = 'HISTORICAL_OUTAGES_1Y',
    HISTORICAL_OUTAGES_3Y = 'HISTORICAL_OUTAGES_3Y',
    HISTORICAL_OUTAGES_5Y = 'HISTORICAL_OUTAGES_5Y',

    // New - experimental
    CONNECTIVITY = 'CONNECTIVITY',
}
export enum MetricType {
    // Standard
    AGE = 'AGE',
    AGE_VS_TUL = 'AGE_VS_TUL',
    LIFE_REMAINING = 'LIFE_REMAINING',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
    EFFECTIVE_AGE_VS_TUL = 'EFFECTIVE_AGE_VS_TUL',
    EFFECTIVE_LIFE_REMAINING = 'EFFECTIVE_LIFE_REMAINING',
    HEALTH_INDEX_CATEGORY = 'HEALTH_INDEX_CATEGORY',
    HEALTH_INDEX_VALUE = 'HEALTH_INDEX_VALUE',
    PERCENT_HEALTH_REMAINING = 'PERCENT_HEALTH_REMAINING',
    CURRENT_FAILURE_PROBABILITY = 'CURRENT_FAILURE_PROBABILITY',
    TEN_YEAR_FAILURE_PROBABILITY = 'TEN_YEAR_FAILURE_PROBABILITY',
    TIME_UNTIL_SR_50 = 'TIME_UNTIL_SR_50',
    RISK = 'RISK',
    RISK_VS_REPLACEMENT_COST = 'RISK_VS_REPLACEMENT_COST',
    RISK_CATEGORY = 'RISK_CATEGORY',
    YEARS_TO_INTERVENTION = 'YEARS_TO_INTERVENTION',
    OIT = 'OIT',
    ECONOMIC_CATEGORY = 'ECONOMIC_CATEGORY',
    TCO_UNDER_OPTIMAL_REPLACEMENT = 'TCO_UNDER_OPTIMAL_REPLACEMENT',
    TCO_RUN_TO_FAILURE = 'TCO_RUN_TO_FAILURE',

    // Not available
    DATA_QUALITY = 'DATA_QUALITY',
    NUMBER_MISSING_DATA_POINTS = 'NUMBER_MISSING_DATA_POINTS',

    // New - refactored
    OUTAGE_COUNT_1Y = 'OUTAGE_COUNT_1Y',
    OUTAGE_CI_1Y = 'OUTAGE_CI_1Y',
    OUTAGE_CHI_1Y = 'OUTAGE_CHI_1Y',
    OUTAGE_COUNT_3Y = 'OUTAGE_COUNT_3Y',
    OUTAGE_CI_3Y = 'OUTAGE_CI_3Y',
    OUTAGE_CHI_3Y = 'OUTAGE_CHI_3Y',
    OUTAGE_COUNT_5Y = 'OUTAGE_COUNT_5Y',
    OUTAGE_CI_5Y = 'OUTAGE_CI_5Y',
    OUTAGE_CHI_5Y = 'OUTAGE_CHI_5Y',

    // New - experimental
    PRID_CUSTOMERS_TOTAL = 'PRID_CUSTOMERS_TOTAL',
    PRID_CUSTOMERS_LOCAL = 'PRID_CUSTOMERS_LOCAL',
}

// Labels
// TODO: remove, and offload this to Backend
export const MeasureTypeLabel = {
    [MeasureType.CALENDAR_AGE]: 'Calendar Age',
    [MeasureType.EFFECTIVE_AGE]: 'Effective Age',
    [MeasureType.HEALTH_INDEX]: 'Health Index',
    [MeasureType.ASSET_FAILURES]: 'Failure Forecast',
    [MeasureType.MONETIZED_RISK]: 'Risk Forecast',
    [MeasureType.LIFE_CYCLE_ANALYSIS]: 'Life-cycle Analysis',
    [MeasureType.TCO]: 'Total Cost of Ownership (TCO)',

    [MeasureType.DATA_QUALITY]: 'Data Quality',

    [MeasureType.HISTORICAL_OUTAGES]: 'Historical Outages',
    [MeasureType.HISTORICAL_OUTAGES_1Y]: '1-year Trailing Outages',
    [MeasureType.HISTORICAL_OUTAGES_3Y]: '3-year Trailing Outages',
    [MeasureType.HISTORICAL_OUTAGES_5Y]: '5-year Trailing Outages',

    [MeasureType.CONNECTIVITY]: 'Connectivity',
};
// TODO: remove, and offload this to Backend
export const MetricTypeLabel = {
    [MetricType.AGE]: 'Calendar Age',
    [MetricType.AGE_VS_TUL]: 'Age vs. TUL (%)',
    [MetricType.LIFE_REMAINING]: 'Life Remaining (Years)',
    [MetricType.EFFECTIVE_AGE]: 'Effective Age',
    [MetricType.EFFECTIVE_AGE_VS_TUL]: 'Effective Age vs. TUL (%)',
    [MetricType.EFFECTIVE_LIFE_REMAINING]: 'Life Remaining (Years)',
    [MetricType.HEALTH_INDEX_CATEGORY]: 'HI Category',
    [MetricType.HEALTH_INDEX_VALUE]: 'Health Index',
    [MetricType.PERCENT_HEALTH_REMAINING]: 'Health Remaining (%)',
    [MetricType.CURRENT_FAILURE_PROBABILITY]: 'Current Failure Probability',
    [MetricType.TEN_YEAR_FAILURE_PROBABILITY]: '10-year Failure Probability',
    [MetricType.TIME_UNTIL_SR_50]: 'Time until 50% Survival',
    [MetricType.RISK]: 'Current Risk',
    [MetricType.RISK_VS_REPLACEMENT_COST]: 'Current Risk vs. Replacement Cost',
    [MetricType.RISK_CATEGORY]: 'Risk Category',
    [MetricType.YEARS_TO_INTERVENTION]: 'Years to Economic EOL',
    [MetricType.OIT]: 'Age at Economic EOL',
    [MetricType.ECONOMIC_CATEGORY]: 'Economic Category',
    [MetricType.TCO_UNDER_OPTIMAL_REPLACEMENT]: 'TCO Optimal TCO',
    [MetricType.TCO_RUN_TO_FAILURE]: 'TCO Run-to-Failure',

    [MetricType.DATA_QUALITY]: 'Data Quality (%)',
    [MetricType.NUMBER_MISSING_DATA_POINTS]: 'Number of Missing Data Points',

    [MetricType.OUTAGE_COUNT_1Y]: 'Outage Count',
    [MetricType.OUTAGE_CI_1Y]: 'Total CI',
    [MetricType.OUTAGE_CHI_1Y]: 'Total CHI',
    [MetricType.OUTAGE_COUNT_3Y]: 'Outage Count',
    [MetricType.OUTAGE_CI_3Y]: 'Total CI',
    [MetricType.OUTAGE_CHI_3Y]: 'Total CHI',
    [MetricType.OUTAGE_COUNT_5Y]: 'Outage Count',
    [MetricType.OUTAGE_CI_5Y]: 'Total CI',
    [MetricType.OUTAGE_CHI_5Y]: 'Total CHI',

    [MetricType.PRID_CUSTOMERS_TOTAL]: 'PRID Total Customers',
    [MetricType.PRID_CUSTOMERS_LOCAL]: 'PRID Local Customers',
};
