import {Observable} from 'rxjs';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';

/*
 * Response
 */
export interface Segment {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    name: string;
    description: string;
    ownerId: string;
    ownerName: string;
    kpiList: KpiType[];
    programCount: number;
}

export interface SegmentDto extends Segment {
    programCount: number;
    projectCount: number;
}

/*
 * Request
 */
export interface SegmentCreate {
    name: string;
    description: string;
    ownerId: string;
    ownerName: string;
    kpiList: KpiType[];
}

export interface SegmentUpdate {
    name: string;
    description: string;
    kpiList: KpiType[];
}

export interface SegmentUpdate {
    name: string;
    description: string;
    kpiList: KpiType[];
}

/*
 * Enums
 */
export enum KpiType {
    DELTA_10YEAR_RISK = 'DELTA_10YEAR_RISK',
    DELTA_10YEAR_FAILURE_COUNT = 'DELTA_10YEAR_FAILURE_COUNT',
    DELTA_10YEAR_PROACTIVE_COUNT = 'DELTA_10YEAR_PROACTIVE_COUNT',
    DELTA_10YEAR_FAILURE_COST = 'DELTA_10YEAR_FAILURE_COST',
    DELTA_10YEAR_PROACTIVE_COST = 'DELTA_10YEAR_PROACTIVE_COST',
}
export const KpiTypeLabel = {
    [KpiType.DELTA_10YEAR_RISK]: '10 Year Risk',
    [KpiType.DELTA_10YEAR_FAILURE_COUNT]: '10 Year Failure Count',
    [KpiType.DELTA_10YEAR_PROACTIVE_COUNT]: '10 Year Proactive Count',
    [KpiType.DELTA_10YEAR_FAILURE_COST]: '10 Year Failure Cost',
    [KpiType.DELTA_10YEAR_PROACTIVE_COST]: '10 Year Proactive Cost',
};

export abstract class SegmentService {
    /*
     * APIs for Segments model
     */
    abstract createSegment(segmentCreate: SegmentCreate): Observable<APIResponse<Segment>>;

    abstract editSegment(segmentId: number, segmentUpdate: SegmentUpdate): Observable<APIResponse<Segment>>;

    abstract deleteSegment(segmentId: number): Observable<APIResponse<boolean>>;

    /*
     * APIs for SegmentDto
     */
    abstract getSegmentDto(segmentId: number): Observable<APIResponse<SegmentDto>>;

    abstract getSegmentDtoList(params: Filter[], pageSize?: number): Observable<SegmentDto[]>;

    abstract getSegmentDtoListCsv(): Observable<APIResponse<DownloadURL>>;

    abstract getSegmentDtoListTable(): Observable<DataSource>;

    /*
     * APIs related to analysis of results and budgets
     */
    abstract findSegmentKpis(segmentId: number, req: PmDataRequest): Observable<APIResponse<KpiData[]>>;

    /*
     * Other support APIs
     */
    abstract getSegmentKpiOptions(): Observable<APIResponse<KpiType[]>>;

    /** True = name is valid and does not exist. */
    abstract validateSegmentName(segmentName: string): Observable<boolean>;

    /*
     * Helpers - call other APIs
     */
    abstract searchSegmentsByName(segmentName: string): Observable<Segment[]>;
}
