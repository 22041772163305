import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    APIResponse,
    DataTableParam,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    FilterFieldType,
    FilterOperatorType,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {
    EconomicsOverTimeResponse,
    Project,
    ProjectAssetDto,
    ProjectCostAnalysis,
    ProjectCreate,
    ProjectDto,
    ProjectService,
    ProjectUpdate,
} from '@core/interfaces/engin/program-management/project';
import {ProjectApi} from '../../api/program-management/project.api';
import {map} from 'rxjs/operators';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

@Injectable()
export class ProjectServiceImpl extends ProjectService {
    constructor(private api: ProjectApi) {
        super();
    }

    /*
     * APIs for Projects model
     */
    public createProject(req: ProjectCreate): Observable<APIResponse<Project>> {
        return this.api.createProject(req);
    }

    public updateProject(projectId: number, req: ProjectUpdate): Observable<APIResponse<Project>> {
        return this.api.updateProject(projectId, req);
    }

    public deleteProject(projectId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteProject(projectId);
    }

    public getProject(projectId: number): Observable<APIResponse<Project>> {
        return this.api.getProject(projectId);
    }

    public getProjectList(params?: Filter[], pageSize?: number): Observable<Project[]> {
        return this.api.getProjectList(params, pageSize).pipe(map((result) => result.items));
    }

    /*
     * APIs for ProjectDto
     */
    public getProjectDto(workflowItemId: number, projectId: number): Observable<APIResponse<ProjectDto>> {
        return this.api.getProjectDto(workflowItemId, projectId);
    }

    public getProjectDtoList(workflowItemId: number, params?: Filter[], pageSize?: number): Observable<ProjectDto[]> {
        return this.api.getProjectDtoList(workflowItemId, params, pageSize).pipe(map((result) => result.items));
    }

    public getProjectDtoListCsv(workflowItemId: number, params?: Filter[]): Observable<APIResponse<DownloadURL>> {
        return this.api.getProjectDtoListCsv(workflowItemId, params);
    }

    public getProjectDtoListTable(workflowItemId: number): Observable<DataSource> {
        return this.api.getProjectDtoListTable(workflowItemId);
    }

    /*
     * APIs for ProjectAssetDto
     */
    public getProjectAssetDtoList(
        workflowItemId: number,
        filters?: Filter[],
        pageSize?: number,
    ): Observable<ProjectAssetDto[]> {
        return this.api.getProjectAssetDtoList(workflowItemId, filters, pageSize).pipe(map((result) => result.items));
    }

    public getProjectAssetsDtoListCsv(
        workflowItemId: number,
        filterParams?: Filter[],
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getProjectAssetsDtoListCsv(workflowItemId, filterParams);
    }

    public getProjectAssetsDtoListTable(workflowItemId: number, filters?: Filter[]): Observable<DataSource> {
        return this.api.getProjectAssetDtoListTable(workflowItemId, filters);
    }

    /*
     * APIs for project scope
     */
    public getAssetScopeFilters(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.getAssetScopeFilters();
    }

    public getAssetFilterFieldOptions(
        workflowItemId: number,
        filterOption: FilterFieldOptionRequest,
    ): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.getAssetFilterFieldOptions(workflowItemId, filterOption);
    }

    public getAssetsByFiltersListTable(workflowItemId: number, filters: Filter[]): Observable<DataSource> {
        return this.api.getAssetsByFiltersListTable(workflowItemId, filters);
    }

    public getAssetsByFiltersListCsv(
        workflowItemId: number,
        filters: DataTableParam,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getAssetsByFiltersListCsv(workflowItemId, filters);
    }

    /*
     * APIs for project-modifying actions
     */
    public removeProjectOutlierByProjectId(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<boolean>> {
        return this.api.removeProjectOutlierByProjectId(workflowItemId, projectId);
    }

    public generateProjectBoundaryByProjectId(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<boolean>> {
        return this.api.generateProjectBoundaryByProjectId(workflowItemId, projectId);
    }

    /*
     * Other support APIs
     */
    public projectNameExistsValidator(projectName: string): Observable<APIResponse<boolean>> {
        return this.api.projectNameExistsValidator(projectName);
    }

    /*
     * Helpers - call other APIs
     */

    public getProjectsByProgram(programId: number): Observable<Project[]> {
        const filter: Filter = {
            fieldKey: 'programId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: programId,
        };
        return this.getProjectList([filter], 100);
    }

    public getProjectDtoListCsvByProgram(
        workflowItemId: number,
        programId: number,
    ): Observable<APIResponse<DownloadURL>> {
        const filter: Filter = {
            fieldKey: 'programId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: programId,
        };
        return this.api.getProjectDtoListCsv(workflowItemId, [filter]);
    }

    public getProjectDtoListCsvBySegment(
        workflowItemId: number,
        segmentId: number,
    ): Observable<APIResponse<DownloadURL>> {
        const filter: Filter = {
            fieldKey: 'segmentId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: segmentId,
        };
        return this.api.getProjectDtoListCsv(workflowItemId, [filter]);
    }

    public projectAssetsDtoListTableByProject(workflowItemId: number, projectId: number): Observable<DataSource> {
        const filter: Filter = {
            fieldKey: 'projectId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: projectId,
        };
        return this.getProjectAssetsDtoListTable(workflowItemId, [filter]);
    }

    public projectAssetsDtoListCsvByProject(
        workflowItemId: number,
        projectId: number,
    ): Observable<APIResponse<DownloadURL>> {
        const filter: Filter = {
            fieldKey: 'projectId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: projectId,
        };
        return this.getProjectAssetsDtoListCsv(workflowItemId, [filter]);
    }

    // TODO: this is being removed and refactored into alternatives
    getEconomicsOverTime(id: number, year: number): Observable<APIResponse<EconomicsOverTimeResponse>> {
        return this.api.getEconomicsOverTime(id, year);
    }
    findProjectCostAnalysis(id: number): Observable<APIResponse<ProjectCostAnalysis>> {
        return this.api.findProjectCostAnalysis(id);
    }
    downloadCostAnalysisListDtoCsv(projectId: number): Observable<APIResponse<DownloadURL>> {
        return this.api.downloadCostAnalysisListDtoCsv(projectId);
    }
}
