import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, PagingResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {
    Program,
    ProgramBudgetOutcome,
    ProgramUpdateBudgetDto,
    ProgramCreateDto,
    ProgramFullDto,
    ProgramUpdateDto,
    ProgramDto,
    ProgramBudgetByOutcomeRequest,
    ProgramBudgetRequest,
    InvestmentPriorityType,
} from '@core/interfaces/engin/program-management/program';
import {TableService} from '@core/utils/table.service';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';
import {KpiType} from '@core/interfaces/engin/program-management/segment';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {map} from 'rxjs/operators';
import {
    Alternative,
    AlternativeAssetDto,
    AlternativeCostResponse,
    AlternativeCreateDto,
    AlternativeDto,
    AlternativeFullDto,
    AlternativeMetricResponse,
    AlternativeMetricType,
    AlternativeUpdateDto,
    AssetInterventionType,
    ResetAlternativeRequest,
    UpdateInterventionRequest,
} from '@core/interfaces/engin/program-management/alternative';

@Injectable()
export class AlternativeApi {
    private readonly prefix = 'alternatives';

    constructor(private api: HttpService) {}

    /*
     * APIs for Alternatives model
     */
    public createAlternative(alternativeCreate: AlternativeCreateDto): Observable<APIResponse<Alternative>> {
        return this.api.post(`${this.prefix}`, alternativeCreate);
    }

    public editAlternativeDetails(
        alternativeId: number,
        alternativeUpdate: AlternativeUpdateDto,
    ): Observable<APIResponse<Alternative>> {
        return this.api.put(`${this.prefix}/${alternativeId}`, alternativeUpdate);
    }

    public deleteAlternative(alternativeId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${alternativeId}`);
    }

    public getAlternativeList(params: Filter[] = [], pageSize: number = 10): Observable<PagingResponse<Alternative>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.get(`${this.prefix}/list?${paramQuery}`);
    }

    public updateAlternativeInterventions(
        alternativeId: number,
        updateDto: UpdateInterventionRequest,
    ): Observable<APIResponse<AlternativeFullDto>> {
        return this.api.post(`${this.prefix}/update/interventions/${alternativeId}`, updateDto);
    }

    public resetAlternative(
        alternativeId: number,
        updateDto: ResetAlternativeRequest,
    ): Observable<APIResponse<AlternativeFullDto>> {
        return this.api.post(`${this.prefix}/reset/${alternativeId}`, updateDto);
    }

    public setSelectedAlternative(alternativeId: number): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/update/selected/${alternativeId}`);
    }

    /*
     * APIs for AlternativeDto
     */
    public getAlternativeDto(alternativeId: number, request: PmDataRequest): Observable<APIResponse<AlternativeDto>> {
        return this.api.post(`${this.prefix}/dto/${alternativeId}`, request);
    }

    public getAlternativeDtoList(
        request: PmDataRequest,
        params: Filter[] = [],
        pageSize: number = 10,
    ): Observable<PagingResponse<AlternativeDto>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.post(`${this.prefix}/dto/list?${paramQuery}`, request);
    }

    public getAlternativeDtoListCsv(
        request: PmDataRequest,
        params: Filter[] = [],
    ): Observable<APIResponse<DownloadURL>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        return this.api.post(`${this.prefix}/dto/list/csv?${paramQuery}`, request);
    }

    /*
     * APIs for AlternativeFullDto
     */
    public getAlternativeDtoFull(
        alternativeId: number,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeFullDto>> {
        return this.api.post(`${this.prefix}/dto-full/${alternativeId}`, request);
    }

    public getAlternativeFullDtoListTable(request: PmDataRequest, params: Filter[] = []): Observable<DataSource> {
        const fullRequest = {
            ...request,
            filters: params,
        };
        return this.api.postServerDataSource(`${this.prefix}/dto-full/list`, fullRequest);
    }

    /*
     * APIs for AlternativeAssetDto
     */
    public getAlternativeAssetsList(
        alternativeId: number,
        request: PmDataRequest,
        filters: Filter[] = [],
        pageSize: number = 10,
    ): Observable<PagingResponse<AlternativeAssetDto>> {
        let paramQuery = TableService.createFiltersParamsString(filters);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.post(`${this.prefix}/assets/${alternativeId}/list?${paramQuery}`, request);
    }

    public getAlternativeAssetsListCsv(
        alternativeId: number,
        request: PmDataRequest,
        params: string = '',
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.post(`${this.prefix}/assets/${alternativeId}/list/csv?${params}`, request);
    }

    public getAlternativeAssetsListTable(alternativeId: number, request: PmDataRequest): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/assets/${alternativeId}/list`, request);
    }

    /*
     * APIs related to analysis of costs and benefits
     */
    public getCostResults(
        alternativeId: number,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeCostResponse>> {
        return this.api.post(`${this.prefix}/analysis/${alternativeId}/costs`, request);
    }

    public getBenefitResultsByType(
        alternativeId: number,
        benefitType: AlternativeMetricType,
        request: PmDataRequest,
    ): Observable<APIResponse<AlternativeMetricResponse>> {
        return this.api.post(`${this.prefix}/analysis/${alternativeId}/benefits/${benefitType}`, request);
    }

    /*
     * Other support APIs
     */
    public getInterventionOptions(): Observable<APIResponse<AssetInterventionType[]>> {
        return this.api.get(`${this.prefix}/options/interventions`);
    }

    public validateAlternativeName(projectId: number, alternativeName: string): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/name/${projectId}/${alternativeName}`);
    }
}
