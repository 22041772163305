import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {
    EmptyDataComponent,
    FileCellComponent,
    MultiSelectWithSearchFilterComponent,
    TagOptionsCallback,
    UploadFileDialogComponent,
} from '@theme/components';
import {ServerDataSource} from '@mominsamir/ngx-smart-table';
import {DocumentAssetImage, DocumentService} from '@core/interfaces/common/document';
import {filter, map, mergeMap, share, takeUntil, tap} from 'rxjs/operators';
import {NbDialogService} from '@nebular/theme';
import {DocumentsStore} from '@store/common/documents.store';
import {APIResponse, FilterOperatorType} from '@core/interfaces/system/system-common';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {InputFilterComponent} from '@theme/components/data-table/custom-filters/input-filter.component';
import {DocumentViewCellComponent} from '../action-cell/action-cell.component';

@Component({
    selector: 'ngx-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() assetId: string;
    @Input() refresh$: Observable<boolean>;
    @Input() getTagOptionsCallback: TagOptionsCallback;
    @Input() acceptedFileTypes: string[];
    @Output() displayFile = new EventEmitter<DocumentAssetImage>();

    _assetId: BehaviorSubject<string> = new BehaviorSubject(null);

    loadingRefresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loadingRefresh$: Observable<boolean> = this.loadingRefresh.asObservable();

    reloadDataTable: BehaviorSubject<number> = new BehaviorSubject(null);
    refreshDataTable: number = null;
    tableSettings: any;
    private setColumns(tag) {
        this.tableSettings = {
            editable: false,
            enableHeader: false,
            sort: true,
            mode: 'external',
            emptyDataComponent: EmptyDataComponent,
            enableGlobalSearch: false,
            hideSubHeader: false,
            actions: {
                position: 'right',
                add: false,
                edit: false,
                delete: false,
                select: false,
            },
            pager: {
                perPage: 8,
                mode: 'custom',
            },
            columns: {
                tag: {
                    title: 'Tag',
                    width: '20%',
                    type: 'string',
                    sort: true,
                    filterOperator: FilterOperatorType.IN,
                    filter: {
                        type: 'custom',
                        component: MultiSelectWithSearchFilterComponent,
                        config: {
                            options: tag.map((item) => ({
                                value: item,
                                label: item,
                            })),
                        },
                    },
                },
                fileName: {
                    title: 'File Name',
                    width: '40%',
                    sort: true,
                    type: 'custom',
                    filter: {
                        type: 'custom',
                        component: InputFilterComponent,
                    },
                    renderComponent: FileCellComponent,
                },
                userName: {
                    title: 'Uploaded By',
                    width: '30%',
                    sort: true,
                    filterOperator: FilterOperatorType.CONTAIN,
                    type: 'string',
                    filter: {
                        type: 'custom',
                        component: InputFilterComponent,
                    },
                },
                button: {
                    title: '',
                    width: '10%',
                    type: 'custom',
                    sort: false,
                    filter: false,
                    renderComponent: DocumentViewCellComponent,
                    onComponentInitFunction: (instance) => {
                        instance.showNavAction = (data: any) => true;
                        instance.onInfoClickedEvent.subscribe((data: DocumentAssetImage) => {
                            this.displayFile.emit(data);
                        });
                    },
                },
            },
        };
    }

    dataSource$: Observable<ServerDataSource> = this._assetId.asObservable().pipe(
        mergeMap((id) =>
            combineLatest([this.documentService.getAllTags(id), this.documentService.listFiles(id)]).pipe(
                tap(([tags, dataSource]: [APIResponse<string[]>, DataSource]) => {
                    this.setColumns(tags.response);
                    dataSource.onChanged().subscribe(() => {
                        this.loadingRefresh.next(false);
                    });
                }),
                map(([_, dataSource]: [APIResponse<string[]>, DataSource]) => {
                    return dataSource as ServerDataSource;
                }),
            ),
        ),
        share(),
    );

    constructor(
        private documentService: DocumentService,
        private documentStore: DocumentsStore,
        private dialogService: NbDialogService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setColumns([]);

        this.refresh$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value: boolean) => value),
            )
            .subscribe(() => this.refreshData());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId && changes.assetId.currentValue !== changes.assetId.previousValue) {
            this.loadingRefresh.next(true);
            this._assetId.next(this.assetId);
        }
    }

    uploadFile() {
        this.dialogService
            .open(UploadFileDialogComponent, {
                closeOnBackdropClick: false,
                context: {
                    getTagOptionsCallback: this.getTagOptionsCallback,
                    acceptedFileTypes: this.acceptedFileTypes,
                },
            })
            .onClose.pipe(
                filter((res) => !!res),
                mergeMap((res) =>
                    this.documentStore.uploadAndCreateImage({
                        ...res,
                        assetId: this.assetId,
                    }),
                ),
            )
            .subscribe(() => {
                this.refreshData();
            });
    }

    refreshData() {
        this.documentService
            .getAllTags(this._assetId.value)
            .pipe(map((tags) => this.setColumns(tags.response)))
            .subscribe();
        this.loadingRefresh.next(true);
        this.refreshDataTable = this.refreshDataTable === null ? 0 : this.refreshDataTable + 1;
        this.reloadDataTable.next(this.refreshDataTable);
    }
}
