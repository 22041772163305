import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, FilterOperatorType} from '@core/interfaces/system/system-common';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {
    KpiType,
    Segment,
    SegmentCreate,
    SegmentDto,
    SegmentService,
    SegmentUpdate,
} from '@core/interfaces/engin/program-management/segment';
import {SegmentApi} from '../../api/program-management/segment.api';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {map} from 'rxjs/operators';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';

@Injectable()
export class SegmentServiceImpl extends SegmentService {
    constructor(private api: SegmentApi) {
        super();
    }

    /*
     * APIs for Segments model
     */
    public createSegment(segmentCreate: SegmentCreate): Observable<APIResponse<Segment>> {
        return this.api.createSegment(segmentCreate);
    }

    public editSegment(segmentId: number, segmentUpdate: SegmentUpdate): Observable<APIResponse<Segment>> {
        return this.api.editSegment(segmentId, segmentUpdate);
    }

    public deleteSegment(segmentId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteSegment(segmentId);
    }

    /*
     * APIs for SegmentDto
     */
    public getSegmentDto(segmentId: number): Observable<APIResponse<SegmentDto>> {
        return this.api.getSegmentDto(segmentId);
    }

    public getSegmentDtoList(params: Filter[], pageSize?: number): Observable<SegmentDto[]> {
        return this.api.getSegmentDtoList(params, pageSize).pipe(map((resp) => resp.items));
    }

    public getSegmentDtoListCsv(): Observable<APIResponse<DownloadURL>> {
        return this.api.getSegmentDtoListCsv();
    }

    public getSegmentDtoListTable(): Observable<DataSource> {
        return this.api.getSegmentDtoListTable();
    }

    /*
     * APIs related to analysis of results and budgets
     */
    public findSegmentKpis(segmentId: number, req: PmDataRequest): Observable<APIResponse<KpiData[]>> {
        return this.api.findSegmentKpis(segmentId, req);
    }

    /*
     * Other support APIs
     */
    public getSegmentKpiOptions(): Observable<APIResponse<KpiType[]>> {
        return this.api.getSegmentKpiOptions();
    }

    public validateSegmentName(segmentName: string): Observable<boolean> {
        return this.api.validateSegmentName(segmentName).pipe(map((res: APIResponse<boolean>) => res.response));
    }

    /*
     * Helpers - call other APIs
     */
    public searchSegmentsByName(segmentName: string): Observable<Segment[]> {
        const filter: Filter = {
            fieldKey: 'name',
            operator: FilterOperatorType.CONTAIN,
            value: segmentName,
        };
        return this.getSegmentDtoList([filter], 5);
    }
}
