<div class="action-cell">
    <button *ngIf="showEditAction(rowData)" nbButton class="table-action-btn" (click)="onEditClicked.emit(rowData)">
        <div class="dot-icon" *ngIf="showEditDotIconAction(rowData)"></div>
        <nb-icon icon="edit-outline"></nb-icon>
    </button>

    <button
        *ngIf="showActionItems(rowData, value) && getActionItems(rowData).length"
        nbButton
        class="table-action-btn"
        [nbContextMenu]="getActionItems(rowData)"
        [nbContextMenuPlacement]="'bottom'"
        nbContextMenuTag="context-menu-action"
    >
        <nb-icon icon="more-vertical"></nb-icon>
    </button>

    <ng-container *ngIf="showInfoItems(rowData)">
        <ng-container *ngIf="getInfoItems(rowData) as infoItems">
            <button
                *ngIf="infoItems.length"
                nbButton
                class="table-action-btn"
                [nbContextMenu]="infoItems"
                [nbContextMenuPlacement]="'bottom'"
                nbContextMenuTag="context-menu-info"
            >
                <nb-icon icon="info-outline"></nb-icon>
            </button>

            <button *ngIf="!infoItems.length" nbButton class="table-action-btn" (click)="onInfoClicked.emit(rowData)">
                <nb-icon icon="info-outline"></nb-icon>
            </button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="showNavAction(rowData)">
        <ng-container *ngIf="getNavItems(rowData) as navItems">
            <button
                *ngIf="navItems.length"
                nbButton
                class="table-action-btn"
                [ngClass]="navLinkDisabled(rowData) && 'table-action-btn-disabled'"
                [nbContextMenu]="navItems"
                [nbContextMenuPlacement]="'bottom'"
                [disabled]="navLinkDisabled(rowData)"
                nbContextMenuTag="context-menu-action"
            >
                <nb-icon [icon]="navIcon"></nb-icon>
            </button>

            <button
                *ngIf="!navItems.length"
                nbButton
                class="table-action-btn"
                [ngClass]="navLinkDisabled(rowData) && 'table-action-btn-disabled'"
                [routerLink]="navLink(rowData)"
                [disabled]="navLinkDisabled(rowData)"
                (click)="onNavigationClicked.emit(rowData)"
            >
                <nb-icon [icon]="navIcon"></nb-icon>
            </button>
        </ng-container>
    </ng-container>

    <button *ngIf="showDeleteAction(rowData)" nbButton class="table-action-btn" (click)="onDeleteClicked.emit(rowData)">
        <nb-icon icon="trash-2-outline"></nb-icon>
    </button>
</div>
