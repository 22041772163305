import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, PagingResponse} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {
    KpiType,
    Segment,
    SegmentCreate,
    SegmentDto,
    SegmentUpdate,
} from '@core/interfaces/engin/program-management/segment';
import {TableService} from '@core/utils/table.service';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {PmDataRequest} from '@core/interfaces/engin/program-management/pm-reporting';

@Injectable()
export class SegmentApi {
    private readonly prefix = 'segments';

    constructor(private api: HttpService) {}

    /*
     * APIs for Segments model
     */
    public createSegment(segmentCreate: SegmentCreate): Observable<APIResponse<Segment>> {
        return this.api.post(`${this.prefix}/`, segmentCreate);
    }

    public editSegment(segmentId: number, segmentUpdate: SegmentUpdate): Observable<APIResponse<Segment>> {
        return this.api.put(`${this.prefix}/${segmentId}`, segmentUpdate);
    }

    public deleteSegment(segmentId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${segmentId}`);
    }

    /*
     * APIs for SegmentDto
     */
    public getSegmentDto(segmentId: number): Observable<APIResponse<SegmentDto>> {
        return this.api.get(`${this.prefix}/${segmentId}`);
    }

    public getSegmentDtoList(params: Filter[], pageSize: number = 10): Observable<PagingResponse<SegmentDto>> {
        let paramQuery: string = TableService.createFiltersParamsString(params);
        paramQuery = TableService.addPagingParams(paramQuery, 1, pageSize);
        return this.api.get(`${this.prefix}/dto/list?${paramQuery}`);
    }

    public getSegmentDtoListCsv(): Observable<APIResponse<DownloadURL>> {
        return this.api.get(`${this.prefix}/dto/list/csv`);
    }

    public getSegmentDtoListTable(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list`);
    }

    /*
     * APIs related to analysis of results and budgets
     */
    public findSegmentKpis(segmentId: number, req: PmDataRequest): Observable<APIResponse<KpiData[]>> {
        return this.api.post(`${this.prefix}/analysis/${segmentId}/kpi`, req);
    }

    /*
     * Other support APIs
     */
    public getSegmentKpiOptions(): Observable<APIResponse<KpiType[]>> {
        return this.api.get(`${this.prefix}/options/kpis`);
    }

    public validateSegmentName(segmentName: string): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/name/${segmentName}`);
    }
}
